/**
 * @author Bharathan - bharathan@au79consulting.com
 * @date 09-08-2024
 * @description : This is Link Page for Virtual Type Approval Page
 */

//Importing the required components/functions from the libraries
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import appTheme from "../../../assets/AppTheme/appTheme";
import LinkSWMToLegal from "./LinkSWMToLegal";
import { LinkModuleType, SearchSelectTextField } from "../../../components";
import LinkEEToLegal from "./LinkEEToLegal";
import LinkVehicleToLegal from "./LinkVehicleToLegal";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

//Link Component to view the concern S/W Modules and Legal Requirements to be Link
function Links({
  variantOptionsList,
  setIntersectionDetailsNew,
  setIsSWMToLegalIntersection,
  setIsVehicleToLegalIntersection,
  selectedProject,
  selectedProjectIsZonal,
  selectedProjectZonesList,
  projectOptionsList,
  setSelectedProject,
  selectedProjectDetails,
  selectedProjectId,
  setTabValue,
  setLinkTab,
  tabValue,
  setShowIntersDelTicket,
  selectedSingleVariantDetails,
  selectOEMUsers,
  setSelectOEMUsers,
  selectTechUsers,
  setSelectTechUsers,
  selectSupplierUsers,
  setSelectSupplierUsers,
  selectedVariantsMany,
  setSelectedVariantsMany,
  UserDetails,
}) {
  const dispatch = useDispatch();

  const linkModuleType = { SWM: 1, EE: 2, VEHICLE: 3 };

  const [selectedLinkModuleType, setSelectedLinkModuleType] = useState(
    linkModuleType?.EE
  );

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryLinkModuleType = queryParams.get("linkModuleType");

  useEffect(() => {
    if (queryLinkModuleType) {
      setSelectedLinkModuleType(Number(queryLinkModuleType)); // Set state based on query parameter
    }
  }, [queryLinkModuleType]);

  const handleProjectChange = (newSelectedProject) => {
    dispatch(setSelectedProject(newSelectedProject));
  };

  return (
    <Box>
      {/* <Box sx={styles.searchContainer}>
        <SearchSelectTextField
          size={"small"}
          label={"Search Project"}
          placeholder={"Search Project"}
          noOptionsText={"No project found"}
          optionsList={projectOptionsList}
          selectedValue={selectedProject}
          setSelectedValue={handleProjectChange}
          txtFldStyle={styles.searchSelectTextField}
        />
      </Box> */}

      <Box
        sx={{
          mt: 2.5,
        }}
      >
        <LinkModuleType
          eeLabel={"EE To Legal"}
          swmLabel={"SWM To Legal"}
          vehicleLabel={"Vehicle to Legal"}
          selectedLMBtn={selectedLinkModuleType}
          setSelectedLMBtn={setSelectedLinkModuleType}
          linkModuleType={linkModuleType}
        />
      </Box>

      {/* Linking Software module To Legal or EE Component To Legal */}
      <Box sx={{ mt: 1.4, mb: 9 }}>
        {/* Linking Software module To Legal */}
        {selectedLinkModuleType === linkModuleType?.SWM ? (
          <LinkSWMToLegal
            selectedProjectDetails={selectedProjectDetails}
            selectedVariantsMany={selectedVariantsMany}
            UserDetails={UserDetails}
            setIntersectionDetailsNew={setIntersectionDetailsNew}
            setIsSWMToLegalIntersection={setIsSWMToLegalIntersection}
            selectedProjectId={selectedProjectId}
            selectedSingleVariantDetails={selectedSingleVariantDetails}
            selectedProjectIsZonal={selectedProjectIsZonal}
            selectedProjectZonesList={selectedProjectZonesList}
            setTabValue={setTabValue}
            setShowIntersDelTicket={setShowIntersDelTicket}
            selectOEMUsers={selectOEMUsers}
            setSelectOEMUsers={setSelectOEMUsers}
            selectTechUsers={selectTechUsers}
            setSelectTechUsers={setSelectTechUsers}
            selectSupplierUsers={selectSupplierUsers}
            setSelectSupplierUsers={setSelectSupplierUsers}
          />
        ) : selectedLinkModuleType === linkModuleType?.EE ? (
          <LinkEEToLegal
            selectedProjectDetails={selectedProjectDetails}
            UserDetails={UserDetails}
            variantOptionsList={variantOptionsList}
            setIntersectionDetailsNew={setIntersectionDetailsNew}
            setIsSWMToLegalIntersection={setIsSWMToLegalIntersection}
            selectedProjectId={selectedProjectId}
            selectedSingleVariantDetails={selectedSingleVariantDetails}
            selectedProjectIsZonal={selectedProjectIsZonal}
            selectedProjectZonesList={selectedProjectZonesList}
            setTabValue={setTabValue}
            setShowIntersDelTicket={setShowIntersDelTicket}
            selectOEMUsers={selectOEMUsers}
            setSelectOEMUsers={setSelectOEMUsers}
            selectTechUsers={selectTechUsers}
            setSelectTechUsers={setSelectTechUsers}
            selectSupplierUsers={selectSupplierUsers}
            setSelectSupplierUsers={setSelectSupplierUsers}
            selectedVariantsMany={selectedVariantsMany}
            setSelectedVariantsMany={setSelectedVariantsMany}
          />
        ) : selectedLinkModuleType === linkModuleType?.VEHICLE ? (
          <LinkVehicleToLegal
            selectedProjectDetails={selectedProjectDetails}
            selectedVariantsMany={selectedVariantsMany}
            UserDetails={UserDetails}
            setIntersectionDetailsNew={setIntersectionDetailsNew}
            setIsVehicleToLegalIntersection={setIsVehicleToLegalIntersection}
            selectedProjectId={selectedProjectId}
            selectedSingleVariantDetails={selectedSingleVariantDetails}
            selectedProjectIsZonal={selectedProjectIsZonal}
            selectedProjectZonesList={selectedProjectZonesList}
            setTabValue={setTabValue}
            setShowIntersDelTicket={setShowIntersDelTicket}
            selectOEMUsers={selectOEMUsers}
            setSelectOEMUsers={setSelectOEMUsers}
            selectTechUsers={selectTechUsers}
            setSelectTechUsers={setSelectTechUsers}
            selectSupplierUsers={selectSupplierUsers}
            setSelectSupplierUsers={setSelectSupplierUsers}
          />
        ) : null}
      </Box>
    </Box>
  );
}

export default Links;

//Styles for the page
const styles = {
  searchContainer: {
    display: "flex",
    justifyContent: "center",
    textAlign: "start",
  },
  searchSelectTextField: {
    width: "215px",
    backgroundColor: "white",
  },
};
