import { Box, IconButton, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CustomButton, CustomPagination } from "../../components";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import InfoIcon from "@mui/icons-material/Info";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ViewDynammicFormDetails from "../admin-settings/DynamicForms/ViewDynammicFormDetails";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { HandleApiActions } from "../../redux/actions";
import documentLibraryAction from "../../redux/actions/documentLibraryAction";
import CustomFiltersN from "../../components/tables/CustomFiltersN";
function Reports({ selectedProjectDetails, selectedVariantDetails }) {
  const dispatch = useDispatch();
  const dfReportsDetails = useSelector((state) => state.documentLibraryReducer);
  const formType = useSelector(
    (state) => state?.settingsReducer?.metaData,
    shallowEqual
  );
  console.log("formStatus", formType?.templates?.template_types);
  //useState
  const [open, setOpen] = useState(false);
  const [openInfo, setOpenInfo] = useState(null);
  const [singleFormDetails, setSingleFormDetails] = useState({});
  // Pagination
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [hasMore, setHasMore] = useState(false);

  // Filtering
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedSearchField, setSelectedSearchField] = useState("name");
  const [searchFieldValue, setSearchFieldValue] = useState("");
  const [statusFilter, setStatusFilter] = useState([]);

  // Open View modal
  const handleOpen = (dataId) => {
    const singleFormDetails = dfReportsDetails?.reports?.find(
      (item) => item?.form_id === dataId
    );
    setSingleFormDetails(singleFormDetails);
    setOpen(true);
  };

  const [isFullNameVisible, setIsFullNameVisible] = useState(false);
  const handleNameClick = () => {
    setIsFullNameVisible(!isFullNameVisible);
  };
  // Close the modals
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(
      HandleApiActions({
        ...documentLibraryAction.get_df_reports,
        params: {
          project_id: parseInt(selectedProjectDetails?.project_id),
          variant_id: parseInt(selectedVariantDetails?.variant_id),
          is_ticket_required: true,
          page_number: page,
          page_size: rowsPerPage,
          ...(searchFieldValue && { [selectedSearchField]: searchFieldValue }),
        },
      })
    );
  }, [
    page,
    rowsPerPage,
    selectedSearchField,
    searchFieldValue,
    selectedProjectDetails,
    selectedVariantDetails,
  ]);

  // Handle pagination hasMore
  useEffect(() => {
    if (dfReportsDetails?.reports?.length < rowsPerPage) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  }, [dfReportsDetails]);

  useEffect(() => {
    let filteredType = formType?.templates?.template_types?.map((el) => {
      return {
        value: el?.value,
        label: el?.key,
      };
    });
    console.log("filteredType", filteredType);
    setStatusFilter(filteredType);
  }, [formType]);

  // Handle page change
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Handle page size change
  const handlePageSizeChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  const handleOpenInfo = (formId) => {
    setOpenInfo((prev) => (prev === formId ? null : formId));
  };

  // Handle filter close
  const handleFilterClose = () => {
    setAnchorElFilter(null);
    setOpenFilter(false);
  };
  // Open filter menu
  const handleFilterClick = (event) => {
    setAnchorElFilter(event.currentTarget);
    setOpenFilter(true);
  };

  // Reset the filter values
  const handleFilterResetAll = () => {
    handleFilterChange("name", "");
  };

  // Handle filter change
  const handleFilterChange = (searchField, searchValue) => {
    setSelectedSearchField(searchField);
    setSearchFieldValue(searchValue);

    setPage(1); // Reset to the first page when page size changes

    handleFilterClose();
  };

  // Apply filtering
  const handleFilterApplyNow = (searchField, searchValue) => {
    handleFilterChange(searchField, searchValue);
  };

  return (
    <div>
      <Box>
        {/* <Box
          sx={{ display: "flex", justixfyContent: "space-around", mt: "10px" }}
        > */}
        {dfReportsDetails?.reports?.length > 0 ? (
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            {" "}
            <CustomFiltersN
              anchorEl={anchorElFilter}
              open={openFilter}
              handleClose={handleFilterClose}
              mainOptionsList={[
                { value: "name", label: "Form Name", type: "search" },
                { value: "form_id", label: "Form ID", type: "search" },
                {
                  value: "intr_id",
                  label: "Intersection ID",
                  type: "search",
                },
                {
                  value: "type",
                  label: "Type",
                  type: "select",
                  value_options_list: statusFilter,
                },
              ]}
              selectedMainOption={selectedSearchField}
              selectedMainOptionValue={searchFieldValue}
              handleFilterClick={handleFilterClick}
              onReset={handleFilterResetAll}
              onApplyNow={handleFilterApplyNow}
            />
          </Box>
        ) : null}

        {dfReportsDetails?.reports?.length > 0 ? (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap", // Enables wrapping
              justifyContent: "space-around", // Distributes the forms evenly
              gap: "20px", // Space between items
            }}
          >
            {dfReportsDetails?.reports?.map((data) => (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  justifyContent: "space-between",
                  width: "calc(25% - 20px)", // Four forms in a row
                  height: "100%",
                }}
                key={data?.form_id}
              >
                <Box
                  sx={{
                    ml: "15px",
                    mt: "5px",
                    width: "250px",
                    height: "300px",
                    boxShadow: 3,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    backgroundColor: "#ffffff",
                    borderRadius: "10px",
                    border: "20px solid #ffffff",
                    paddingTop: "30px",
                    overflow: "hidden",
                    backgroundImage:
                      "linear-gradient(0deg, #f5f5f5 25%, #e0e0e0 25%, #e0e0e0 50%, #f5f5f5 50%, #f5f5f5 75%, #e0e0e0 75%, #e0e0e0 100%)",
                    backgroundSize: "50px 50px",
                  }}
                >
                  <IconButton
                    sx={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      backgroundColor: "rgba(255, 255, 255, 0.7)",
                      borderRadius: "50%",
                    }}
                    onClick={() => handleOpenInfo(data?.form_id)}
                  >
                    <InfoIcon />
                  </IconButton>
                  {openInfo && (
                    <Paper
                      sx={{
                        position: "absolute",
                        bottom: openInfo ? "0px" : "-200px",
                        left: "0px",
                        right: "0px",
                        height: openInfo ? "150px" : "0px",
                        transition: "all 0.3s ease",
                        overflow: "hidden",
                      }}
                    >
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Details
                      </Typography>
                      <Box>
                        {[
                          { label: "Form ID", value: data?.form_id },
                          {
                            label: "Intersection ID",
                            value: data?.ticket_details?.intr_id,
                          },
                        ]?.map((detail, index) => (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "8px",
                            }}
                            key={index}
                          >
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                minWidth: 120,
                                fontSize: "12px",
                                marginRight: "8px",
                              }}
                            >
                              {detail?.label}
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                fontSize: "12px",
                                marginRight: "8px",
                              }}
                            >
                              :
                            </Typography>
                            <Typography sx={{ fontSize: "12px" }}>
                              {detail.value}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </Paper>
                  )}

                  {!openInfo && (
                    <IconButton
                      sx={{ position: "absolute" }}
                      onClick={() => handleOpen(data?.form_id)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  )}
                </Box>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      paddingLeft: "25px",
                      paddingRight: "20px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        mt: "10px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        // width: "calc(100% - 30px)",
                        display: "inline-block",
                      }}
                      title={data?.name}
                      onClick={handleNameClick}
                    >
                      {isFullNameVisible || data?.name?.length <= 20
                        ? data?.name.charAt(0).toUpperCase() +
                          data?.name.slice(1)
                        : `${
                            data?.name?.slice(0, 20).charAt(0).toUpperCase() +
                            data?.name?.slice(1, 20)
                          }...`}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography sx={{ textAlign: "center" }}>
              No dynamic forms available
            </Typography>
          </Box>
        )}
      </Box>
      {/* </Box> */}
      {/* View Dynammic Form Details */}
      <ViewDynammicFormDetails
        data={singleFormDetails}
        open={open}
        handleClose={handleClose}
      />
      {/* Pagination */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "20px",
        }}
      >
        <CustomPagination
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          hasMore={hasMore}
        />
      </Box>
    </div>
  );
}

export default Reports;
