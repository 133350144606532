/**
 * @author Abdul Rahim M-abdul@au79consulting.com
 * @date 03-12-2024
 * @description  Dynamic Template for Settings API
 */
import actionType from "../actionTypes";
const dynamicTemplateAction = {
  //Dynamic Settings API
  get_d_template: {
    api_name: "/UCM/templates/intersections/details",
    https_method: "GET",
    action_type: actionType.GET_DYNAMIC_TEMPLATE,
    description: "Get the Dynamic Forms In Settings",
  },
  add_d_template: {
    api_name: "/UCM/templates/intersections/add",
    https_method: "POST",
    action_type: actionType.ADD_DYNAMIC_TEMPLATE,
    description: "Add the Dynamic Forms In Settings",
  },
  update_d_template: {
    api_name: "/UCM/templates/intersections/details",
    https_method: "PUT",
    action_type: actionType.UPDATE_DYNAMIC_TEMPLATE,
    description: "Update the Dynamic Forms In Settings",
  },
  update_d_status_template: {
    api_name: "/UCM/templates/intersections/status",
    https_method: "PUT",
    action_type: actionType.UPDATE_STATUS_DYNAMIC_TEMPLATE,
    description: "Update Status the Dynamic Forms In Settings",
  },
};

export default dynamicTemplateAction;
