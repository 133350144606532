/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 28-06-2024
 * @description Component for adding roles and access permissions.
 */

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  DialogContentText,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  HandleApiActions,
  legalRequirements,
  profileActions,
  userManagementApis,
} from "../../redux/actions";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import DeleteIcon from "@mui/icons-material/Delete";
import UpdateIcon from "@mui/icons-material/Update";
import {
  CustomButton,
  CustomConfirmationDialog,
  CustomTextField,
  CustomTextFieldN,
  MultiSelectTextField,
  MultiSelectTextFieldN,
  MultiSelectTextFieldNR,
  MultiSelectTextFieldNRemove,
  SelectTextField,
  SelectTextFieldN,
} from "../../components";
import appTheme from "../../assets/AppTheme/appTheme";
import customConsole from "../../config/customConsole";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

/**
 * CustomTabPanel component renders a tab panel for a tab-based interface.
 */
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 28-06-2024
 * @description Component for adding roles and access permissions.
 * @param { setIsAddRole }
 * @return The rendered add roles and access component.
 */
function UpdateRolesAndAccess({
  rolesAndAccessData,
  toggleDrawer,
  handleGetRolesAndAccessList,
}) {
  const {
    role_id,
    role_name,
    role_type,
    department: dept,
    sub_department,
    legal_chapters,
    description: desp,
    access_to_pages,
    access_to_features,
    is_active,
  } = rolesAndAccessData;

  console.log("rolesAndAccessData", rolesAndAccessData);

  const [roleName, setRoleName] = useState(role_name || "");
  const [roleType, setRoleType] = useState(role_type);
  const [department, setDepartment] = useState(dept || "");
  const [subDepartment, setSubDepartment] = useState(sub_department || "");
  const [subDepartmentError, setSubDepartmentError] = useState("");
  const [description, setDescription] = useState(desp || "");
  const [descriptionError, setDescriptionError] = useState("");

  // Redux store data
  const { legalStandardDetails } = useSelector(
    (state) => state.legalRequirementsReducer,
    shallowEqual
  );
  // Legal standard states
  const [lsOptionsList, setLSOptionsList] = useState([]);
  const [selectedLSId, setSelectedLSId] = useState(null);

  // Chapter
  const [lsAddedChaptersOptionsList, setLSAddedChaptersOptionsList] = useState(
    []
  );
  const [lsAddedSelectedChapters, setLSAddedSelectedChapters] = useState([]);

  const [lsChaptersOptionsList, setLSChaptersOptionsList] = useState([]);
  const [lsSelectedChapters, setLSSelectedChapters] = useState([]);
  const [isAddChapterClicked, setIsAddChapterClicked] = useState(false);

  const { menu_options } = useSelector(
    (state) => state.settingsReducer.metaData,
    shallowEqual
  );
  const { featuresList } = useSelector(
    (state) => state.userManagementReducer,
    shallowEqual
  );
  const [menuOptionsList, setMenuOptionsList] = useState([]);
  const [selectedMenuOption, setSelectedMenuOption] =
    useState("USER MANAGEMENT");
  const [selectedMenus, setSelectedMenus] = useState(access_to_pages || []);

  let access_to_features_ids = access_to_features?.map((id) => Number(id));
  const [selectedMenuOptionFeaturesList, setSelectedMenuOptionFeaturesList] =
    useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState(
    access_to_features_ids || []
  );
  const dispatch = useDispatch();
  const [isRoleActive, setIsRoleActive] = useState(is_active || false);
  const [openRoleDeleteDialog, setOpenRoleDeleteDialog] = useState(false);

  const [openRoleStatusDialog, setOpenRoleStatusDialog] = useState(false);
  const [pendingRoleStatus, setPendingRoleStatus] = useState(isRoleActive);

  const [roleDepartmentOptions, setRoleDepartmentOptions] = useState("");

  const profileDetails = useSelector((store) => store.profileReducer);
  customConsole("profileDetails: " + profileDetails);
  const [loggedInUserRoleId, setLoggedInUserRoleId] = useState("");

  // Tabs
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    const filteredMenuOptionsList = menu_options?.filter(
      (el) => el !== "DEFAULT"
    );
    setMenuOptionsList(filteredMenuOptionsList);
  }, [menu_options]);

  const { rolesList } = useSelector(
    (state) => state.userManagementReducer,
    shallowEqual
  );

  useEffect(() => {
    if (profileDetails?.profile?.role_id) {
      setLoggedInUserRoleId(profileDetails?.profile?.role_id);
    }
  }, [profileDetails]);

  // Handle get legal standards list
  const handleGetLegalStandardList = () => {
    dispatch(
      HandleApiActions({
        ...legalRequirements.get_legal_standard_details,
        show_toast: false,
      })
    );
  };

  useEffect(() => {
    handleGetRolesAndAccessList();
    handleGetLegalStandardList();
  }, []);

  useEffect(() => {
    let filteredRoleDepartment = rolesList
      ?.map((el) => {
        return {
          value: el.role_name,
          label: `${el.role_name} - ${el.department}`,
        };
      })
      .sort((a, b) => {
        return a.value.localeCompare(b.value);
      });

    setRoleDepartmentOptions(filteredRoleDepartment);
  }, [rolesList]);

  useEffect(() => {
    const filteredFeaturesList = featuresList?.filter(
      (el) => el.page_name == selectedMenuOption
    );
    setSelectedMenuOptionFeaturesList(filteredFeaturesList);
  }, [selectedMenuOption]);

  useEffect(() => {
    // get the legal standard options list
    let filteredLSOptionsList = legalStandardDetails?.map((el) => {
      return {
        value: el?.ls_id,
        label: el?.standard_number,
      };
    });
    setLSOptionsList(filteredLSOptionsList);

    // Select first legal standard by default
    if (legalStandardDetails[0]?.ls_id) {
      setSelectedLSId(legalStandardDetails[0]?.ls_id);
    }
  }, [legalStandardDetails]);

  useEffect(() => {
    // When the selected legal standard is changed, make the selected chapters to empty array.
    setLSSelectedChapters([]);
  }, [selectedLSId]);

  useEffect(() => {
    // get the selected legal standard details
    let filteredSelectedLSDetails = legalStandardDetails?.find(
      (el) => el?.ls_id == selectedLSId
    );

    //  Chapters options list for select option
    // It will list only the chapters which are not added
    const filteredLSChaptersOptionsList =
      filteredSelectedLSDetails?.chapters?.filter(
        (el) => !legal_chapters?.includes(el)
      ) || [];
    setLSChaptersOptionsList(filteredLSChaptersOptionsList);
  }, [selectedLSId, legalStandardDetails]);

  useEffect(() => {
    setLSAddedChaptersOptionsList(legal_chapters);
  }, [legal_chapters]);

  const isUpdateChaptersChanged = () => {
    return (
      JSON?.stringify([...legal_chapters]?.sort()) !==
      JSON?.stringify([...lsSelectedChapters]?.sort())
    );
  };

  // Handles the click event to update role
  const handleUpdateRoleClick = () => {
    let currentTime = new Date().toISOString();

    if (subDepartment !== sub_department || description !== desp) {
      dispatch(
        HandleApiActions({
          ...userManagementApis.update_role,
          params: {
            current_time: currentTime,
            ...(subDepartment
              ? { sub_department: subDepartment }
              : { sub_department: null }),
            ...(description ? { description } : { description: null }),
            role_id,
          },
          show_toast: true,
        })
      ).then(() => {
        handleGetRolesAndAccessList();
      });
    }
  };

  // Handles the click event to add chapters
  const handleAddChaptersClick = () => {
    let currentTime = new Date().toISOString();

    if (isUpdateChaptersChanged) {
      dispatch(
        HandleApiActions({
          ...userManagementApis.update_role,
          params: {
            current_time: currentTime,
            legal: { ls_id: selectedLSId, chapters: lsSelectedChapters },
            role_id,
          },
          show_toast: true,
        })
      ).then(() => {
        handleGetRolesAndAccessList();
      });
    }
  };

  // Handles the click event to remove chapters
  const handleRemoveChaptersClick = () => {
    let currentTime = new Date().toISOString();

    if (role_id && lsAddedSelectedChapters) {
      dispatch(
        HandleApiActions({
          ...userManagementApis.remove_chapters,
          params: {
            current_time: currentTime,
            legal_chapters: [...lsAddedSelectedChapters],
            role_id,
          },
          show_toast: true,
        })
      ).then(() => {
        handleGetRolesAndAccessList();
      });
    }
  };

  const handleGetUserProfileDetails = () => {
    dispatch(
      HandleApiActions({
        ...profileActions.get_profile_details,
        show_toast: false,
        params: {},
      })
    );
  };

  const handleRoleStatusChange = (newStatus) => {
    setPendingRoleStatus(newStatus);
    setOpenRoleStatusDialog(true);
  };

  const handleRoleDialogClose = (confirmed) => {
    let currentTime = new Date().toISOString();

    if (confirmed) {
      setIsRoleActive(pendingRoleStatus);

      handleRoleStatusClick({
        current_time: currentTime,
        is_active: pendingRoleStatus,
        role_id,
      });
    }
    setOpenRoleStatusDialog(false);
  };

  // Handles the click event to change role status
  const handleRoleStatusClick = ({ current_time, is_active, role_id }) => {
    dispatch(
      HandleApiActions({
        ...userManagementApis.change_role_status,
        params: { current_time, is_active, role_id },
        show_toast: true,
      })
    ).then(() => {
      handleGetRolesAndAccessList();
    });
  };

  // Handles the click event to add menus access
  const handleAddMenusAccessClick = ({ access_to_pages }) => {
    let currentTime = new Date().toISOString();

    if (role_id && access_to_pages?.length > 0) {
      dispatch(
        HandleApiActions({
          ...userManagementApis.add_page_access,
          params: { current_time: currentTime, role_id, access_to_pages },
          show_toast: true,
        })
      ).then(() => {
        handleGetRolesAndAccessList();
        handleGetUserProfileDetails();
      });
    }
  };

  // Handles the click event to remove menus access
  const handleRemoveMenusAccessClick = ({ access_to_pages }) => {
    let currentTime = new Date().toISOString();

    if (role_id && access_to_pages?.length > 0) {
      dispatch(
        HandleApiActions({
          ...userManagementApis.remove_page_access,
          params: { current_time: currentTime, role_id, access_to_pages },
          show_toast: true,
        })
      ).then(() => {
        handleGetRolesAndAccessList();
        handleGetUserProfileDetails();
      });
    }
  };

  // Handles the click event to add features access
  const handleAddFeaturesAccessClick = ({ access_to_features }) => {
    let currentTime = new Date().toISOString();

    if (role_id && access_to_features?.length > 0) {
      dispatch(
        HandleApiActions({
          ...userManagementApis.add_feature_access,
          params: { current_time: currentTime, role_id, access_to_features },
          show_toast: true,
        })
      ).then(() => {
        handleGetRolesAndAccessList();
        handleGetUserProfileDetails();
      });
    }
  };

  // Handles the click event to remove features access
  const handleRemoveFeaturesAccessClick = ({ access_to_features }) => {
    let currentTime = new Date().toISOString();

    if (role_id && access_to_features?.length > 0) {
      dispatch(
        HandleApiActions({
          ...userManagementApis.remove_feature_access,
          params: { current_time: currentTime, role_id, access_to_features },
          show_toast: true,
        })
      ).then(() => {
        handleGetRolesAndAccessList();
        handleGetUserProfileDetails();
      });
    }
  };

  // Handles the click event to delete role
  const handleDeleteRoleClick = () => {
    setOpenRoleDeleteDialog(true);
  };

  const handleConfirmRoleDelete = () => {
    let currentTime = new Date().toISOString();

    dispatch(
      HandleApiActions({
        ...userManagementApis.delete_role,
        params: { current_time: currentTime, role_id },
        show_toast: true,
      })
    ).then(() => {
      handleGetRolesAndAccessList();
    });

    toggleDrawer(false)();
  };

  const handleCancelRoleDelete = () => {
    setOpenRoleDeleteDialog(false);
  };

  const isUpdateRoleValid = () => {
    return (
      !subDepartmentError &&
      !descriptionError &&
      (subDepartment?.trim() !== sub_department || description?.trim() !== desp)
    );
  };

  // handle tab change event
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={styles.urcMainContainer}>
      <Box sx={styles.urcTxtMainContainer}>
        <Box sx={styles.urcTxtContainer}>
          <Typography
            variant="h6"
            textAlign="start"
            ml="10px"
            fontWeight="bold"
          >
            Update Role & Access
          </Typography>
        </Box>
        <IconButton sx={styles.closeIcon} onClick={toggleDrawer(false)}>
          <CloseIcon />
        </IconButton>
      </Box>

      {/* Tabs */}
      <Box sx={styles.tabsContainer}>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab sx={styles.tabs} label="Role Details" {...a11yProps(0)} />
          <Tab sx={styles.tabs} label="Menu Access" {...a11yProps(1)} />
          <Tab sx={styles.tabs} label="Features Access" {...a11yProps(2)} />
        </Tabs>
      </Box>

      {/* Role Details, Menu Access and Features Access*/}
      <Box sx={styles.SelectedTabContainer}>
        <CustomTabPanel value={tabValue} index={0}>
          <Card style={styles.urcCard}>
            <Typography
              fontWeight="bold"
              mt={1}
              textAlign={"center"}
              variant="h6"
              gutterBottom
            >
              Update Role
            </Typography>
            <Divider />
            <Grid
              container
              columnSpacing={2}
              rowSpacing={1}
              px={3}
              pt={2}
              pb={4}
            >
              {/* <Grid container columnSpacing={2} rowSpacing={1}> */}
              <Grid item xs={12}>
                <CustomTextField
                  size="small"
                  label="Role"
                  placeholder="Role"
                  variant={"standard"}
                  value={roleName}
                  setValue={setRoleName}
                  inputProps={{
                    disabled: true,
                  }}
                  txtFldStyle={styles.customTextField}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  size="small"
                  label="Role Type"
                  placeholder="Role"
                  variant={"standard"}
                  value={
                    roleType === 0
                      ? "TA requestors"
                      : roleType === 1
                      ? "TA reviewers"
                      : roleType === 2
                      ? "Supplier"
                      : ""
                  }
                  setValue={setRoleType}
                  inputProps={{
                    disabled: true,
                  }}
                  txtFldStyle={styles.customTextField}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  size="small"
                  label="Function"
                  placeholder="Function"
                  variant={"standard"}
                  value={department}
                  setValue={setDepartment}
                  inputProps={{
                    disabled: true,
                  }}
                  txtFldStyle={styles.customTextField}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextFieldN
                  size="small"
                  label="Sub Function"
                  placeholder="Sub Function"
                  variant={"standard"}
                  type={"text"}
                  fldType={"textO"}
                  value={subDepartment}
                  setValue={setSubDepartment}
                  valueError={subDepartmentError}
                  setValueError={setSubDepartmentError}
                  txtFldStyle={styles.customTextField}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextFieldN
                  size="small"
                  label="Organization"
                  placeholder="Organization"
                  variant={"standard"}
                  type={"text"}
                  fldType={"text"}
                  value={description}
                  setValue={setDescription}
                  valueError={descriptionError}
                  setValueError={setDescriptionError}
                  isMultiline={true}
                  minRows={1}
                  maxRows={5}
                  txtFldStyle={styles.customTextField}
                />
              </Grid>

              <Grid item xs={12}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-around"
                  sx={styles.toggleBtnContainer}
                >
                  <Typography variant="body1" component="span">
                    Role Status:
                  </Typography>

                  <CustomButton
                    size="small"
                    variant="contained"
                    btnName="Enabled"
                    handleOnClick={() => handleRoleStatusChange(true)}
                    btnStyle={styles.customButton}
                    endIcon={isRoleActive ? <CheckBoxIcon /> : null}
                  />
                  <CustomButton
                    size="small"
                    variant="contained"
                    btnName="Disabled"
                    handleOnClick={() => handleRoleStatusChange(false)}
                    btnStyle={styles.customButton}
                    endIcon={isRoleActive ? null : <CheckBoxIcon />}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Button
                  startIcon={<DeleteIcon />}
                  size="medium"
                  color="error"
                  variant="contained"
                  onClick={handleDeleteRoleClick}
                  // disabled={loggedInUserRoleId === rolesAndAccessData?.role_id}
                  sx={{
                    // width: "100%",
                    // width: "140px",
                    // mt: 2,
                    // borderRadius: "0px",
                    // bgcolor: "#f44336",
                    // "&:hover": {
                    //   bgcolor: "#d32f2f",
                    // },
                    ...appTheme.commonBtnStyle,
                    mt: 2,
                  }}
                >
                  Delete
                </Button>
              </Grid>
              <Grid item xs={6} textAlign={"center"}>
                <Button
                  startIcon={<UpdateIcon />}
                  size="medium"
                  color="primary"
                  variant="contained"
                  disabled={!isUpdateRoleValid()}
                  onClick={handleUpdateRoleClick}
                  sx={{ ...appTheme.commonBtnStyle, mt: 2 }}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </Card>
          {roleType == 1 ? (
            <Card style={styles.urcCardLS}>
              <Typography
                fontWeight="bold"
                mt={1}
                textAlign={"center"}
                variant="h6"
                gutterBottom
              >
                Legal Standard Chapters
              </Typography>
              <Divider />
              <Grid
                alignItems={"center"}
                container
                columnSpacing={2}
                rowSpacing={1}
                px={3}
                pt={2}
                pb={4}
              >
                <Grid item xs={9}>
                  <MultiSelectTextFieldNRemove
                    size="small"
                    label="Legal Standard Chapters"
                    optionsList={lsAddedChaptersOptionsList}
                    emptyOptionListText={"No chapters available"}
                    selectedValues={lsAddedSelectedChapters}
                    setSelectedValues={setLSAddedSelectedChapters}
                    sltFldStyle={styles.customTextFieldNA}
                    handleRemoveClick={() => {
                      handleRemoveChaptersClick();
                      toggleDrawer(false)();
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <CustomButton
                    startIcon={<AddIcon />}
                    size={"small"}
                    variant={"contained"}
                    btnName={"Add"}
                    handleOnClick={() =>
                      setIsAddChapterClicked(!isAddChapterClicked)
                    }
                    btnStyle={styles.customButton}
                  />
                </Grid>
                <Grid item xs={12}>
                  {isAddChapterClicked && (
                    <Card style={styles.urcCardLS}>
                      <Typography
                        fontWeight="bold"
                        mt={1}
                        textAlign={"center"}
                        fontSize={"17px"}
                        gutterBottom
                      >
                        Add Chapters
                      </Typography>
                      <Divider />
                      <Grid
                        alignItems={"center"}
                        container
                        columnSpacing={2}
                        rowSpacing={1}
                        px={2}
                        pt={2}
                        pb={4}
                      >
                        <Grid item xs={12}>
                          <SelectTextFieldN
                            size="small"
                            label="Select Standard No."
                            variant="standard"
                            optionsList={lsOptionsList}
                            selectedValue={selectedLSId}
                            handleOnChange={(e) =>
                              setSelectedLSId(e.target.value)
                            }
                            sltFldStyle={styles.lsSelectTextFieldNA}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <MultiSelectTextFieldNR
                            size={"small"}
                            label={"Select Chapters"}
                            optionsList={lsChaptersOptionsList}
                            selectedValues={lsSelectedChapters}
                            setSelectedValues={setLSSelectedChapters}
                            sltFldStyle={styles.customTextFieldNA}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Box sx={styles.customBtnACContainer}>
                            <CustomButton
                              startIcon={<AddIcon />}
                              size={"small"}
                              variant={"contained"}
                              btnName={"Add Chapters"}
                              handleOnClick={() => {
                                handleAddChaptersClick();
                                setIsAddChapterClicked(!isAddChapterClicked);
                                toggleDrawer(false)();
                              }}
                              disabled={lsSelectedChapters?.length <= 0}
                              btnStyle={styles.customButton}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Card>
                  )}
                </Grid>
              </Grid>
            </Card>
          ) : null}
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          <Box>
            <MenusCard
              menuOptions={menuOptionsList || []}
              selectedMenus={selectedMenus}
              setSelectedMenus={setSelectedMenus}
              handleAddMenusAccessClick={handleAddMenusAccessClick}
              handleRemoveMenusAccessClick={handleRemoveMenusAccessClick}
            />
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={2}>
          <Box>
            <FeaturesCard
              menuOptions={menu_options || []}
              featuresList={featuresList || []}
              selectedMenuOption={selectedMenuOption}
              setSelectedMenuOption={setSelectedMenuOption}
              selectedMenuOptionFeaturesList={selectedMenuOptionFeaturesList}
              selectedFeatures={selectedFeatures}
              setSelectedFeatures={setSelectedFeatures}
              handleAddFeaturesAccessClick={handleAddFeaturesAccessClick}
              handleRemoveFeaturesAccessClick={handleRemoveFeaturesAccessClick}
            />
          </Box>
        </CustomTabPanel>
      </Box>

      <CustomConfirmationDialog
        open={openRoleDeleteDialog}
        onClose={handleCancelRoleDelete}
        dialogTitle={"Confirm Delete"}
        dialogContent={
          <DialogContentText>
            Are you sure you want to delete this role?
          </DialogContentText>
        }
        onConfirm={handleConfirmRoleDelete}
      />

      <CustomConfirmationDialog
        open={openRoleStatusDialog}
        onClose={() => handleRoleDialogClose(false)}
        dialogTitle={"Confirm Status Change"}
        dialogContent={
          <DialogContentText>
            Are you sure you want to change the role status to{" "}
            {pendingRoleStatus ? "Enable" : "Disable"}?
          </DialogContentText>
        }
        onConfirm={() => handleRoleDialogClose(true)}
      />
    </Box>
  );
}

export default UpdateRolesAndAccess;

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 28-06-2024
 * @description : Show the menus card.
 * @param : menuOptions, selectedMenus, setSelectedMenus, handleAddMenusAccessClick, handleRemoveMenusAccessClick,
 * @return : The rendered menus card.
 */
const MenusCard = ({
  menuOptions,
  selectedMenus,
  setSelectedMenus,
  handleAddMenusAccessClick,
  handleRemoveMenusAccessClick,
}) => {
  customConsole("selectedMenus: " + selectedMenus);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [currentMenu, setCurrentMenu] = useState(null);
  const [actionMessage, setActionMessage] = useState("");

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    if (checked) {
      setSelectedMenus((prevItems) => [...prevItems, name]);
      setActionMessage(`Select: "${name}"`);
    } else {
      setSelectedMenus((prevItems) =>
        prevItems?.filter((item) => item !== name)
      );
      setActionMessage(`Deselect: "${name}"`);
    }

    setCurrentMenu(name);
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = (confirmed) => {
    if (confirmed) {
      // Handle confirmation logic here.
      if (selectedMenus.includes(currentMenu)) {
        if (currentMenu) {
          handleAddMenusAccessClick({ access_to_pages: [currentMenu] });
        }
      } else {
        if (currentMenu) {
          handleRemoveMenusAccessClick({ access_to_pages: [currentMenu] });
        }
      }
    } else {
      // Revert checkbox state if not confirmed
      if (selectedMenus.includes(currentMenu)) {
        setSelectedMenus(selectedMenus?.filter((menu) => menu !== currentMenu));
      } else {
        setSelectedMenus([...selectedMenus, currentMenu]);
      }
    }
    setConfirmDialogOpen(false);
  };

  return (
    <Box>
      <Card style={styles.mCard}>
        <Typography
          mt={1}
          textAlign={"center"}
          variant="h6"
          fontWeight="bold"
          gutterBottom
        >
          Access Menu Options
        </Typography>
        <Divider />
        <CardContent style={styles.mCardContent}>
          <Grid container spacing={2}>
            {menuOptions?.map((menu, index) => (
              <Grid item xs={12} key={index}>
                <Box display="flex" alignItems="center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedMenus.includes(menu)}
                        onChange={handleCheckboxChange}
                        name={menu}
                        disabled={
                          menu === "PROFILE" && selectedMenus.includes(menu)
                        }
                      />
                    }
                    label={menu}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>

      <CustomConfirmationDialog
        open={confirmDialogOpen}
        onClose={() => handleConfirmDialogClose(false)}
        dialogTitle={"Confirm Update"}
        dialogContent={
          <DialogContentText>
            Are you sure you want to {actionMessage}?
          </DialogContentText>
        }
        onConfirm={() => handleConfirmDialogClose(true)}
      />
    </Box>
  );
};

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 28-06-2024
 * @description : Shows the features card.
 * @param : featuresList, selectedMenuOption, setSelectedMenuOption, selectedMenuOptionFeaturesList, selectedFeatures, setSelectedFeatures, handleAddFeaturesAccessClick, handleRemoveFeaturesAccessClick,
 * @return : The rendered features card.
 */
const FeaturesCard = ({
  featuresList,
  selectedMenuOption,
  setSelectedMenuOption,
  selectedMenuOptionFeaturesList,
  selectedFeatures,
  setSelectedFeatures,
  handleAddFeaturesAccessClick,
  handleRemoveFeaturesAccessClick,
}) => {
  customConsole("selectedFeatures: " + selectedFeatures);
  const uniqueMenuList = [
    ...new Set(featuresList?.map((menu) => menu.page_name)),
  ];
  const menuOptionsList = uniqueMenuList?.map((pageName) => ({
    value: pageName,
    label: pageName,
  }));

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [currentFeatureId, setCurrentFeatureId] = useState(null);
  const [actionMessage, setActionMessage] = useState("");

  const handleCheckboxChange = (event, detail) => {
    const { name, checked } = event.target;
    const id = parseInt(name, 10);

    if (checked) {
      setSelectedFeatures((prevItems) => {
        if (!prevItems.includes(id)) {
          return [...prevItems, id];
        }
        return prevItems;
      });

      if (detail?.description) {
        setActionMessage(`Select: "${detail?.description}"`);
      }
    } else {
      setSelectedFeatures((prevItems) =>
        prevItems?.filter((item) => item !== id)
      );

      if (detail?.description) {
        setActionMessage(`Deselect: "${detail?.description}"`);
      }
    }

    setCurrentFeatureId(id);
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = (confirmed) => {
    if (confirmed) {
      // Handle confirmation logic here.
      if (selectedFeatures.includes(currentFeatureId)) {
        if (currentFeatureId) {
          handleAddFeaturesAccessClick({
            access_to_features: [currentFeatureId],
          });
        }
      } else {
        if (currentFeatureId) {
          handleRemoveFeaturesAccessClick({
            access_to_features: [currentFeatureId],
          });
        }
      }
    } else {
      // Revert checkbox state if not confirmed
      if (selectedFeatures.includes(currentFeatureId)) {
        setSelectedFeatures(
          selectedFeatures?.filter((feature) => feature !== currentFeatureId)
        );
      } else {
        setSelectedFeatures([...selectedFeatures, currentFeatureId]);
      }
    }
    setConfirmDialogOpen(false);
  };

  return (
    <Box>
      <Box sx={styles.fCardContainer}>
        <SelectTextField
          size={"small"}
          label={"Select Menu"}
          variant={"standard"}
          optionsList={menuOptionsList}
          selectedValue={selectedMenuOption}
          setSelectedValue={setSelectedMenuOption}
          sltFldStyle={styles.selectTextField}
        />
      </Box>

      {selectedMenuOptionFeaturesList?.map((feature, index) => (
        <Accordion key={index} style={styles.fAccordion}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              mt={1}
              textAlign={"center"}
              variant="body1"
              gutterBottom
            >
              {feature.feature_name}
            </Typography>
          </AccordionSummary>
          <Divider />
          <AccordionDetails>
            <Grid container spacing={2}>
              {feature.details?.map((detail, index1) => (
                <Grid item xs={12} key={index1}>
                  <Box display="flex" alignItems="center">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedFeatures.includes(detail.id)}
                          onChange={(e) => handleCheckboxChange(e, detail)}
                          name={detail.id.toString()}
                          disabled={
                            feature.feature_name === "Default" &&
                            selectedFeatures.includes(detail.id)
                          }
                        />
                      }
                      label={detail.description}
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}

      <CustomConfirmationDialog
        open={confirmDialogOpen}
        onClose={() => handleConfirmDialogClose(false)}
        dialogTitle={"Confirm Update"}
        dialogContent={
          <DialogContentText>
            Are you sure you want to {actionMessage}?
          </DialogContentText>
        }
        onConfirm={() => handleConfirmDialogClose(true)}
      />
    </Box>
  );
};

// Styles
const styles = {
  urcMainContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  urcTxtMainContainer: {
    display: "flex",
    alignItems: "center",
    p: 1,
    boxShadow: "3",
  },
  urcTxtContainer: {
    flexGrow: 1,
    textAlign: "center",
  },
  urctabsContainer: {
    display: "flex",
    justifyContent: "center",
    border: "1px solid lightgrey",
  },
  urcSelectedTabContainer: {
    flexGrow: 1,
    overflow: "auto",
  },
  urcCard: {
    marginTop: "8px",
    border: "1px solid lightgrey",
    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.2)",
  },
  urcCardLS: {
    marginTop: "25px",
    border: "1px solid lightgrey",
    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.2)",
  },
  mCard: {
    // marginBottom: "10px",
    border: "1px solid lightgrey",
    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.2)",
  },
  mCardContent: {
    height: "450px",
    overflowY: "auto",
  },
  fCardContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  fAccordion: {
    marginTop: "5px",
    marginBottom: "15px",
    border: "1px solid lightgrey",
    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.2)",
  },
  customTextField: {
    backgroundColor: "white",
    mt: 2,
  },
  selectTextField: {
    mb: 1,
    width: "220px",
    backgroundColor: "white",
  },
  lsSelectTextFieldN: {
    // width: "220px",
    width: "100%",
    mt: 2,
    mb: 1,
  },
  customTextFieldN: {
    // width: "220px",
    width: "100%",
    mt: 2,
    mb: 1,
  },
  customBtnACContainer: {
    display: "flex",
    justifyContent: "flex-end",
    mt: 2,
  },
  lsSelectTextFieldNA: {
    // width: "220px",
    width: "100%",
    my: 1,
  },
  customTextFieldNA: {
    // width: "220px",
    width: "100%",
    my: 1,
  },
  closeIcon: {
    color: "red",
  },
  tabs: {
    "&.Mui-selected": {
      fontWeight: 600,

      color: "black",
    },
  },
  toggleBtnContainer: {
    width: "100%",
    mt: 2,
  },
  toggleBtnGroup: {
    borderRadius: "20px",
    backgroundColor: "lightgrey",
  },
};
