/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 27-06-2024
 * @description Component for managing roles and access permissions.
 */
import { Box, Drawer, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { HandleApiActions, userManagementApis } from "../../redux/actions";
import UpdateRolesAndAccess from "./UpdateRolesAndAccess";
import AddIcon from "@mui/icons-material/Add";
import { convertUtcToTimeZone } from "../../utils/convertUtcToTimeZone";
import {
  CustomButton,
  CustomDataGridTable,
  CustomDataGridTableN,
  CustomFilters,
  CustomPagination,
  CustomSorting,
} from "../../components";
import CustomFiltersN from "../../components/tables/CustomFiltersN";

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 27-06-2024
 * @description Component for displaying roles and managing access permissions.
 * @param { setIsAddRole }
 * @return The rendered roles and access component.
 */
function RolesAndAccess({ setIsAddRole }) {
  const dispatch = useDispatch();

  const { rolesListMain } = useSelector(
    (state) => state.userManagementReducer,
    shallowEqual
  );

  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [tableRowsData, setTableRowsData] = useState([]);
  const [rolesAndAccessData, setRolesAndAccessData] = useState({});
  const profileDetails = useSelector((store) => store.profileReducer);

  // Pagination
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [hasMore, setHasMore] = useState(false);

  // Sorting
  const [anchorElSort, setAnchorElSort] = useState(null);
  const [openSort, setOpenSort] = useState(false);
  const [selectedSortColumn, setSelectedSortColumn] = useState("created_at");
  const [selectedSortOrder, setSelectedSortOrder] = useState("ASC");

  // Filtering
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedSearchField, setSelectedSearchField] = useState("role_name");
  const [searchFieldValue, setSearchFieldValue] = useState("");

  // Roles for filter
  const [userRolesOptions, setUserRolesOptions] = useState([]);

  useEffect(() => {
    if (rolesListMain?.length < rowsPerPage) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }

    let filteredRolesList = rolesListMain?.map((role, index) => {
      return {
        ...role,
        id: (page - 1) * rowsPerPage + index + 1,
        access_to_pages: role?.access_to_pages,
        access_to_features: role?.access_to_features,
        created_at: convertUtcToTimeZone(
          role?.created_at,
          profileDetails?.profile?.profile?.region
        ),
      };
    });

    setTableRowsData(filteredRolesList);

    // Roles list for filter
    let filteredRoles = rolesListMain
      ?.filter((el) => el?.is_active)
      ?.map((el) => {
        return {
          value: el?.role_id,
          label: `${el?.role_name} - ${el?.department}`,
        };
      })
      ?.sort((a, b) => {
        return a?.value?.localeCompare(b?.value);
      });

    setUserRolesOptions(filteredRoles);
  }, [rolesListMain]);

  useEffect(() => {
    handleGetRolesAndAccessList();
  }, [
    page,
    rowsPerPage,
    selectedSortColumn,
    selectedSortOrder,
    selectedSearchField,
    searchFieldValue,
  ]);

  const handleGetRolesAndAccessList = () => {
    dispatch(
      HandleApiActions({
        ...userManagementApis.get_roles_list_main,
        params: {
          page_number: page,
          page_size: rowsPerPage,
          sort_column: selectedSortColumn,
          sort_order: selectedSortOrder,
          ...(searchFieldValue && { [selectedSearchField]: searchFieldValue }),
        },
        show_toast: false,
      })
    );
  };

  // Handle page change
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Handle page size change
  const handlePageSizeChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1); // Reset to the first page when page size changes
  };

  // Open sort menu
  const handleSortClick = (event) => {
    setAnchorElSort(event.currentTarget);
    setOpenSort(true);
  };

  // Handle sort close
  const handleSortClose = () => {
    setAnchorElSort(null);
    setOpenSort(false);
  };

  // Handle sort change
  const handleSortChange = (column, order) => {
    setSelectedSortColumn(column);
    setSelectedSortOrder(order);

    setPage(1); // Reset to the first page when page size changes

    handleSortClose();
  };

  // Reset the sort values
  const handleSortResetAll = () => {
    handleSortChange("created_at", "ASC");
  };

  // Apply sorting
  const handleSortApplyNow = (selectedSortColumn, selectedSortOrder) => {
    handleSortChange(selectedSortColumn, selectedSortOrder);
  };

  // Open filter menu
  const handleFilterClick = (event) => {
    setAnchorElFilter(event.currentTarget);
    setOpenFilter(true);
  };

  // Handle filter close
  const handleFilterClose = () => {
    setAnchorElFilter(null);
    setOpenFilter(false);
  };

  // Handle filter change
  const handleFilterChange = (searchField, searchValue) => {
    setSelectedSearchField(searchField);
    setSearchFieldValue(searchValue);

    setPage(1); // Reset to the first page when page size changes

    handleFilterClose();
  };

  // Reset the filter values
  const handleFilterResetAll = () => {
    handleFilterChange("role_name", "");
  };

  // Apply filtering
  const handleFilterApplyNow = (searchField, searchValue) => {
    handleFilterChange(searchField, searchValue);
  };

  // Toggles the state of the drawer.
  const toggleUpdateDeleteDrawer = (newOpen) => () => {
    setOpenDrawer(newOpen);
  };

  // Handles the click event for adding a new role.
  const handleAddRoleClick = () => {
    setIsAddRole(true);
  };

  // Handles the click event for table rows for editing
  const handleTableRowClick = ({ params }) => {
    toggleUpdateDeleteDrawer(true)();

    setRolesAndAccessData({ ...params.row });
  };

  const COLUMNS = [
    {
      field: "id",
      headerName: "S.No",
      width: 90,
      filterable: false,
      sortable: false,
    },
    {
      field: "description",
      headerName: "Organization",
      width: 220,
    },
    {
      field: "role_name",
      headerName: "Role",
      width: 220,
    },
    {
      field: "department",
      headerName: "Function",
      width: 220,
    },
    {
      field: "sub_department",
      headerName: "Sub Function",
      width: 220,
      renderCell: (params) => (params.value ? params.value : "-"),
    },
    { field: "created_at", headerName: "Created At", width: 220 },
  ];

  return (
    <Box style={styles.rcMainContainer}>
      <Box sx={styles.tblFun}>
        {/* Add/Filter/Sorting buttons */}
        <Box sx={styles.btnsContainer}>
          <CustomFiltersN
            anchorEl={anchorElFilter}
            open={openFilter}
            handleClose={handleFilterClose}
            mainOptionsList={[
              { value: "role_name", label: "Role Name", type: "search" },
              {
                value: "role_id",
                label: "Roles",
                type: "select",
                value_options_list:
                  userRolesOptions?.length > 0 ? [...userRolesOptions] : [],
              },
              {
                value: "is_active",
                label: "Role Status",
                type: "select",
                value_options_list: [
                  { value: "true", label: "Active" },
                  { value: "false", label: "In-Active" },
                ],
              },
              { value: "department", label: "Function", type: "search" },
              {
                value: "sub_department",
                label: "Sub Function",
                type: "search",
              },
            ]}
            selectedMainOption={selectedSearchField}
            selectedMainOptionValue={searchFieldValue}
            handleFilterClick={handleFilterClick}
            onReset={handleFilterResetAll}
            onApplyNow={handleFilterApplyNow}
          />

          <CustomSorting
            anchorEl={anchorElSort}
            open={openSort}
            handleClose={handleSortClose}
            sortOptionsList={[
              { value: "role_name", label: "Role Name" },
              { value: "department", label: "Function" },
              { value: "sub_department", label: "Sub Function" },
              { value: "created_at", label: "Created At" },
              { value: "updated_at", label: "Updated At" },
            ]}
            selectedSortColumn={selectedSortColumn}
            selectedSortOrder={selectedSortOrder}
            handleSortClick={handleSortClick}
            onReset={handleSortResetAll}
            onApplyNow={handleSortApplyNow}
          />

          <CustomButton
            startIcon={<AddIcon />}
            size={"medium"}
            variant={"contained"}
            btnName={"Add"}
            handleOnClick={handleAddRoleClick}
            btnStyle={styles.customButton}
          />
        </Box>
      </Box>
      {/* Table */}
      <Paper elevation={4} sx={styles.rcPaper}>
        {/* Data grid table */}
        <CustomDataGridTableN
          tableRowsData={tableRowsData}
          columns={COLUMNS}
          handleTableRowClick={handleTableRowClick}
        />
        <Paper elevation={4} sx={styles.paginationPaper}>
          {/* Pagination with select page size */}
          <CustomPagination
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            hasMore={hasMore}
          />
        </Paper>
      </Paper>
      {/* <RightSideDrawer open={open} drawerData={drawerData} setDrawerData={setDrawerData} toggleDrawer={toggleDrawer} /> */}
      <UpdateDeleteRightSideDrawer
        open={openDrawer}
        toggleDrawer={toggleUpdateDeleteDrawer}
        rolesAndAccessData={rolesAndAccessData}
        handleGetRolesAndAccessList={handleGetRolesAndAccessList}
      />
    </Box>
  );
}

export default RolesAndAccess;

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 06-07-2024
 * @description : Update the roles and access drawer
 * @param :  open, rolesAndAccessData, toggleDrawer,
 * @return : The rendered drawer
 */
function UpdateDeleteRightSideDrawer({
  open,
  rolesAndAccessData,
  toggleDrawer,
  handleGetRolesAndAccessList,
}) {
  const handleOnClose = () => {
    toggleDrawer(false)();
  };

  return (
    <div>
      <Drawer anchor="right" open={open} onClose={handleOnClose}>
        <Box sx={styles.UDRSDContainer}>
          <UpdateRolesAndAccess
            rolesAndAccessData={rolesAndAccessData}
            toggleDrawer={toggleDrawer}
            handleGetRolesAndAccessList={handleGetRolesAndAccessList}
          />
        </Box>
      </Drawer>
    </div>
  );
}

// Styling for the RolesAndAccess component
const styles = {
  container: {
    width: "100%",
    overflow: "hidden",
  },
  paginationContainer: {
    display: "flex",
  },
  btnsContainer: {
    display: "flex",
    gap: 1,
  },
  tblFun: {
    mx: 2,
    mb: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  customButton: {
    ml: 2,
    // borderRadius: "0px",
  },
  UDRSDContainer: {
    width: "425px",
  },
  rcPaper: {
    height: 470,
    width: "100%",
    mb: 14,
    scrollbarColor: "#888 #f1f1f1",
    // maxWidth: { xs: "350px", sm: "400px", md: "100%" },
    // overflow: "auto",
  },
  paginationPaper: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px",
  },
  rcMainContainer: {
    marginBottom: "60px",
  },
};
