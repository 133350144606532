/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 28-07-2024
 * @description : Custom template dialog component.
 */
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import UpdateIcon from "@mui/icons-material/Update";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import appTheme from "../../assets/AppTheme/appTheme";
import CustomTextFieldN from "../text-fields/CustomTextFieldN";
import CustomButton from "../buttons/CustomButton";
import customConsole from "../../config/customConsole";
import DynamicFormTemplate from "./DynamicFormTemplate";
import { useRef } from "react";
import generatePDF from "react-to-pdf";
import typeApprovalAPI from "../../redux/actions/typeApprovalAPI";
import { HandleApiActions } from "../../redux/actions";
import { useDispatch } from "react-redux";
import { FORM_TYPES } from "../../config/appConstants";
import CustomLogsDialog from "../dialog-boxes/CustomLogsDialog";

const TemplateDialog = ({
  open,
  onClose,
  formType,
  projectId,
  variantId,
  ticketId,
  intrId,
  dialogTitle,
  dialogContent,
  onConfirm,
  onDisabled,
  // intersectionDetails,
  dynamicFormData,
  setDynamicFormData,
}) => {
  /* Dispatch */
  const dispatch = useDispatch();

  /* Refs */
  const templateRef = useRef();

  /* Constant States */
  const ALIGN_TYPES = { LEFT: "left", CENTER: "center", RIGHT: "right" };
  const FIELD_TYPES = { NUMBER: "number", TEXT: "string" };
  const EDIT_TYPES = { DDH: 100, SNOW: 200, BOTH: 300 };

  /* States */
  const [formName, setFormName] = useState("");
  // const [formType, setFormType] = useState(FORM_TYPES?.EE);
  const [selectedHeaders, setSelectedHeaders] = useState([ALIGN_TYPES?.CENTER]);
  const [selectedFooters, setSelectedFooters] = useState([ALIGN_TYPES?.CENTER]);
  const [selectedSignatures, setSelectedSignatures] = useState([
    ALIGN_TYPES?.RIGHT,
  ]);

  /* Template states */
  const [openTemplateChangeLogsDialog, setOpenTemplateChangeLogsDialog] =
    useState(false);
  const [templateChangeLogsList, setTemplateChangeLogsList] = useState([]);
  const [templateLogsDialogData, setTemplateLogsDialogData] = useState({});

  // Dynamic form data
  // const [dynamicFormData, setDynamicFormData] = useState(null);

  // Add/Update states
  const [fields, setFields] = useState([
    {
      id: 1,
      fieldName: "Field Name 1",
      fieldValue: "",
      fieldType: FIELD_TYPES?.NUMBER,
      editType: EDIT_TYPES?.DDH,
    },
    {
      id: 2,
      fieldName: "Field Name 2",
      fieldValue: "",
      fieldType: FIELD_TYPES?.NUMBER,
      editType: EDIT_TYPES?.SNOW,
    },
    {
      id: 3,
      fieldName: "Field Name 3",
      fieldValue: "",
      fieldType: FIELD_TYPES?.TEXT,
      editType: EDIT_TYPES?.BOTH,
    },
  ]);
  const [showAddUpdateField, setShowAddUpdateField] = useState(false);
  const [currentField, setCurrentField] = useState(null);

  const [updatedFieldsValues, setUpdatedFieldsValues] = useState([]);
  const [currentFieldValue, setCurrentFieldValue] = useState({
    field_name: "",
    value: "",
    default_value: "",
    edit_type: "",
    // field_type: "",
    sequence: "",
    reason: "",
  });
  const [showUpdateFieldValues, setShowUpdateFieldValues] = useState(false);

  const headerOptionsList = [
    {
      label: "Left",
      position: ALIGN_TYPES?.LEFT,
      value: "",
    },
    {
      label: "Center",
      position: ALIGN_TYPES?.CENTER,
      value: "",
    },
    {
      label: "Right",
      position: ALIGN_TYPES?.RIGHT,
      value: "",
    },
  ];

  const footerOptionsList = [
    {
      label: "Left",
      position: ALIGN_TYPES?.LEFT,
      value: "",
    },
    {
      label: "Center",
      position: ALIGN_TYPES?.CENTER,
      value: "",
    },
    {
      label: "Right",
      position: ALIGN_TYPES?.RIGHT,
      value: "",
    },
  ];

  const signatureOptionsList = [
    {
      label: "Left",
      position: ALIGN_TYPES?.LEFT,
      value: "",
    },
    {
      label: "Right",
      position: ALIGN_TYPES?.RIGHT,
      value: "",
    },
  ];

  /* Functions */
  /* Get Templates change logs */
  const handleGetTemplateChangeLogs = async ({ pageNo, pageSize }) => {
    let apiDetails = typeApprovalAPI.get_dynamic_form_logs;

    if (dynamicFormData && dynamicFormData?.form_id) {
      customConsole({
        ...apiDetails,
        params: {
          form_id: Number(dynamicFormData?.form_id),
          page_number: pageNo,
          page_size: pageSize,
        },
        show_toast: false,
      });

      const resp = await dispatch(
        HandleApiActions({
          ...apiDetails,
          params: {
            form_id: Number(dynamicFormData?.form_id),
            page_number: pageNo,
            page_size: pageSize,
          },
          show_toast: false,
        })
      );
      if (resp.error) {
        setTemplateChangeLogsList([]);
      } else {
        setTemplateChangeLogsList(resp?.data);
      }
    }
  };

  const handleTemplateLogsDialogOpen = (data) => {
    setOpenTemplateChangeLogsDialog(true);

    setTemplateLogsDialogData(data);
  };
  const handleTemplateLogsDialogClose = () => {
    setTemplateLogsDialogData(null);
    setTemplateChangeLogsList([]);

    setOpenTemplateChangeLogsDialog(false);
  };

  const updateFieldValues = async () => {
    let apiDetails = typeApprovalAPI.update_dynamic_form;

    if (dynamicFormData && dynamicFormData?.form_id) {
      customConsole({
        ...apiDetails,
        params: {
          form_id: Number(dynamicFormData?.form_id),
          field_details: updatedFieldsValues,
        },
        show_toast: true,
      });

      const resp = await dispatch(
        HandleApiActions({
          ...apiDetails,
          params: {
            form_id: Number(dynamicFormData?.form_id),
            field_details: updatedFieldsValues,
          },
          show_toast: true,
        })
      );
      if (resp.error) {
      } else {
      }
    }

    setUpdatedFieldsValues([]);
  };

  const handleUpdateFieldValues = () => {
    // Check if currentFieldValue?.sequence exists
    if (currentFieldValue?.sequence) {
      // Add to setUpdatedFieldsValues
      setUpdatedFieldsValues((prev) => [
        ...prev,
        {
          field_name: currentFieldValue?.field_name,
          value: currentFieldValue?.value,
          reason: currentFieldValue?.reason,
        },
      ]);

      // Update the dynamic form data
      setDynamicFormData((prev) => ({
        ...prev,
        fields: prev?.fields?.map((field1) =>
          field1?.sequence === currentFieldValue?.sequence
            ? { ...field1, value: currentFieldValue?.value }
            : field1
        ),
      }));
    }

    // Reset form and close it after adding or updating the field
    resetFieldValuesInputs();
  };

  const resetFieldValuesInputs = () => {
    // Reset the form fields
    setCurrentField({
      field_name: "",
      value: "",
      default_value: "",
      edit_type: "",
      // field_type: "",
      sequence: "",
      reason: "",
    });

    // Close the "Update Field" form
    setShowUpdateFieldValues(false);
  };

  const handleFieldValueChange = (field, value) => {
    setCurrentFieldValue((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleEditUpdateField = (fieldName) => {
    // Find the field to update by its name
    let fieldToUpdate = dynamicFormData?.fields?.find(
      (field1) => field1?.field_name === fieldName
    );

    // If the field is found, set it in the `currentFieldeValue` state
    if (fieldToUpdate) {
      setCurrentFieldValue(fieldToUpdate);
      setShowUpdateFieldValues(true);
    }
  };

  /* UseEffects */
  useEffect(() => {
    // Define valid positions
    const validPositions = ["left", "center", "right"];
    const validPositions1 = ["left", "right"];

    // Update the selected headers list with valid positions
    let selectedHeadersList =
      dynamicFormData?.headers
        ?.map((el) =>
          validPositions?.includes(el?.position) ? String(el?.position) : null
        )
        ?.filter(Boolean) || [];
    setSelectedHeaders(selectedHeadersList);

    // Update the selected signatures list with valid positions
    let selectedSignaturesList =
      dynamicFormData?.signatures
        ?.map((el) =>
          validPositions1?.includes(el?.position) ? String(el?.position) : null
        )
        ?.filter(Boolean) || [];
    setSelectedSignatures(selectedSignaturesList);

    // Update the selected footers list with valid positions
    let selectedFootersList =
      dynamicFormData?.footers
        ?.map((el) =>
          validPositions?.includes(el?.position) ? String(el?.position) : null
        )
        ?.filter(Boolean) || [];
    setSelectedFooters(selectedFootersList);
  }, [open, dynamicFormData]);

  useEffect(() => {
    customConsole({ dynamicFormData17: dynamicFormData });
  }, [dynamicFormData]);

  return (
    <Dialog
      open={open}
      // onClose={onClose}
      fullWidth
      maxWidth={"md"}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          textAlign: "center",
          boxShadow: 2,
          py: 1,
        }}
      >
        {/* Title text */}
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          {dialogTitle}
        </Typography>

        {/* Close Icon Button */}
        <IconButton
          edge="end"
          color="inherit"
          onClick={() => {
            onClose();
            resetFieldValuesInputs();
            setUpdatedFieldsValues([]);
          }}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        {dynamicFormData?.intr_id ? (
          <>
            {/* Change logs icon & Download template icon */}
            <Box sx={{ display: "flex", justifyContent: "flex-end", mb: -2 }}>
              {/* Change logs icon */}
              <Tooltip title="Change logs">
                <IconButton
                  size="small"
                  sx={{ color: "green" }}
                  onClick={(e) =>
                    handleTemplateLogsDialogOpen({ pageNo: 1, pageSize: 25 })
                  }
                >
                  <HistoryEduIcon fontSize="medium" />
                </IconButton>
              </Tooltip>
              {/* Download template icon */}
              <Tooltip title="Download template">
                <IconButton
                  size="small"
                  sx={{ color: "green" }}
                  onClick={() =>
                    generatePDF(templateRef, { filename: "page.pdf" })
                  }
                >
                  <FileDownloadIcon fontSize="medium" />
                </IconButton>
              </Tooltip>
            </Box>
            {/* Form details */}
            <Paper elevation={2} sx={styles.intDetailsContainer}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  textAlign: "center",
                  pt: 0.4,
                }}
              >
                Form Details
              </Typography>
              <Divider sx={{ mt: 0.4, mb: 1 }} />
              <Grid container sx={{ mt: 1 }}>
                {/* Intersection Id */}
                <Grid item xs={12} sm={6}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mb: 1,
                    }}
                  >
                    <Typography variant="subtitle2" sx={styles.intDetailsTxt}>
                      <span style={styles.txtBold}>Intersection id: </span>
                      {dynamicFormData?.intr_id || "-"}
                    </Typography>
                  </Box>
                </Grid>
                {/* Ticket Id */}
                <Grid item xs={12} sm={6}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mb: 1,
                    }}
                  >
                    <Typography variant="subtitle2" sx={styles.intDetailsTxt}>
                      <span style={styles.txtBold}>Ticket id: </span>
                      {ticketId || "-"}
                    </Typography>
                  </Box>
                </Grid>
                {/* Form name */}
                <Grid item xs={12} sm={6}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mb: 1,
                    }}
                  >
                    <Typography variant="subtitle2" sx={styles.intDetailsTxt}>
                      <span style={styles.txtBold}>Form name: </span>
                      {dynamicFormData?.name || "-"}
                    </Typography>
                  </Box>
                </Grid>
                {/* Form type */}
                <Grid item xs={12} sm={6}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mb: 1,
                    }}
                  >
                    <Typography variant="subtitle2" sx={styles.intDetailsTxt}>
                      <span style={styles.txtBold}>Form type: </span>
                      {dynamicFormData?.type === FORM_TYPES?.EE
                        ? "EE Component"
                        : dynamicFormData?.type === FORM_TYPES?.SWM
                        ? "Software Module"
                        : dynamicFormData?.type === FORM_TYPES?.VEHICLE
                        ? "Vehicle Component"
                        : "-"}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
            {/* Template */}
            {/* Template & Update field values */}
            <Box sx={{ display: "flex", gap: 2.5 }}>
              <Box
                ref={templateRef}
                sx={{
                  minHeight: "315mm",
                  padding: "10px",
                  display: "flex",
                  textDecoration: "none",
                  justifyContent: "space-between",
                }}
              >
                <DynamicFormTemplate
                  showOnlyTemplate={true}
                  handleEditField={() => {}}
                  showFieldsUpdate={true}
                  handleEditUpdateField={handleEditUpdateField}
                  headerOptionsList={headerOptionsList}
                  signatureOptionsList={signatureOptionsList}
                  footerOptionsList={footerOptionsList}
                  selectedHeaders={selectedHeaders}
                  selectedSignatures={selectedSignatures}
                  selectedFooters={selectedFooters}
                  dynamicFormData={dynamicFormData}
                  setDynamicFormData={setDynamicFormData}
                />
              </Box>
              {/* Update field values */}
              {showUpdateFieldValues ? (
                <Paper
                  elevation={2}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    px: 1,
                    py: 1.4,
                    border: "1px solid grey",
                    borderRadius: 1,
                    height: "262px",
                    width: "240px",
                  }}
                >
                  {/* Title text */}
                  <Typography
                    sx={{
                      fontSize: "17px",
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    Update Field
                  </Typography>
                  <Divider
                    sx={{ m: -1, p: 0, border: "1px solid lightgrey" }}
                  />

                  {/* Enter field name */}
                  <Box>
                    <Typography
                      variant="subtitle1"
                      color={"grey"}
                      component="label"
                      htmlFor="field-name"
                    >
                      field name*
                    </Typography>
                    <TextField
                      fullWidth
                      id="field-name"
                      variant="standard"
                      type={"text"}
                      size={"small"}
                      disabled={true}
                      value={currentFieldValue?.field_name || ""}
                      onChange={(e) => {}}
                      sx={{ width: "220px", mt: -0.7 }}
                    />
                  </Box>

                  {/* Enter field value */}
                  <Box>
                    <Typography
                      variant="subtitle1"
                      color={"grey"}
                      component="label"
                      htmlFor="field-value"
                    >
                      Enter field value*
                    </Typography>
                    <TextField
                      fullWidth
                      id="field-name"
                      variant="standard"
                      type={"text"}
                      size={"small"}
                      value={currentFieldValue?.value || ""}
                      onChange={(e) =>
                        handleFieldValueChange("value", e.target?.value)
                      }
                      sx={{ width: "220px", mt: -0.7 }}
                    />
                  </Box>

                  {/* Enter reason */}
                  <Box>
                    <Typography
                      variant="subtitle1"
                      color={"grey"}
                      component="label"
                      htmlFor="reason"
                    >
                      Enter reason*
                    </Typography>
                    <TextField
                      fullWidth
                      id="reason"
                      variant="standard"
                      type={"text"}
                      size={"small"}
                      value={currentFieldValue?.reason || ""}
                      onChange={(e) =>
                        handleFieldValueChange("reason", e.target?.value)
                      }
                      sx={{ width: "220px", mt: -0.7 }}
                    />
                  </Box>

                  {/* Close & Update buttons */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: 1.4,
                      mt: 2,
                    }}
                  >
                    <CustomButton
                      size={"small"}
                      variant={"contained"}
                      btnName={"Close"}
                      startIcon={<CloseIcon />}
                      handleOnClick={resetFieldValuesInputs} // Reset the form
                      btnStyle={{}}
                    />
                    <CustomButton
                      size={"small"}
                      variant={"contained"}
                      btnName={"Update"}
                      disabled={
                        !(
                          currentFieldValue?.field_name?.length &&
                          currentFieldValue?.value?.length &&
                          currentFieldValue?.reason?.length
                        )
                      }
                      startIcon={<UpdateIcon />}
                      handleOnClick={handleUpdateFieldValues}
                      btnStyle={{}}
                    />
                  </Box>
                </Paper>
              ) : null}
            </Box>
            {/* Change logs */}
            <CustomLogsDialog
              open={openTemplateChangeLogsDialog}
              handleClose={handleTemplateLogsDialogClose}
              dialogTitle={"Change Logs"}
              dialogContent={templateChangeLogsList}
              data={templateLogsDialogData}
              getLogsData={handleGetTemplateChangeLogs}
            />
          </>
        ) : (
          <Box
            style={{
              height: "370px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">No attachments found</Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ boxShadow: 4, gap: 2, px: 2, py: 1 }}>
        <CustomButton
          size={"small"}
          variant={"contained"}
          btnName={"Close"}
          startIcon={<CloseIcon />}
          handleOnClick={() => {
            onClose();
            resetFieldValuesInputs();
            setUpdatedFieldsValues([]);
          }}
          btnStyle={{}}
        />
        {dynamicFormData?.intr_id ? (
          <CustomButton
            size={"small"}
            variant={"contained"}
            btnName={"Update"}
            startIcon={<UpdateIcon />}
            disabled={
              !updatedFieldsValues?.length ||
              !updatedFieldsValues?.every(
                (item) => item?.field_name && item?.value && item?.reason
              )
            }
            handleOnClick={() => {
              onClose();
              resetFieldValuesInputs();
              updateFieldValues();
            }}
            btnStyle={{}}
          />
        ) : null}
      </DialogActions>
    </Dialog>
  );
};

export default TemplateDialog;

const styles = {
  intDetailsContainer: {
    px: 2,
    // py: 1,
    // mx: 1,
    height: "100%",
    border: "1px solid grey",
    borderRadius: 1,
  },
  intDetailsTxt: {
    textAlign: "start",
    alignContent: "center",
  },
  txtBold: {
    fontWeight: "bold",
  },
};
