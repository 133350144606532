import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React from "react";

function ViewDynammicFormDetails({ data, open, handleClose, selectedHeaders }) {
  const EDIT_TYPES = { DDH: 100, SNOW: 200, BOTH: 300 };
  function getKeyByValue(value) {
    const type = [
      { key: "SOFTWARE MODULE", value: 100 },
      { key: "EE COMPONENT", value: 200 },
      { key: "VEHICLE COMPONENT", value: 300 },
    ]?.find((item) => item.value === value);
    return type ? type.key : "Key not found";
  }

  console.log("View Data", data);

  return (
    <>
      <Dialog fullWidth maxWidth={"sm"} onClose={handleClose} open={open}>
        <DialogTitle sx={{ fontWeight: "bold" }}>
          Name of the Form <strong>:</strong>
          {data?.name}
        </DialogTitle>

        <DialogContent>
          <Box sx={{ display: "flex" }}>
            <Typography sx={{ fontWeight: "bold" }}>Type Of Form</Typography>
            <strong>:</strong>
            <Typography>{getKeyByValue(data?.type)}</Typography>
          </Box>
          <Paper
            sx={{
              border: "1px solid grey",
              borderRadius: 1,
              flexGrow: 1,
              mt: "10px",
            }}
          >
            {/* Template Headers */}
            <Box
              sx={{
                p: 1.4,
                display: "flex",
                justifyContent: "space-between",
                gap: 2,
              }}
            >
              {data?.headers?.map((item, index) => (
                //  Enter field name
                <Box key={index} sx={{ visibility: "visible" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      paddingRight: "15px",
                    }}
                  >
                    <Box sx={{ flexGrow: 1, marginLeft: "15px" }}>
                      {" "}
                      {/* Make this section grow to fill remaining space */}
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        {item?.title}
                      </Typography>
                      <Typography variant="body2">{item?.sub_title}</Typography>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>

            <Divider sx={{ border: "1px solid lightGrey" }} />
            {/* Template Fields */}
            <Box sx={{ minHeight: "250px", m: 2.5 }}>
              {data?.fields?.map((field, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    mb: 1.4,
                  }}
                >
                  <Typography variant="subtitle1" sx={{ fontWeight: "normal" }}>
                    {field?.sequence}
                  </Typography>
                  <strong>.</strong>
                  <Typography
                    sx={{ fontWeight: "bold" }}
                    variant="subtitle1"
                    color={
                      field?.editType === EDIT_TYPES?.DDH
                        ? "blue"
                        : field?.editType === EDIT_TYPES?.SNOW
                        ? "grey"
                        : "black"
                    }
                  >
                    {field?.field_name}
                  </Typography>
                  <strong>:</strong>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mt: -1,
                    }}
                  >
                    <Typography sx={{ mt: "10px" }} variant="subtitle1">
                      {field?.value}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
            {/* Template Signature */}
            <Box
              sx={{
                p: 1.4,
                display: "flex",
                justifyContent: "space-between",
                mb: 2,
                gap: 2,
              }}
            >
              {data?.signatures?.map((item, index) => (
                //  Enter field name
                <Box
                  key={index}
                  sx={{
                    visibility: "visible",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      ml: "10px",
                    }}
                  ></Box>
                  <Box sx={{ flexGrow: 1, marginLeft: "15px" }}>
                    {" "}
                    {/* Make this section grow to fill remaining space */}
                    <Typography>{item?.title}</Typography>
                    <Typography>{item?.sub_title}</Typography>
                  </Box>
                </Box>
              ))}
            </Box>
            <Divider sx={{ border: "1px solid lightGrey" }} />
            {/* Template Footers */}
            <Box
              sx={{
                p: 1.4,
                display: "flex",
                justifyContent: "space-between",
                gap: 2,
              }}
            >
              {data?.footers?.map((item, index) => (
                //  Enter field name
                <Box
                  key={index}
                  sx={{
                    visibility: "visible",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      paddingRight: "15px",
                    }}
                  >
                    <Box sx={{ flexGrow: 1, marginLeft: "15px" }}>
                      {" "}
                      {/* Make this section grow to fill remaining space */}
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        {item?.title}
                      </Typography>
                      <Typography variant="body2">{item?.sub_title}</Typography>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Paper>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ViewDynammicFormDetails;
