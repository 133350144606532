import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Badge,
  Box,
  Button,
  Chip,
  Collapse,
  Divider,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  styled,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TabContext, TabPanel } from "@mui/lab";
import InfoButton from "../../../components/infoButton/InfoButton";
import appTheme from "../../../assets/AppTheme/appTheme";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import EditVehicleFunction from "../EditVehicleFunction";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import {
  CustomLogsDialog,
  CustomTextFieldSP,
  CustomUpdateDialog,
} from "../../../components";
import moment from "moment";
import { useDispatch } from "react-redux";
import { HandleApiActions, projectConfigAPI } from "../../../redux/actions";
import customConsole from "../../../config/customConsole";
import UpdateStatus from "../UpdateStatus";
import UpdateDeliveryMarket from "../UpdateDeliveryMarket";
import EditPowertrainClass from "../EditPowertrainClass";
import AddMftDetails from "../AddMftDetails";
import { GetSingleImages } from "../../../redux/actions/actionHandler";

function ProjectDetails({ selectedProjectData }) {
  console.log("selectedProjectData", selectedProjectData);
  const projectStatus = {
    100: (
      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            position: "absolute",
            left: 97,
            bottom: 10,
            p: 0,
          }}
        >
          <InfoButton infoKey={"project_status"} color={"grey"} />
        </Box>
        <Chip
          sx={{
            boxShadow: "3",
            fontWeight: "bold",
            borderRadius: "0px 15px 0px 15px",
            backgroundColor: "#c8e8fa",
            color: "black",
          }}
          label={"Active Pre SOP"}
          size="medium"
        />
      </Box>
    ),
    101: (
      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            position: "absolute",
            left: 98,
            bottom: 14,
          }}
        >
          <InfoButton infoKey={"project_status"} color={"white"} />
        </Box>
        <Chip
          sx={{
            boxShadow: "3",
            fontWeight: "bold",
            borderRadius: "0px 15px 0px 15px",
            backgroundColor: "#c8e8fa",
            color: "black",
          }}
          label={"Draft"}
          size="medium"
        />
      </Box>
    ),
    200: (
      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            position: "absolute",
            left: 100,
            bottom: 10,
            p: 0,
          }}
        >
          <InfoButton infoKey={"project_status"} color={"grey"} />
        </Box>
        <Chip
          sx={{
            boxShadow: "3",
            fontWeight: "bold",
            borderRadius: "0px 15px 0px 15px",
            backgroundColor: "#f7f6cd",
            color: "black",
          }}
          label={"Active Post SOP"}
          size="medium"
        />
      </Box>
    ),
    300: (
      <Chip
        sx={{
          boxShadow: "3",
          fontWeight: "bold",
          borderRadius: "0px 15px 0px 15px",
          backgroundColor: "red",
          color: "white",
        }}
        label={"Deactivated"}
        size="medium"
      />
    ),
    500: (
      <Chip
        sx={{
          boxShadow: "3",
          fontWeight: "bold",
          borderRadius: "0px 15px 0px 15px",
          backgroundColor: "#9af5a2",
          color: "green",
        }}
        label={"Completed"}
        size="medium"
      />
    ),
  };

  const dispatch = useDispatch();

  const [open, setOpen] = useState("1");
  const [value, setValue] = useState("1");
  const [image, setImage] = useState("");
  const [openProjectChangeLogsDialog, setOpenProjectChangeLogsDialog] =
    useState(false);
  const [projectLogsDialogData, setProjectLogsDialogData] = useState({});
  const [openVfDialog, setOpenVfDialog] = useState(false);
  const [editVF, setEditVF] = useState([]);
  const [openPcDialog, setOpenPcDialog] = useState(false);
  const [editPC, setEditPC] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [editTradeMark, setEditTradeMark] = useState("");
  const [projectChangeLogsList, setProjectChangeLogsList] = useState([]);
  const [trademarkError, setTradeMarkError] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [openDeliveryMarket, setOpenDeliveryMarket] = useState(false);
  const [openMftDialog, setOpenMftDialog] = useState(false);
  const [selectMft, setSelectMft] = useState({
    name: "",
    address: "",
  });

  //2.useEffect

  // useEffect(() => {
  //   dispatch(
  //     HandleApiActions({
  //       ...projectConfigAPI.get_project,
  //       params: { project_id: parseInt(selectedProjectData?.project_id) },
  //     })
  //   );
  // }, []);

  const imageURL = async (imageDetails) => {
    const resp = await GetSingleImages(imageDetails);
    customConsole("imagesURL RESP: ");
    customConsole(resp);
    setImage(resp);
    return resp;
  };
  React.useEffect(() => {
    if (selectedProjectData) {
      setEditTradeMark(selectedProjectData.trade_mark || "");

      const vehicleFunctionDetails = Array.isArray(
        selectedProjectData.vehicle_function_details
      )
        ? selectedProjectData.vehicle_function_details.map((vf) =>
            String(vf?.id)
          )
        : [];

      setEditVF(vehicleFunctionDetails);

      const powerTrainClassDetails = Array.isArray(
        selectedProjectData.powertrain_class_details
      )
        ? selectedProjectData.powertrain_class_details?.map((vf) =>
            String(vf?.id)
          )
        : [];

      setEditPC(powerTrainClassDetails);
    }
    if (
      selectedProjectData.vehicle_class_details &&
      selectedProjectData.vehicle_class_details.length
    ) {
      imageURL({ image: selectedProjectData.vehicle_class_details[0].image });
    }
  }, [selectedProjectData]);

  const handleClick = (value) => {
    setOpen(open === value ? null : value);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleProjectDialogOpen = (data) => {
    setOpenProjectChangeLogsDialog(true);

    // handleGetProjectChangeLogs();

    setProjectLogsDialogData(data);
  };
  const handleClickEdit = () => {
    const reqObj = {
      project_id: parseInt(selectedProjectData?.project_id),

      ...(openVfDialog === false ? { trade_mark: editTradeMark } : {}),
      ...(openVfDialog === true
        ? { vehicle_funcs: editVF?.map((str) => parseInt(str)) }
        : {}),
      ...(openPcDialog === true
        ? { pwrtrn_class: editPC?.map((str) => parseInt(str)) }
        : {}),
      ...(openMftDialog === true ? { mft_details: selectMft } : {}),

      current_time: moment().format(),
    };

    dispatch(
      HandleApiActions({
        ...projectConfigAPI.update_project,
        params: reqObj,
        show_toast: true,
      })
    ).then(() => {
      dispatch(
        HandleApiActions({
          ...projectConfigAPI.get_project,
          params: { project_id: parseInt(selectedProjectData?.project_id) },
        })
      );
    });

    setOpenDialog(false);
    setOpenVfDialog(false);
    setOpenPcDialog(false);
    setOpenMftDialog(false);
  };

  const handleProjectDialogClose = () => {
    setProjectLogsDialogData(null);
    setProjectChangeLogsList([]);

    setOpenProjectChangeLogsDialog(false);
  };

  const handleGetProjectChangeLogs = async ({ pageNo, pageSize }) => {
    let apiDetails = projectConfigAPI.get_project_change_logs;

    if (selectedProjectData && selectedProjectData?.project_id) {
      customConsole({
        ...apiDetails,
        params: {
          project_id: Number(selectedProjectData?.project_id),
          page_number: pageNo,
          page_size: pageSize,
        },
        show_toast: false,
      });

      const resp = await dispatch(
        HandleApiActions({
          ...apiDetails,
          params: {
            project_id: Number(selectedProjectData?.project_id),
            page_number: pageNo,
            page_size: pageSize,
          },
          show_toast: false,
        })
      );
      if (resp.error) {
        setProjectChangeLogsList([]);
      } else {
        setProjectChangeLogsList(resp?.data);
      }
    }
  };

  const getStatusName = (statusCode) => {
    return projectStatus[statusCode] || "Unknown Status";
  };

  return (
    <div>
      <List sx={styles.list} component="nav">
        <ListItemButton onClick={() => handleClick("1")}>
          <ListItemText
            sx={styles.listItemText}
            primary="Project Configuration"
            primaryTypographyProps={styles.typographyProps}
          />

          <Box>{open === "1" ? <ExpandLessIcon /> : <ExpandMoreIcon />}</Box>
        </ListItemButton>

        <Collapse in={open === "1"} timeout="auto" unmountOnExit appear>
          <Divider sx={styles.divider} />
          <List component="div" disablePadding sx={styles.listMain}>
            <Box>
              <Box></Box>
              <Box>
                <Grid
                  container
                  sx={{
                    mt: "10px",
                  }}
                >
                  {/* Left Section */}
                  <Grid xs={12} md={3}>
                    <Box>
                      {[
                        {
                          title: "Name",
                          value: selectedProjectData?.name,
                        },
                        {
                          title: "Architecture",
                          value: selectedProjectData?.architecture_name,
                        },
                        {
                          title: "Project Status",
                          value: selectedProjectData?.status,
                        },
                      ]?.map((item, index) => (
                        <Box
                          sx={{
                            display: "flex",
                            //justifyContent: "space-between",
                            padding: "8px 16px",
                          }}
                        >
                          <Box>
                            <Typography sx={{ fontWeight: "bold" }}>
                              {item?.title}
                            </Typography>
                          </Box>
                          <strong>:</strong>
                          {item?.title !== "Project Status" && (
                            <Box>
                              <Typography>{item?.value}</Typography>
                            </Box>
                          )}

                          {item?.title === "Project Status" ? (
                            <Box sx={styles.dataStatus}>
                              <Typography ml="10px">
                                {getStatusName(selectedProjectData?.status)}
                              </Typography>
                              <IconButton
                                sx={{ color: "blue" }}
                                size="small"
                                onClick={() => setOpenStatus(true)}
                              >
                                <EditIcon sx={styles.editIcon} />
                              </IconButton>
                            </Box>
                          ) : null}
                        </Box>
                      ))}
                    </Box>
                  </Grid>

                  {/* Right Section */}
                  <Grid item xs={12} md={3}>
                    <Box>
                      {[
                        {
                          title: "Vehicle Name",
                          value:
                            selectedProjectData?.vehicle_class_details?.[0]
                              ?.name,
                        },
                        {
                          title: "Vehicle Class",
                          value: `${selectedProjectData?.vehicle_class_details?.[0]?.class} - ${selectedProjectData?.vehicle_class_details?.[0]?.sub_class}`,
                        },
                        // {
                        //   title: "Updated At",
                        //   value: moment(selectedProjectData?.updated_at).format(
                        //     "YYYY-MM-DD HH:mm:ss"
                        //   ),
                        // },
                      ]?.map((item, index) => (
                        <Box
                          sx={{
                            display: "flex",
                            //justifyContent: "space-between",
                            padding: "8px 16px",
                          }}
                        >
                          <Box>
                            <Typography sx={{ fontWeight: "bold" }}>
                              {item?.title}
                            </Typography>
                          </Box>
                          <strong>:</strong>
                          <Box sx={{ display: "flex" }}>
                            <Typography>{item?.value}</Typography>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                    {/* Vehicle Image */}
                    <Box sx={styles.vehicleImageBox}>
                      <img
                        src={image}
                        style={styles.imageBox}
                        alt="vehicle pic!"
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <Box>
                      {[
                        {
                          title: "Trademark",
                          value:
                            selectedProjectData?.trade_mark === ""
                              ? selectedProjectData?.name
                              : selectedProjectData?.trade_mark,
                        },
                        {
                          title: "Vehicle Platform",
                          value: selectedProjectData?.vehicle_platform,
                        },

                        {
                          title: "Created At",
                          value: moment(selectedProjectData?.created_at).format(
                            "YYYY-MM-DD HH:mm:ss"
                          ),
                        },
                      ]?.map((item, index) => (
                        <Box
                          sx={{
                            display: "flex",

                            padding: "8px 16px",
                          }}
                        >
                          <Box>
                            <Typography sx={{ fontWeight: "bold" }}>
                              {item?.title}
                            </Typography>
                          </Box>
                          <strong>:</strong>
                          <Box>
                            <Typography>{item?.value}</Typography>
                          </Box>
                          {item?.title === "Trademark" ? (
                            <IconButton
                              sx={{ color: "blue" }}
                              size="small"
                              onClick={() => setOpenDialog(true)}
                            >
                              <EditIcon sx={styles.editIcon} />
                            </IconButton>
                          ) : null}
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Box>
                      {[
                        {
                          title: "Target Market",
                          value: selectedProjectData?.target_market,
                        },
                        {
                          title: "No.Of.Seats",
                          value: selectedProjectData?.no_of_seats,
                        },
                        {
                          title: "Model Year",
                          value: selectedProjectData?.model_year || "-",
                        },
                      ]?.map((item, index) => (
                        <Box
                          sx={{
                            display: "flex",

                            padding: "8px 16px",
                          }}
                        >
                          <Box>
                            <Typography sx={{ fontWeight: "bold" }}>
                              {item?.title}
                            </Typography>
                          </Box>
                          <strong>:</strong>
                          <Box>
                            <Typography>{item?.value}</Typography>
                          </Box>
                          {item?.title === "Trademark" ? (
                            <IconButton
                              sx={{ color: "blue" }}
                              size="small"
                              onClick={() => setOpenDialog(true)}
                            >
                              <EditIcon sx={styles.editIcon} />
                            </IconButton>
                          ) : null}
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              {/* <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                {[
                  {
                    title: "Project Status",
                    value: selectedProjectData?.status,
                  },
                ]?.map((item, index) => (
                  <Box
                    sx={{
                      display: "flex",

                      padding: "8px 16px",
                    }}
                  >
                    <Box>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {item?.title}
                      </Typography>
                    </Box>
                    <strong>:</strong>
                    <Box>
                      <Box sx={styles.dataStatus}>
                        <Typography ml="10px">
                          {getStatusName(selectedProjectData?.status)}
                        </Typography>
                        <IconButton
                          sx={{ color: "blue" }}
                          size="small"
                          onClick={() => setOpenStatus(true)}
                        >
                          <EditIcon sx={styles.editIcon} />
                        </IconButton>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box> */}

              <Divider />

              <Paper elevation={3}>
                <Box sx={styles.tabsBox}>
                  <TabContext value={value} variant="scrollable">
                    <Box sx={styles.tabInBox}>
                      <StyledTabs
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                      >
                        {selectedProjectData?.project_zones &&
                          selectedProjectData?.project_zones.length && (
                            <StyledTab
                              label={
                                <Badge
                                  badgeContent={
                                    selectedProjectData?.project_zones
                                      ?.length || "0"
                                  }
                                  color="primary"
                                >
                                  Zones
                                </Badge>
                              }
                              value="2"
                            />
                          )}
                        <StyledTab
                          label={
                            <Badge
                              badgeContent={
                                selectedProjectData?.vehicle_function_details?.filter(
                                  (item) =>
                                    item.id &&
                                    item.name &&
                                    Object.keys(item).length > 0
                                ).length || 0
                              }
                              color="primary"
                              sx={{ alignItems: "center" }}
                            >
                              Vehicle Function
                              <InfoButton
                                infoKey={"vehicle_function"}
                                color={"grey"}
                              />
                            </Badge>
                          }
                          value="1"
                        />
                        <StyledTab
                          label={
                            <Badge
                              badgeContent={
                                selectedProjectData?.powertrain_class_details?.filter(
                                  (item) =>
                                    item.id &&
                                    item.name &&
                                    Object.keys(item)?.length > 0
                                ).length || 0
                              }
                              color="primary"
                              sx={{ alignItems: "center" }}
                            >
                              Power Train Class
                              <InfoButton
                                infoKey={"power_train_class"}
                                color={"grey"}
                              />
                            </Badge>
                          }
                          value="3"
                        />
                        <StyledTab
                          label={
                            <Badge
                              badgeContent={
                                selectedProjectData?.owner_details?.filter(
                                  (item) => Object.keys(item).length > 0
                                ).length || 0
                              }
                              color="primary"
                            >
                              Project Owner
                            </Badge>
                          }
                          value="4"
                        />
                        <StyledTab
                          label={
                            <Badge
                              badgeContent={
                                selectedProjectData?.co_owner_details?.filter(
                                  (item) => Object.keys(item).length > 0
                                ).length || 0
                              }
                              color="primary"
                            >
                              Co-Owners
                            </Badge>
                          }
                          value="5"
                        />
                        <StyledTab
                          label={
                            <Badge
                              badgeContent={
                                selectedProjectData?.edit_user_details?.filter(
                                  (item) => Object.keys(item).length > 0
                                ).length || 0
                              }
                              color="primary"
                            >
                              Editors
                            </Badge>
                          }
                          value="6"
                        />
                        <StyledTab
                          label={
                            <Badge
                              badgeContent={
                                selectedProjectData?.view_user_details?.filter(
                                  (item) => Object.keys(item).length > 0
                                ).length || 0
                              }
                              color="primary"
                            >
                              Viewers
                            </Badge>
                          }
                          value="7"
                        />
                        <StyledTab
                          label={
                            <Badge
                              badgeContent={
                                selectedProjectData?.delivery_market?.filter(
                                  (item) => Object.keys(item).length > 0
                                ).length || 0
                              }
                              color="primary"
                            >
                              Delivery Market
                            </Badge>
                          }
                          value="8"
                        />
                        <StyledTab
                          label={
                            <Badge
                              badgeContent={
                                selectedProjectData?.mft_details?.filter(
                                  (item) => Object.keys(item).length > 0
                                ).length || 0
                              }
                              color="primary"
                            >
                              Manufacturer Configurator
                            </Badge>
                          }
                          value="9"
                        />
                      </StyledTabs>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Tooltip title="Project Logs">
                          <IconButton
                            color="info"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleProjectDialogOpen(selectedProjectData);
                            }}
                          >
                            <HistoryEduIcon fontSize="medium" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                    <TabPanel value="2">
                      {selectedProjectData?.project_zones &&
                      selectedProjectData?.project_zones?.length ? (
                        <Box sx={styles.tab2Box}>
                          {selectedProjectData?.project_zones?.map((data) => {
                            return (
                              <Paper elevation={12} sx={styles.tab2Paper}>
                                <Typography sx={styles.tab2Typography}>
                                  &nbsp;{data?.position}
                                  &nbsp;&nbsp;-&nbsp;&nbsp;
                                  {data?.zone_name}&nbsp;
                                </Typography>
                              </Paper>
                            );
                          })}
                        </Box>
                      ) : (
                        <Typography>No Zones</Typography>
                      )}
                    </TabPanel>
                    <TabPanel value="1">
                      <Box sx={styles.tab1BoxButton}>
                        <Button
                          size="small"
                          startIcon={<AddIcon />}
                          sx={{
                            ...appTheme.commonBtnStyle,
                          }}
                          variant="outlined"
                          onClick={() => setOpenVfDialog(true)}
                        >
                          Add
                        </Button>
                      </Box>
                      {openVfDialog && (
                        <EditVehicleFunction
                          openVfDialog={openVfDialog}
                          setOpenVfDialog={setOpenVfDialog}
                          editVF={editVF}
                          setEditVF={setEditVF}
                          handleClickEdit={handleClickEdit}
                        />
                      )}
                      {selectedProjectData?.vehicle_function_details &&
                      selectedProjectData?.vehicle_function_details?.length ? (
                        <Box sx={styles.tab1Box}>
                          {selectedProjectData?.vehicle_function_details?.map(
                            (data, idx) => {
                              return (
                                <Paper
                                  key={idx}
                                  elevation={12}
                                  sx={styles.tab1Paper}
                                >
                                  <Typography sx={styles.tab1Typo}>
                                    {data?.name} - ({data?.abbreviation})
                                  </Typography>
                                  {data?.other_fields?.description && (
                                    <Typography sx={styles.tab1Typo1}>
                                      {data?.other_fields?.description}
                                    </Typography>
                                  )}
                                </Paper>
                              );
                            }
                          )}{" "}
                        </Box>
                      ) : (
                        <Typography sx={styles.tab1NotFound}>
                          Details not found!
                        </Typography>
                      )}
                      {/* </Box> */}
                    </TabPanel>

                    <TabPanel value="3">
                      <Box sx={styles.tab1BoxButton}>
                        <Button
                          size="small"
                          startIcon={<AddIcon />}
                          sx={{
                            ...appTheme.commonBtnStyle,
                          }}
                          variant="outlined"
                          onClick={() => setOpenPcDialog(true)}
                        >
                          Add
                        </Button>
                        {openPcDialog && (
                          <EditPowertrainClass
                            openPcDialog={openPcDialog}
                            setOpenPcDialog={setOpenPcDialog}
                            editPC={editPC}
                            setEditPC={setEditPC}
                            handleClickEdit={handleClickEdit}
                          />
                        )}
                      </Box>
                      <Box sx={styles.tab3Box}>
                        {selectedProjectData?.powertrain_class_details
                          ?.length ? (
                          selectedProjectData.powertrain_class_details.map(
                            (data, idx) => (
                              <>
                                {data.abbreviation ? (
                                  <Paper
                                    key={idx}
                                    elevation={12}
                                    sx={styles.tab3Paper}
                                  >
                                    <Typography sx={styles.tab3Typo}>
                                      {data.name} - ({data.abbreviation})
                                    </Typography>
                                    {data.other_fields?.description && (
                                      <Typography sx={styles.tab3Typo1}>
                                        {data.other_fields.description}
                                      </Typography>
                                    )}
                                  </Paper>
                                ) : (
                                  <Typography sx={styles.tab1NotFound}>
                                    Details not found!
                                  </Typography>
                                )}
                              </>
                            )
                          )
                        ) : (
                          <Typography sx={styles.tab1NotFound}>
                            Details not found!
                          </Typography>
                        )}
                      </Box>
                    </TabPanel>

                    <TabPanel value="4">
                      {" "}
                      <Box sx={styles.tab4Box}>
                        <Paper elevation={12} sx={styles.tab4Paper}>
                          {[
                            {
                              key: "Name",
                              value:
                                (selectedProjectData?.owner_details &&
                                  selectedProjectData?.owner_details[0]
                                    ?.name) ||
                                "-",
                            },
                            {
                              key: "Email",
                              value:
                                (selectedProjectData?.owner_details &&
                                  selectedProjectData?.owner_details[0]
                                    ?.email) ||
                                "-",
                            },
                          ].map((data) => {
                            return (
                              <Box sx={styles.tab4Box}>
                                <Typography sx={styles.tab4Typo}>
                                  {data.key}
                                </Typography>
                                <strong>:</strong>
                                <Typography sx={styles.tab4Typo1}>
                                  {data.value}
                                </Typography>
                              </Box>
                            );
                          })}
                        </Paper>
                      </Box>
                    </TabPanel>
                    <TabPanel value="5">
                      {selectedProjectData?.co_owner_details &&
                      selectedProjectData.co_owner_details.length &&
                      selectedProjectData.co_owner_details[0]?.role_id ? (
                        <Box sx={styles.tab5Box}>
                          {selectedProjectData.co_owner_details.map(
                            (details, index) => {
                              return (
                                <Paper
                                  key={index}
                                  elevation={12}
                                  sx={styles.tab5Paper}
                                >
                                  {[
                                    {
                                      key: "Name",
                                      value: details.name || "-",
                                    },
                                    {
                                      key: "Email",
                                      value: details.email || "-",
                                    },
                                  ].map((data, idx) => {
                                    return (
                                      <Box key={idx} sx={styles.tab5Box1}>
                                        <Typography sx={styles.tab5Tpyo}>
                                          {data.key}
                                        </Typography>
                                        <strong>:</strong>
                                        <Typography sx={styles.tab5Typo1}>
                                          {data.value}
                                        </Typography>
                                      </Box>
                                    );
                                  })}
                                </Paper>
                              );
                            }
                          )}
                        </Box>
                      ) : (
                        <Typography sx={styles.tab1NotFound}>
                          Details not found!
                        </Typography>
                      )}
                    </TabPanel>
                    <TabPanel value="6">
                      {selectedProjectData?.edit_user_details &&
                      selectedProjectData.edit_user_details.length &&
                      selectedProjectData.edit_user_details[0]?.role_id ? (
                        <Box sx={styles.tab6Box}>
                          {selectedProjectData.edit_user_details.map(
                            (details, index) => {
                              return (
                                <Paper
                                  key={index}
                                  elevation={12}
                                  sx={styles.tab6Paper}
                                >
                                  {[
                                    {
                                      key: "Name",
                                      value: details.name || "-",
                                    },
                                    {
                                      key: "Email",
                                      value: details.email || "-",
                                    },
                                  ].map((data, idx) => {
                                    return (
                                      <Box key={idx} sx={styles.tab6DataBox}>
                                        <Typography sx={styles.tab6Typo}>
                                          {data.key}
                                        </Typography>
                                        <strong>:</strong>
                                        <Typography sx={styles.tab6Typo1}>
                                          {data.value}
                                        </Typography>
                                      </Box>
                                    );
                                  })}
                                </Paper>
                              );
                            }
                          )}
                        </Box>
                      ) : (
                        <Typography sx={styles.tab1NotFound}>
                          Details not found!
                        </Typography>
                      )}
                    </TabPanel>
                    <TabPanel value="7">
                      {" "}
                      {selectedProjectData?.view_user_details &&
                      selectedProjectData.view_user_details.length &&
                      selectedProjectData.view_user_details[0]?.role_id ? (
                        <Box sx={styles.tab7Box}>
                          {selectedProjectData.view_user_details.map(
                            (details, index) => {
                              return (
                                <Paper
                                  key={index}
                                  elevation={12}
                                  sx={styles.tab7Paper}
                                >
                                  {[
                                    {
                                      key: "Name",
                                      value: details.name || "-",
                                    },
                                    {
                                      key: "Email",
                                      value: details.email || "-",
                                    },
                                  ].map((data, idx) => {
                                    return (
                                      <Box key={idx} sx={styles.tab7DataBox}>
                                        <Typography sx={styles.tab7Typo}>
                                          {data.key}
                                        </Typography>
                                        <strong>:</strong>
                                        <Typography sx={styles.tab7Typo1}>
                                          {data.value}
                                        </Typography>
                                      </Box>
                                    );
                                  })}
                                </Paper>
                              );
                            }
                          )}
                        </Box>
                      ) : (
                        <Typography sx={styles.tab1NotFound}>
                          Details not found!
                        </Typography>
                      )}
                    </TabPanel>
                    <TabPanel value="8">
                      <Box sx={styles.tab1BoxButton}>
                        <Button
                          size="small"
                          startIcon={<AddIcon />}
                          sx={{
                            ...appTheme.commonBtnStyle,
                          }}
                          variant="outlined"
                          onClick={() => setOpenDeliveryMarket(true)}
                        >
                          Add
                        </Button>
                      </Box>
                      {openDeliveryMarket && (
                        <UpdateDeliveryMarket
                          openDeliveryMarket={openDeliveryMarket}
                          setOpenDeliveryMarket={setOpenDeliveryMarket}
                          boxes={selectedProjectData}
                        />
                      )}
                      <Box sx={styles.tab3Box}>
                        {selectedProjectData?.delivery_market?.length ? (
                          selectedProjectData?.delivery_market?.map(
                            (data, idx) => (
                              <>
                                {data ? (
                                  <Paper
                                    key={idx}
                                    elevation={12}
                                    sx={styles.tab3Paper}
                                  >
                                    <Typography sx={styles.tab3Typo}>
                                      {data}
                                    </Typography>
                                  </Paper>
                                ) : (
                                  <Typography sx={styles.tab1NotFound}>
                                    Details not found!
                                  </Typography>
                                )}
                              </>
                            )
                          )
                        ) : (
                          <Typography sx={styles.tab1NotFound}>
                            Details not found!
                          </Typography>
                        )}
                      </Box>
                    </TabPanel>
                    <TabPanel value="9">
                      <Box sx={styles.tab1BoxButton}>
                        <Button
                          size="small"
                          startIcon={<AddIcon />}
                          sx={{
                            ...appTheme.commonBtnStyle,
                          }}
                          variant="outlined"
                          onClick={() => setOpenMftDialog(true)}
                        >
                          Add
                        </Button>
                      </Box>
                      {openMftDialog && (
                        <AddMftDetails
                          handleClickEdit={handleClickEdit}
                          selectMft={selectMft}
                          setSelectMft={setSelectMft}
                          openMftDialog={openMftDialog}
                          selectedProjectData={selectedProjectData}
                          setOpenMftDialog={setOpenMftDialog}
                        />
                      )}
                      <Box sx={styles.tab3Box}>
                        {selectedProjectData?.mft_details?.length ? (
                          selectedProjectData?.mft_details?.map((data, idx) => (
                            <>
                              {data ? (
                                <>
                                  <Paper
                                    key={idx}
                                    elevation={12}
                                    sx={styles.tab3Paper}
                                  >
                                    <Box>
                                      <Typography sx={styles.tab3Typo}>
                                        Name<strong>:</strong>
                                        {data?.name}
                                      </Typography>
                                      <Typography sx={styles.tab3Typo}>
                                        Address<strong>:</strong>
                                        {data?.address}
                                      </Typography>
                                    </Box>
                                  </Paper>
                                </>
                              ) : (
                                <Typography sx={styles.tab1NotFound}>
                                  Details not found!
                                </Typography>
                              )}
                            </>
                          ))
                        ) : (
                          <Typography sx={styles.tab1NotFound}>
                            Details not found!
                          </Typography>
                        )}
                      </Box>
                    </TabPanel>
                  </TabContext>
                </Box>
              </Paper>
            </Box>
          </List>
        </Collapse>
      </List>
      <CustomLogsDialog
        open={openProjectChangeLogsDialog}
        handleClose={handleProjectDialogClose}
        dialogTitle={"Change Logs"}
        dialogContent={projectChangeLogsList}
        data={projectLogsDialogData}
        getLogsData={handleGetProjectChangeLogs}
      />

      {openStatus && (
        <UpdateStatus
          openStatus={openStatus}
          setOpenStatus={setOpenStatus}
          boxes={selectedProjectData}
        />
      )}

      {openDialog && (
        <CustomUpdateDialog
          onDisabled={
            editTradeMark === selectedProjectData?.trade_mark ? true : false
          }
          open={openDialog}
          onClose={() => {
            setOpenDialog(false);
          }}
          dialogTitle={"Edit Project"}
          dialogContent={
            <Stack spacing={2}>
              <CustomTextFieldSP
                size={"small"}
                label={"Trademark"}
                placeholder={"Trademark"}
                value={editTradeMark}
                setValue={setEditTradeMark}
                txtFldStyle={{
                  width: "400px",
                }}
                validationRules={{ minLength: 3 }}
                setError={setTradeMarkError}
                error={trademarkError}
              />
            </Stack>
          }
          onConfirm={handleClickEdit}
        />
      )}
    </div>
  );
}

export default ProjectDetails;

const styles = {
  list: {
    width: "100%",
    maxWidth: "100%",
    marginTop: "15px",
    backgroundColor: "whitesmoke",
    boxShadow: "10px 10px 30px rgba(0, 0, 0, 0.2)",
    borderRadius: "0px",
  },
  listItemText: {
    textAlign: "start",
    color: "black",
  },
  typographyProps: {
    fontWeight: 600,
    fontSize: "18px",
  },
  divider: {
    margin: "10px",
    height: "2px",
  },
  listMain: {
    backgroundColor: "whitesmoke",
    margin: "10px",
    padding: "10px",
    borderRadius: "10px",
    overflowY: "auto",
    maxHeight: "500px",
  },
  boxMain: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "5px",
  },
  architectureStyle: {
    display: "flex",
    flexWrap: "wrap",
    gap: 2,
    marginTop: "10px",
    alignItems: "center",
    justifyContent: "center",
  },
  architectureBox: {
    display: "flex",
    flexDirection: "row",
    borderRadius: "0px",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    fontWeight: "bold",
  },
  close: {
    color: "white",
    marginLeft: "10px",
    "&:hover": { color: "white" },
  },
  notFound: {
    color: "red",
    fontSize: "12px",
    fontWeight: "bold",
  },
  tblFun: {
    mb: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  tabsBox: { width: "100%", mt: "20px", borderRadius: "10px" },
  tabInBox: {
    borderBottom: 1,
    borderColor: "divider",
    display: "flex",
    justifyContent: "space-between",
  },
  tab2Box: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "10px",
  },
  tab2Paper: { height: "30px", padding: "5px" },
  tab2Typography: { fontWeight: "bold", fontSize: "14px" },
  tab1BoxButton: {
    display: "flex",
    justifyContent: "flex-end",
  },
  tab1Box: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "10px",
    marginTop: "10px",
  },
  tab1Paper: { padding: "10px", borderRadius: "10px" },
  tab1Typo: { fontSize: "14px", fontWeight: "bold" },
  tab1Typo1: { fontSize: "12px", fontWeight: "bold" },
  tab1NotFound: { fontWeight: "bold", fontSize: "12px", color: "red" },
  tab3Box: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "10px",
  },
  tab3Paper: { padding: "10px", borderRadius: "10px" },
  tab3Typo: { fontSize: "14px", fontWeight: "bold" },
  tab3Typo1: { fontSize: "12px", fontWeight: "bold" },
  tab4Box: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "10px",
  },
  tab4Paper: { padding: "10px", borderRadius: "10px" },
  tab4Box: {
    display: "flex",
    flexDirection: "row",
    gap: 2,
    alignItems: "center",
  },
  imageBox: { width: 72, height: 72 },
  tab4Typo: {
    fontWeight: "bold",
    fontSize: "14px",
    textAlign: "left",
    minWidth: "100px",
    color: "gray",
  },
  tab4Typo1: { fontSize: "14px", fontWeight: "bold" },
  tab5Box: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "10px",
  },
  tab5Paper: { padding: "10px", borderRadius: "10px" },
  tab5Box1: {
    display: "flex",
    flexDirection: "row",
    gap: 2,
    alignItems: "center",
  },
  tab5Tpyo: {
    fontWeight: "bold",
    fontSize: "14px",
    textAlign: "left",
    minWidth: "100px",
    color: "gray",
  },
  tab5Typo1: { fontSize: "14px", fontWeight: "bold" },
  tab6Box: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "10px",
  },
  tab6Paper: { padding: "10px", borderRadius: "10px" },
  tab6DataBox: {
    display: "flex",
    flexDirection: "row",
    gap: 2,
    alignItems: "center",
  },
  tab6Typo: {
    fontWeight: "bold",
    fontSize: "14px",
    textAlign: "left",
    minWidth: "100px",
    color: "gray",
  },
  tab6Typo1: { fontSize: "14px", fontWeight: "bold" },
  tab7Box: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "10px",
  },
  tab7Paper: { padding: "10px", borderRadius: "10px" },
  tab7DataBox: {
    display: "flex",
    flexDirection: "row",
    gap: 2,
    alignItems: "center",
  },
  tab7Typo: {
    fontWeight: "bold",
    fontSize: "14px",
    textAlign: "left",
    minWidth: "100px",
    color: "gray",
  },
  tab7Typo1: { fontSize: "14px", fontWeight: "bold" },
  dataStatus: {
    display: "flex",
    flexDirection: "row",

    marginBottom: "10px",
    position: "relative",
  },
  vehicleImageBox: {
    display: "flex",

    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
};

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "1rem",
    marginRight: theme.spacing(1),

    color: "green",
    "&.Mui-selected": {
      color: "black",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 60,
    width: "100%",
    backgroundColor: "#635ee7",
  },
});
