/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 15-11-2024
 * @description : Component to show the project & variant details
 */
import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import helper from "../../utils/helper";
import { shallowEqual, useSelector } from "react-redux";

function ProjectVariantDetails({ projectDetails, variantDetails }) {
  const { project_status } = useSelector(
    (state) => state?.settingsReducer?.metaData,
    shallowEqual
  );

  return (
    <Grid container>
      <Grid xs={6}>
        <Paper elevation={6} sx={styles.prjDetailsContainer}>
          <Typography sx={{ fontSize: "16px", fontWeight: "bold", pt: 0.4 }}>
            Project Configurations
          </Typography>
          <Divider sx={{ mt: 0.4, mb: 1 }} />
          <Grid container>
            {/* Name Section */}
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mb: 1,
                }}
              >
                <Typography variant="subtitle2" sx={styles.prjDetailsTxt}>
                  <span style={styles.txtBold}>Name: </span>
                </Typography>
                <Typography sx={{ textAlign: "start" }} variant="subtitle2">
                  {projectDetails?.name || "-"}
                </Typography>
              </Box>
            </Grid>

            {/* Architecture Section */}
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mb: 1,
                }}
              >
                <Typography variant="subtitle2" sx={styles.prjDetailsTxt}>
                  <span style={styles.txtBold}>Architecture: </span>
                </Typography>
                <Typography sx={{ textAlign: "start" }} variant="subtitle2">
                  {projectDetails?.architecture_name || "-"}
                </Typography>
              </Box>
            </Grid>

            {/* Trade Mark Section */}
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mb: 1,
                }}
              >
                <Typography variant="subtitle2" sx={styles.prjDetailsTxt}>
                  <span style={styles.txtBold}>Trade Mark: </span>
                </Typography>
                <Typography sx={{ textAlign: "start" }} variant="subtitle2">
                  {projectDetails?.trade_mark || "-"}
                </Typography>
              </Box>
            </Grid>

            {/* Vehicle Platform Section */}
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mb: 1,
                }}
              >
                <Typography variant="subtitle2" sx={styles.prjDetailsTxt}>
                  <span style={styles.txtBold}>Vehicle Platform: </span>
                </Typography>
                <Typography sx={{ textAlign: "start" }} variant="subtitle2">
                  {projectDetails?.vehicle_platform || "-"}
                </Typography>
              </Box>
            </Grid>

            {/* Zones Section */}
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mb: 1,
                }}
              >
                <Typography variant="subtitle2" sx={styles.prjDetailsTxt}>
                  <span style={styles.txtBold}>Zones: </span>
                </Typography>
                <Typography sx={{ textAlign: "start" }} variant="subtitle2">
                  {projectDetails?.is_zonal
                    ? projectDetails?.project_zones?.length
                    : "-"}
                </Typography>
              </Box>
            </Grid>

            {/* Project Status */}
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mb: 1,
                }}
              >
                <Typography variant="subtitle2" sx={styles.prjDetailsTxt}>
                  <span style={styles.txtBold}>Status: </span>
                </Typography>
                <Typography sx={{ textAlign: "start" }} variant="subtitle2">
                  {helper?.getObjectKeyByValue(
                    project_status,
                    projectDetails?.status
                  ) || "-"}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid xs={6}>
        <Paper
          elevation={6}
          sx={styles.prjDetailsContainer}
          key={variantDetails?.variant_id}
        >
          <Typography sx={{ fontSize: "16px", fontWeight: "bold", pt: 0.4 }}>
            Variant Configurations
          </Typography>
          <Divider sx={{ mt: 0.4, mb: 1 }} />
          <Grid container>
            {/* Name Section */}
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mb: 1,
                }}
              >
                <Typography variant="subtitle2" sx={styles.prjDetailsTxt}>
                  <span style={styles.txtBold}>Name: </span>
                </Typography>
                <Typography sx={{ textAlign: "start" }} variant="subtitle2">
                  {variantDetails?.name || "-"}
                </Typography>
              </Box>
            </Grid>

            {/* Power Train Class Section */}
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mb: 1,
                }}
              >
                <Typography variant="subtitle2" sx={styles.prjDetailsTxt}>
                  <span style={styles.txtBold}>Power Train Class: </span>
                </Typography>
                <Typography sx={{ textAlign: "start" }} variant="subtitle2">
                  {variantDetails?.powertrain_class_details?.[0]?.name || "-"}
                </Typography>
              </Box>
            </Grid>

            {/* Vehicle Status Section */}
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mb: 1,
                }}
              >
                <Typography variant="subtitle2" sx={styles.prjDetailsTxt}>
                  <span style={styles.txtBold}>Vehicle Status: </span>
                </Typography>
                <Typography sx={{ textAlign: "start" }} variant="subtitle2">
                  {variantDetails?.vehicle_status || "-"}
                </Typography>
              </Box>
            </Grid>

            {/* Delivery Market Section */}
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mb: 1,
                }}
              >
                <Typography variant="subtitle2" sx={styles.prjDetailsTxt}>
                  <span style={styles.txtBold}>Delivery Market: </span>
                </Typography>
                <Typography sx={{ textAlign: "start" }} variant="subtitle2">
                  {variantDetails?.delivery_market || "-"}
                </Typography>
              </Box>
            </Grid>

            {/* Vehicle Components Section */}
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mb: 1,
                }}
              >
                <Typography variant="subtitle2" sx={styles.prjDetailsTxt}>
                  <span style={styles.txtBold}>Vehicle Components: </span>
                </Typography>
                <Typography sx={{ textAlign: "start" }} variant="subtitle2">
                  {variantDetails?.vehicle_components?.length || "-"}
                </Typography>
              </Box>
            </Grid>

            {/* EE Components Section */}
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mb: 1,
                }}
              >
                <Typography variant="subtitle2" sx={styles.prjDetailsTxt}>
                  <span style={styles.txtBold}>EE Components: </span>
                </Typography>
                <Typography sx={{ textAlign: "start" }} variant="subtitle2">
                  {variantDetails?.ee_ids?.length || "-"}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default ProjectVariantDetails;

const styles = {
  prjDetailsContainer: {
    px: 2,
    // py: 1,
    mx: 1,
    height: "100%",
  },
  prjDetailsTxt: {
    textAlign: "start",
    alignContent: "center",
  },
  txtBold: {
    fontWeight: "bold",
  },
};
