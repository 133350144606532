/**
 * @author Abdul Rahim M - abdul@au79consulting.com
 * @date 02-07-2024
 * @description Component for handling Zone Details.
 */
import {
  Box,
  Button,
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { HandleApiActions } from "../../redux/actions/actionHandler";
import settingsApis from "../../redux/actions/settings";
import customConsole from "../../config/customConsole";
import CustomButton from "../../components/buttons/CustomButton";
import AddIcon from "@mui/icons-material/Add";
import {
  CustomAddDialog,
  CustomTextFieldN,
  SelectTextField,
} from "../../components";
import { adminSettingsAPI } from "../../redux/actions";
import { customAppColors } from "../../assets/app-theme/appColors";

function ZoneDetails({ open, handleClick, zoneDetails }) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [ZoneName, setZoneName] = useState("");
  const [selectedPosition, setSelectedPosition] = useState("");
  //1.Redux
  const dispatch = useDispatch();
  const appMetaZoneDetails = useSelector(
    (state) => state?.settingsReducer,
    shallowEqual
  );

  //2.React useState

  const [zonePositions, setZonePositions] = useState([]); // State to manage add zone error message

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };
  const handleDialogClose = () => {
    setIsDialogOpen(false);

    setZoneName("");
    setSelectedPosition("");
  };
  const isFormValid = () => {
    return ZoneName?.length > 0 && selectedPosition;
  };

  const handleAddZoneClick = () => {
    let currentTime = new Date().toISOString();

    dispatch(
      HandleApiActions({
        ...adminSettingsAPI.add_zone_details,
        params: {
          position_name: ZoneName,
          zone_position: selectedPosition,
          current_time: currentTime,
        },
        show_toast: true,
      })
    ).then(() => {
      dispatch(
        HandleApiActions({
          ...adminSettingsAPI.get_admin_settings,
          show_toast: false,
        })
      );
    });

    handleDialogClose();
  };

  //3.React useEffect
  React.useEffect(() => {
    dispatch(
      HandleApiActions({
        ...settingsApis.get_app_meta_data,
        show_toast: false,
      })
    );
  }, []);

  React.useEffect(() => {
    customConsole("zoneDetails: ", zoneDetails);
    customConsole("appMetaZoneDetails");
    customConsole(appMetaZoneDetails?.metaData?.zone_positions);
    if (
      appMetaZoneDetails &&
      appMetaZoneDetails.metaData &&
      appMetaZoneDetails.metaData &&
      appMetaZoneDetails.metaData.zone_positions
    ) {
      let availableZones = appMetaZoneDetails?.metaData?.zone_positions;
      if (zoneDetails && zoneDetails.length) {
        const existingZones = zoneDetails?.map((data) => data.zone_position);

        availableZones = appMetaZoneDetails?.metaData?.zone_positions.filter(
          (data) => !existingZones.includes(data)
        );
      }
      customConsole("availableZones: ", availableZones);
      setZonePositions(availableZones);
    }
  }, [appMetaZoneDetails, zoneDetails]);

  return (
    <div>
      <List
        sx={styled.list}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <ListItemButton onClick={() => handleClick("2")}>
          <ListItemText
            sx={styled.listText}
            primary="Zone Details"
            primaryTypographyProps={styled.typographyProps}
          />
          {open === "2" ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={open === "2"} timeout="auto" unmountOnExit>
          <Divider sx={{ margin: "10px" }} />
          <List component="div" disablePadding sx={styled.mainList}>
            <Box sx={styled.tblFun}>
              <CustomButton
                size={"medium "}
                variant={"contained"}
                btnName={"Add "}
                startIcon={<AddIcon />}
                handleOnClick={handleDialogOpen}
                btnStyle={{
                  ml: 2,
                }}
              />
            </Box>
            <Box sx={styled.zoneBox}>
              {zoneDetails && zoneDetails.length ? (
                zoneDetails?.map((data, index) => {
                  return (
                    <Box key={index} sx={styled.subZoneBox}>
                      <Typography padding="7px">
                        {data.position_name}
                      </Typography>
                    </Box>
                  );
                })
              ) : (
                <Typography sx={styled.notFound}>
                  Zone details not found
                </Typography>
              )}
            </Box>
          </List>
        </Collapse>
      </List>

      <CustomAddDialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        dialogTitle={"Add Zone"}
        dialogContent={
          <Stack spacing={4} mb={2} width="220px">
            <CustomTextFieldN
              size={"small"}
              label={"Zone Name*"}
              placeholder={"Zone Name"}
              type={"text"}
              value={ZoneName}
              setValue={setZoneName}
              variant={"standard"}
            />
            <SelectTextField
              size={"small"}
              label={"Select Zone Position*"}
              optionsList={zonePositions?.map((el) => {
                return { value: el, label: el };
              })}
              selectedValue={selectedPosition}
              setSelectedValue={setSelectedPosition}
              variant={"standard"}
            />
          </Stack>
        }
        onConfirm={handleAddZoneClick}
        onDisabled={!isFormValid()}
      />
    </div>
  );
}

export default ZoneDetails;

const styled = {
  list: {
    width: "100%",
    marginTop: "15px",
    backgroundColor: "whitesmoke",
    boxShadow: "10px 10px 30px rgba(0, 0, 0, 0.2)",
    borderRadius: "0px",
    maxWidth: "100%",
  },
  listText: {
    textAlign: "start",

    color: "black",
  },
  typographyProps: {
    fontWeight: 600,
    fontSize: "18px",
  },
  mainList: {
    background: "whitesmoke",
    margin: "10px",
    padding: "10px",
    borderRadius: "10px",
    overflowY: "auto",
    maxHeight: "500px",
  },
  mainBox: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px",
  },
  zoneBox: {
    display: "flex",
    flexWrap: "wrap",
    gap: 2,
    alignItems: "center",
    justifyContent: "center",
  },
  subZoneBox: {
    display: "flex",
    flexDirection: "row",
    borderRadius: "0px",
    backgroundColor: customAppColors?.green,
    alignItems: "center",
    justifyContent: "center",

    color: "white",
    fontWeight: "bold",
  },
  notFound: {
    color: "red",
    fontSize: "12px",
    fontWeight: "bold",
  },
  tblFun: {
    mb: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
};
