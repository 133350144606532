import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDispatch, useSelector } from "react-redux";
import { HandleApiActions } from "../../../redux/actions";
import dynamicTemplateAction from "../../../redux/actions/dynamicTemplateAction";
import DynamicFormTemplate from "./DynamicFormTemplate";
import ViewDynammicFormDetails from "./ViewDynammicFormDetails";
import EditIcon from "@mui/icons-material/Edit";
import DynamicFormDialog from "./DynamicFormDialog";
import UpdateDynamicForm from "./UpdateDynamicForm";

import DraftsIcon from "@mui/icons-material/Drafts";
import SaveIcon from "@mui/icons-material/Save";
import { CustomPagination } from "../../../components";

function ViewDynammicForm() {
  const dispatch = useDispatch();
  const getFormDetails = useSelector((state) => state.dynamicTemplateReducer);

  // States
  const [singleFormDetails, setSingleFormDetails] = useState({});
  const [updateFormDetails, setUpdateFormDetails] = useState({});

  const [open, setOpen] = useState(false);
  const [updateForm, setUpdateForm] = useState(false);

  // Pagination
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [hasMore, setHasMore] = useState(false);

  // Handle page change
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Handle page size change
  const handlePageSizeChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  // Close the modals
  const handleClose = (index) => {
    if (index === 1) {
      setOpen(false);
    } else if (index === 2) {
      setUpdateForm(false);
    }
  };

  // Open View modal
  const handleOpen = (dataId) => {
    const singleFormDetails = getFormDetails?.dynamicTemplate?.find(
      (item) => item?.id === dataId
    );
    setSingleFormDetails(singleFormDetails);
    setOpen(true);
  };

  // Open Update modal
  const handleOpenUpdate = (dataId) => {
    const updateFormDetails = getFormDetails?.dynamicTemplate?.find(
      (item) => item?.id === dataId
    );
    setUpdateFormDetails(updateFormDetails);
    setUpdateForm(true);
  };

  // useEffect for dispatches
  useEffect(() => {
    dispatch(
      HandleApiActions({
        ...dynamicTemplateAction.get_d_template,
        params: {
          page_number: page,
          page_size: rowsPerPage,
        },
      })
    );
  }, [open, updateForm, page, rowsPerPage, dispatch]);

  // Check if more items are available for pagination
  useEffect(() => {
    if (getFormDetails?.dynamicTemplate?.length < rowsPerPage) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  }, [getFormDetails?.dynamicTemplate, rowsPerPage]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          gap: 20,
          flexWrap: "wrap",
          minHeight: "400px",
          maxHeight: "300px",
          overflowY: "auto",
          scrollbarWidth: "thin", // For Firefox (controls width)
          scrollbarColor: "#888 #f1f1f1", // For Firefox (thumb color, track color)
        }}
        className="custom-scroll"
      >
        {getFormDetails?.dynamicTemplate?.map((data, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "column",
              mt: "10px",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <Box>
              <Grid container>
                <Grid item xs={8}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      mt: "10px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "calc(100% - 30px)",
                      display: "inline-block",
                    }}
                    title={data?.name}
                  >
                    {data?.name?.length > 15
                      ? `${data?.name?.slice(0, 15)}...`
                      : data?.name}
                  </Typography>
                </Grid>

                {/* IconButton (Edit icon) */}
                <Grid item xs={4}>
                  <IconButton onClick={() => handleOpenUpdate(data?.id)}>
                    <EditIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>

            {/* Card with Visibility Icon */}
            <Box
              sx={{
                ml: "15px",
                mt: "5px",
                width: "250px",
                height: "300px",
                boxShadow: 3,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                backgroundColor: "#ffffff", // White background
                borderRadius: "10px", // Rounded corners
                border: "30px solid #ffffff", // White border around
                paddingTop: "30px",
                overflow: "hidden", // Prevent content from spilling out
                backgroundImage:
                  "linear-gradient(0deg, #f5f5f5 25%, #e0e0e0 25%, #e0e0e0 50%, #f5f5f5 50%, #f5f5f5 75%, #e0e0e0 75%, #e0e0e0 100%)", // Light grey gradient stripes
                backgroundSize: "50px 50px", // Stripe size
              }}
            >
              {data?.status === 100 && (
                <DraftsIcon
                  sx={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                  }}
                />
              )}
              {/* Centered Visibility Icon */}
              <IconButton onClick={() => handleOpen(data?.id)}>
                <VisibilityIcon />
              </IconButton>
            </Box>
          </Box>
        ))}
        {/* View Dynamic Form Details */}
        <ViewDynammicFormDetails
          data={singleFormDetails}
          open={open}
          handleClose={() => handleClose(1)}
        />

        {/* Update Dynamic Form Template */}
        <UpdateDynamicForm
          data={updateFormDetails}
          open={updateForm}
          handleClose={() => handleClose(2)}
        />
      </div>

      <style>
        {`
          .custom-scroll::-webkit-scrollbar {
            width: 10px; /* Adjust the width of the scrollbar */
          }

          .custom-scroll::-webkit-scrollbar-thumb {
            background-color: #888; /* Change the color of the scrollbar thumb */
            border-radius: 5px;
          }

          .custom-scroll::-webkit-scrollbar-track {
            background-color: #f1f1f1; /* Color of the scrollbar track */
          }
        `}
      </style>

      {/* Pagination */}
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <CustomPagination
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          hasMore={hasMore}
        />
      </Box>
    </>
  );
}

export default ViewDynammicForm;
