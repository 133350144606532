import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
function MultiSelectFieldVariants({
  sltFldStyle,
  optionsList,
  label,
  size,
  selectedValues,
  setSelectedValues,
  disabled,
}) {
  // const handleChipClick = (value) => {
  //   setSelectedValues((prevSelected) => {
  //     if (prevSelected.includes(value)) {
  //       return prevSelected.filter((selectedValue) => selectedValue !== value);
  //     } else {
  //       return [...prevSelected, value];
  //     }
  //   });
  // };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedValues(
      typeof value === "string" ? value?.split(",")?.map(Number) : value
    );
  };
  console.log("selectedValuesselectedValuesselectedValues", selectedValues);
  return (
    <div>
      <FormControl
        disabled={disabled}
        variant="standard"
        size={size}
        sx={sltFldStyle}
      >
        <InputLabel>{label}</InputLabel>
        <Select
          multiple
          value={selectedValues}
          onChange={handleChange}
          input={<Input />}
          renderValue={(selected) =>
            selected
              ?.map(
                (value) =>
                  optionsList?.find(
                    (option) => parseInt(option?.value) === parseInt(value)
                  )?.label
              )
              ?.join(", ")
          }
          MenuProps={CustomMenuProps}
        >
          {optionsList?.map((data, idx) => (
            <MenuItem
              key={idx}
              value={parseInt(data?.value)}
              sx={{
                whiteSpace: "normal",
                wordBreak: "break-word",
                lineHeight: 1.5,
              }}
            >
              <Checkbox
                sx={{ color: data?.color }}
                checked={
                  (selectedValues || [])?.indexOf(parseInt(data?.value)) > -1
                }
              />
              <ListItemText
                sx={{ color: data?.color }}
                primary={`${data?.label}`}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {/* <Box>
        <Box>
          <Typography sx={{ color: "grey" }}>Select Variants</Typography>
        </Box>
        <Stack sx={{ width: "270px" }} direction="row" flexWrap="wrap">
          {optionsList?.map((data) => (
            <Chip
              key={data?.value}
              label={data?.label}
              onClick={() => handleChipClick(data?.value)}
              icon={
                selectedValues?.includes(data?.value) ? (
                  <CheckCircleIcon
                    color={
                      selectedValues.includes(data?.value)
                        ? "transparent"
                        : data?.color
                    }
                  />
                ) : null
              }
              size="medium"
              sx={{
                fontWeight: "bold",
                color: "black",
                // color: selectedValues.includes(data?.value) ? "black" : "white",
                backgroundColor: data?.color,
                border: selectedValues.includes(data?.value)
                  ? "none"
                  : `1px solid ${data?.color}`,
                mt: "10px",
                ml: "2px",
              }}
            />
          ))}
        </Stack>
      </Box> */}
    </div>
  );
}

export default MultiSelectFieldVariants;

const CustomMenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 220,
      overflowX: "auto", // Allow horizontal scrolling
      // whiteSpace: "nowrap", // Prevent text from wrapping to the next line
      overflowX: "hidden", // Hide horizontal overflow
      scrollbarWidth: "thin", // Firefox
      msOverflowStyle: "none", // IE and Edge
      "&::-webkit-scrollbar": {
        height: "8px", // Adjust scrollbar height
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#888", // Scrollbar thumb color
        borderRadius: "4px", // Scrollbar thumb rounded corners
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#f1f1f1", // Scrollbar track color
      },
    },
  },
};
