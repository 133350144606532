/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 12-08-2024
 * @description : Reusable filter component
 */

import React, { useEffect, useState } from "react";
import { Box, Menu, Typography, Divider } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { CustomButton, SelectTextField, CustomTextFieldN } from "../index";

function CustomFiltersMulti({
  selectedTicketsType,
  anchorEl,
  open,
  handleClose,
  mainOptionsList,
  selectedMainOption,
  selectedMainOptionValue,
  handleFilterClick,
  onReset,
  onApplyNow,
}) {
  // Main select field
  const [selectedMainField, setSelectedMainField] = useState(
    selectedMainOption || ""
  );

  // Main select field value
  const [selectedMainFieldValue, setSelectedMainFieldValue] = useState(
    selectedMainOptionValue || ""
  );

  // Search/Select field name
  const [searchSelectFieldName, setSearchSelectFieldName] = useState("");

  // Field type(Search/Select)
  const [fieldType, setFieldType] = useState("");

  // Options List
  const [optionsList, setOptionsList] = useState([]);

  // UseEffects
  useEffect(() => {
    // By default select the main option
    setSelectedMainField(selectedMainOption || "");

    // By default select the main option value
    setSelectedMainFieldValue(selectedMainOptionValue || "");
  }, [selectedMainOption, selectedMainOptionValue]);

  useEffect(() => {
    if (selectedMainField == selectedMainOption) {
      setSelectedMainFieldValue(selectedMainOptionValue || "");
    } else {
      setSelectedMainFieldValue("");
    }
  }, [selectedMainField]);

  useEffect(() => {
    if (selectedMainField) {
      let filterSelectedMainOption = mainOptionsList?.find(
        (el) => el?.value === selectedMainField
      );
      if (filterSelectedMainOption) {
        setSearchSelectFieldName(
          filterSelectedMainOption?.label?.toLowerCase() || ""
        );
        setFieldType(filterSelectedMainOption?.type || "search");
        setOptionsList(filterSelectedMainOption?.value_options_list || []);
      }
    }
  }, [selectedMainField, mainOptionsList]);

  const renderInputField = () => {
    if (fieldType === "select") {
      return (
        <SelectTextField
          size="small"
          label={`Select ${searchSelectFieldName}`}
          optionsList={optionsList}
          selectedValue={selectedMainFieldValue}
          setSelectedValue={setSelectedMainFieldValue}
          variant="standard"
          sltFldStyle={styles.selectTextField}
        />
      );
    }

    // Search text field by default
    return (
      <CustomTextFieldN
        size={"small"}
        label={`Search ${searchSelectFieldName}`}
        placeholder={`Search ${searchSelectFieldName}`}
        type={"text"}
        value={selectedMainFieldValue}
        setValue={setSelectedMainFieldValue}
        variant={"standard"}
        txtFldStyle={styles.customTextFieldN}
      />
    );
  };

  return (
    <Box sx={styles.mainContainer}>
      <CustomButton
        size="small"
        variant="contained"
        btnName="Filter"
        handleOnClick={handleFilterClick}
        btnStyle={styles.customButton}
        startIcon={<FilterAltIcon />}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 12,
          sx: {
            mt: 0.4,
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Typography sx={{ ...styles.heading, ml: "10px" }}>Filter</Typography>
        <Divider />
        {/* Filter fields */}
        <Box sx={styles.container}>
          <Box>
            <SelectTextField
              size="small"
              label="Select option"
              optionsList={mainOptionsList}
              selectedValue={selectedMainField}
              setSelectedValue={setSelectedMainField}
              variant="standard"
              sltFldStyle={styles.selectTextField}
            />
            {selectedTicketsType === 1 ? (
              <Box>
                {/* Render the appropriate input field based on the type */}
                {renderInputField()}
              </Box>
            ) : null}

            <Box>
              {/* Render the appropriate input field based on the type */}
              {renderInputField()}
            </Box>
          </Box>

          {/* reset & apply now buttons */}
          <Box sx={styles.btBtnsContainer}>
            <CustomButton
              size="small"
              variant="contained"
              btnName="Reset All"
              handleOnClick={onReset}
            />
            <CustomButton
              size="small"
              variant="contained"
              btnName="Apply Now"
              disabled={!selectedMainFieldValue}
              handleOnClick={() =>
                onApplyNow(selectedMainField, selectedMainFieldValue)
              }
            />
          </Box>
        </Box>
      </Menu>
    </Box>
  );
}

export default CustomFiltersMulti;

// Styling
const styles = {
  mainContainer: {
    // mr: "10px ",
  },
  container: {
    px: 1.4,
  },
  btBtnsContainer: {
    display: "flex",
    justifyContent: "space-between",
    mt: 3,
    mb: 0.4,
  },
  selectTextField: {
    width: "250px",
    mt: 1,
  },
  customTextFieldN: {
    width: "250px",
    mt: 1,
  },
  heading: {
    fontWeight: "bold",
  },
};
