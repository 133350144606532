/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 07-02-2025
 * @description : Custom export button for importing from JSON or Excel.
 */

import { Box, Menu, MenuItem } from "@mui/material";
import * as XLSX from "xlsx";
import React, { useState } from "react";
import CustomButton from "./CustomButton";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 07-02-2025
 * @description : Custom import button for importing from JSON or Excel.
 * @param {}
 * @return : The rendered export button
 */

function CustomExportButton({ fileName, exportDataExcel, exportDataJson }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Handles exporting data as an Excel file with multiple sheets.
  const handleExportExcel = () => {
    handleClose();

    if (!exportDataExcel || Object.keys(exportDataExcel).length === 0) return;

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Loop through object keys and create sheets dynamically
    Object.entries(exportDataExcel).forEach(([sheetName, sheetData]) => {
      if (Array.isArray(sheetData) && sheetData.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(sheetData);
        XLSX.utils.book_append_sheet(wb, worksheet, sheetName);
      }
    });

    // Generate and download Excel file
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };

  // Handles exporting data as a JSON file.
  const handleExportJson = () => {
    handleClose();
    if (!exportDataJson || Object.keys(exportDataJson).length === 0) return;

    const jsonData = JSON.stringify(exportDataJson, null, 2); // Pretty-print JSON
    const blob = new Blob([jsonData], { type: "application/json" });
    const link = document.createElement("a");

    link.href = URL.createObjectURL(blob);
    link.download = `${fileName}.json`; // Save file with ".json" extension
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box>
      <CustomButton
        size={"small"}
        variant={"contained"}
        btnName={"Export"}
        startIcon={<FileDownloadIcon />}
        handleOnClick={(e) => setAnchorEl(e.currentTarget)}
        btnStyle={{
          ml: 2,
        }}
      />
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => handleExportJson("json")}>
          Export JSON
        </MenuItem>
        <MenuItem onClick={() => handleExportExcel()}>Export Excel</MenuItem>
      </Menu>
    </Box>
  );
}

export default CustomExportButton;
