/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 22-07-2024
 * @description : Custom Replace Dialog Component
 */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { TbReplace } from "react-icons/tb";
import appTheme from "../../assets/AppTheme/appTheme";
import DraftsIcon from "@mui/icons-material/Drafts";
const CustomReplaceDialog = ({
  open,
  onClose,
  dialogTitle,
  dialogContent,
  onConfirm,
  onDisabled,
  buttonTitle,
  onConfirmDraft,
  onConfirmAgainDraft,
  onDisabledUpdateDraft,
  onDisabledAgainDraft,
  onD,
}) => {
  return (
    <Dialog
      open={open}
      // onClose={onClose}
      // fullWidth
      // maxWidth={"xs"}
    >
      <DialogTitle sx={{ boxShadow: 3 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography fontWeight={600} variant="h6">
            {dialogTitle}
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent
        dividers
        sx={
          {
            //   px: 4,
          }
        }
      >
        {dialogContent}
      </DialogContent>
      <DialogActions sx={{ boxShadow: 3 }}>
        {buttonTitle === "Replace EE Component" ||
        buttonTitle === "Replace SW Module" ? null : (
          <Button
            disabled={onDisabledAgainDraft}
            variant="contained"
            onClick={onConfirmAgainDraft}
            startIcon={<DraftsIcon />}
            sx={{ ...appTheme.commonBtnStyle }}
          >
            Save As Draft
          </Button>
        )}

        <Button
          variant="contained"
          onClick={
            buttonTitle === "Replace EE Component" ||
            buttonTitle === "Replace SW Module"
              ? onConfirm
              : onConfirmDraft
          }
          startIcon={<TbReplace style={{ fontSize: "22px" }} />}
          disabled={
            buttonTitle === "Replace EE Component" ||
            buttonTitle === "Replace SW Module"
              ? onDisabled
              : onDisabledUpdateDraft
          }
          sx={{ ...appTheme.commonBtnStyle }}
        >
          {buttonTitle === "Replace EE Component" ||
          buttonTitle === "Replace SW Module"
            ? "Replace"
            : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomReplaceDialog;
