import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";

import Collapse from "@mui/material/Collapse";

import Divider from "@mui/material/Divider";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";

import DeleteIcon from "@mui/icons-material/Delete";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import React, { useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import CustomButton from "../../components/buttons/CustomButton";
import CustomTextField1 from "../../components/text-fields/CustomTextField1";
import customConsole from "../../config/customConsole";

import CustomTextFieldSP from "../../components/text-fields/CustomTextFieldSP";
import { HandleApiActions } from "../../redux/actions/actionHandler";
import profileActions from "../../redux/actions/profile";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import adminSettingsAPI from "../../redux/actions/adminSetttingAPI";

import AddIcon from "@mui/icons-material/Add";
import { convertUtcToTimeZone } from "../../utils/convertUtcToTimeZone";
import CustomAddDialog from "../../components/dialog-boxes/CustomAddDialog";
import { DialogContentText, IconButton, TextField } from "@mui/material";
import CustomUpdateDialog from "../../components/dialog-boxes/CustomUpdateDialog";
import CustomConfirmationDialog from "../../components/dialog-boxes/CustomConfirmationDialog";
import { SelectTextField } from "../../components";

function VehicleStatus({ open, handleClick }) {
  const dispatch = useDispatch();

  const vehicleStatusDetails = useSelector(
    (state) => state.vehicleStatusReducer
  );

  const timeZoneDetails = useSelector((state) => state.profileReducer);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [getVehicleStatus, setGetVehicleStatus] = React.useState([]);
  const [openAddVehicleStatusDialog, setOpenAddVehicleStatusDialog] =
    React.useState(false);
  const [openEditVehicleStatusDialog, setOpenEditVehicleStatusDialog] =
    React.useState(false);
  const [openDeleteVehicleStstusDialog, setOpenDeleteVehicleStstusDialog] =
    React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [timeZone, setTimeZone] = React.useState([]);
  const [tableRowsData, setTableRowsData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    dispatch(
      HandleApiActions({
        ...profileActions.get_coutry_regions,
      })
    );
  }, []);

  useEffect(() => {
    setTimeZone(timeZoneDetails?.profile?.profile?.region);
  }, [timeZoneDetails]);

  useEffect(() => {
    const vehicleStatusList = getVehicleStatus
      ?.map((item, index) => ({
        sno: index + 1,
        id: item?.id || index,
        name: item?.name,
        sequence: item?.sequence,
        is_edit_allowed: item?.is_edit_allowed ? "YES" : "NO",
        is_approval_required: item?.is_approval_required,
        created_at: convertUtcToTimeZone(
          item?.created_at,
          timeZoneDetails?.profile?.profile?.region
        ),
        updated_at: convertUtcToTimeZone(
          item?.updated_at,
          timeZoneDetails?.profile?.profile?.region
        ),
        allData: item,
      }))
      .sort((a, b) => a.sequence - b.sequence);

    setTableRowsData(vehicleStatusList);
  }, [getVehicleStatus]);

  // const getVehicleStatusDetails = async () => {
  //   let apiDetails = adminSettingsAPI.get_vehicle_status;
  //   delete apiDetails.action_type;

  //   const resp = await dispatch(
  //     HandleApiActions({
  //       ...apiDetails,
  //       params: {},
  //       show_toast: false,
  //     })
  //   );
  //   if (resp.error) {
  //     setGetVehicleStatus([]);
  //   } else {
  //     setGetVehicleStatus(resp.data);
  //   }
  // };

  useEffect(() => {
    dispatch(
      HandleApiActions({
        ...adminSettingsAPI.get_vehicle_status,
      })
    );
  }, []);

  useEffect(() => {
    setGetVehicleStatus(
      vehicleStatusDetails?.vehicleStatusDetails?.data?.length
        ? vehicleStatusDetails?.vehicleStatusDetails?.data
        : []
    );
  }, [vehicleStatusDetails]);

  const COLUMNS = [
    // {
    //   field: "sno",
    //   headerName: "S.No",
    //   headerAlign: "left",
    //   width: 100,
    // },
    {
      field: "sequence",
      headerName: "Sequence",
      headerAlign: "left",
      width: 100,
    },
    { field: "name", headerName: "Name", headerAlign: "left", width: 180 },
    {
      field: "is_edit_allowed",
      headerName: "Is Editable",
      headerAlign: "left",
      width: 140,
    },
    {
      field: "created_at",
      headerName: "Created At",
      headerAlign: "left",
      width: 180,
    },
    {
      field: "updated_at",
      headerName: "Updated At",
      headerAlign: "left",
      width: 180,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <IconButton
          size="small"
          color="error"
          onClick={(e) => {
            e.stopPropagation();
            handleTableRowClick(params, true);
          }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  const toggleDialog = (newOpen) => () => {
    setOpenAddVehicleStatusDialog(newOpen);
  };

  const handleAddVehicleStatusClick = () => {
    toggleDialog(true)();
  };

  const handleTableRowClick = (params, isButtonClicked) => {
    setSelectedRow(params);
    customConsole(params);
    if (isButtonClicked) {
      setOpenDeleteVehicleStstusDialog(true);
    } else {
      setOpenEditVehicleStatusDialog(true); // Open the dialog unconditionally
    }
  };

  const filteredRows = useMemo(() => {
    if (!searchQuery) return tableRowsData;
    return tableRowsData.filter((row) =>
      COLUMNS.some((column) =>
        row[column.field]
          ?.toString()
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, tableRowsData, COLUMNS]);

  return (
    <div>
      <List
        sx={{
          width: "100%",
          maxWidth: "100%",
          marginTop: "15px",
          backgroundColor: "whitesmoke",
          boxShadow: "10px 10px 30px rgba(0, 0, 0, 0.2)",
          borderRadius: "0px",
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <ListItemButton onClick={() => handleClick("8")}>
          <ListItemText
            sx={{
              textAlign: "start",
              color: "black",
            }}
            primary="Vehicle Status"
            primaryTypographyProps={{
              fontWeight: 600,
              fontSize: "18px",
            }}
          />
          {open === "8" ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={open === "8"} timeout="auto" unmountOnExit>
          <Divider sx={{ margin: "10px" }} />
          <List
            component="div"
            disablePadding
            sx={{
              background: "whitesmoke",
              margin: "10px",
              padding: "10px",
              borderRadius: "10px",

              maxHeight: "500px",
            }}
          >
            <Box>
              <Paper sx={styles.container}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: "60px",
                    mr: "10px",
                    ml: "10px",
                  }}
                >
                  <Box>
                    <TextField
                      variant="standard"
                      label="Search"
                      size="small"
                      margin="normal"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      sx={{ margin: 0 }}
                    />
                  </Box>

                  <Box sx={styles.tblFun}>
                    <CustomButton
                      size={"medium"}
                      variant={"contained"}
                      btnName={"Add"}
                      startIcon={<AddIcon />}
                      handleOnClick={handleAddVehicleStatusClick}
                      btnStyle={{
                        ml: 2,
                        // borderRadius: "0px",
                      }}
                    />
                  </Box>
                </Box>
                <div
                  style={{
                    height: 400,
                    width: "100%",
                    scrollbarColor: "#888 #f1f1f1",
                  }}
                >
                  <DataGrid
                    initialState={{
                      density: "comfortable",
                    }}
                    sx={{
                      "& .MuiDataGrid-scrollbar.MuiDataGrid-scrollbar--horizontal.css-1rtad1":
                        {
                          display: "block",
                        },
                      "& .MuiDataGrid-columnHeaders": {
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        backgroundColor: "white",
                        "& .MuiDataGrid-columnHeaderTitle": {
                          fontWeight: "bold",
                        },
                      },
                    }}
                    localeText={{ toolbarDensity: "View" }}
                    // autoHeight={true}
                    rows={filteredRows || []}
                    columns={COLUMNS}
                    disableColumnMenu
                    disableColumnSelector
                    disableColumnFilter
                    disableRowSelectionOnClick
                    slots={{ toolbar: GridToolbar }}
                    pageSize={rowsPerPage}
                    slotProps={{
                      toolbar: {
                        printOptions: { disableToolbarButton: true },
                      },
                    }}
                    rowCount={
                      tableRowsData?.length >= rowsPerPage
                        ? -1
                        : page * rowsPerPage + tableRowsData?.length
                    }
                    onPaginationModelChange={(model) => {
                      setPage(model.page);
                      setRowsPerPage(model.pageSize);
                    }}
                    paginationModel={{ page: page, pageSize: rowsPerPage }}
                    pageSizeOptions={[25, 50, 100]}
                    onRowClick={(params) => handleTableRowClick(params.row)}
                  />
                </div>
                <AddVehicleStatus
                  open={openAddVehicleStatusDialog}
                  toggleDialog={toggleDialog}
                />
                <EditVehicleStatus
                  open={openEditVehicleStatusDialog}
                  setOpenEditVehicleStatusDialog={
                    setOpenEditVehicleStatusDialog
                  }
                  selectedRow={selectedRow}
                />
                <DeleteVehicleStstus
                  openDeleteVehicleStstusDialog={openDeleteVehicleStstusDialog}
                  setOpenDeleteVehicleStstusDialog={
                    setOpenDeleteVehicleStstusDialog
                  }
                  selectedRow={selectedRow}
                />
              </Paper>
            </Box>
          </List>
        </Collapse>
      </List>
    </div>
  );
}

export default VehicleStatus;

// Styling for the Users component
const styles = {
  container: {
    width: "100%",
    overflow: "auto",
  },
  tblFun: {
    mt: 2,
    mb: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
};

const AddVehicleStatus = ({ open, toggleDialog }) => {
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [sequence, setSequence] = useState();
  const [sequenceError, setSequenceError] = useState(false);
  const [isEditable, setIsEditable] = useState();
  const [isEditableError, setIsEditableError] = useState(false);
  const [selectedValue, setSelectedValue] = useState();

  const handleClose = () => {
    setName("");
    setSequence("");
    setIsEditable(undefined);
    toggleDialog(false)();
  };

  const handleClickAdd = () => {
    const reqObj = {
      current_time: new Date(),
      name: name,
      sequence: Number(sequence),
      is_edit_allowed: isEditable, // Always set based on the selected option
    };

    dispatch(
      HandleApiActions({
        ...adminSettingsAPI.add_vehicle_status,
        params: reqObj,
        show_toast: true,
      })
    ).then(() => {
      dispatch(
        HandleApiActions({
          ...adminSettingsAPI.get_vehicle_status,
        })
      );
    });

    // Reset form fields
    setName("");
    setSequence("");
    setIsEditable(undefined); // Set to the default value
    toggleDialog(false)();
  };

  return (
    <>
      <CustomAddDialog
        open={open}
        onClose={handleClose}
        dialogTitle={"Add Vehicle Status"}
        dialogContent={
          <Stack spacing={2}>
            <CustomTextFieldSP
              required
              size={"small"}
              label={"Name"}
              placeholder={"Name"}
              value={name}
              validationRules={{ minLength: 3 }}
              setValue={setName}
              txtFldStyle={{
                width: "270px",
              }}
              setError={setNameError}
              error={nameError !== ""}
            />
            <CustomTextFieldSP
              required
              size={"small"}
              label={"Sequence No"}
              placeholder={"Sequence No"}
              type={"number"}
              value={sequence}
              setValue={setSequence}
              validationRules={{ minimum: 1 }}
              txtFldStyle={{
                width: "270px",
              }}
              setError={setSequenceError}
              error={sequenceError !== ""}
            />
            <SelectTextField
              size={"small"}
              label={"Is Editable*"}
              optionsList={[
                {
                  value: true,
                  label: "Yes",
                },
                {
                  value: false,
                  label: "No",
                },
              ]}
              selectedValue={isEditable}
              setSelectedValue={setIsEditable}
              variant={"standard"}
            />
          </Stack>
        }
        onConfirm={handleClickAdd}
        onDisabled={
          name?.length > 2 &&
          name?.trim() !== "" &&
          sequence > 0 &&
          isEditable !== undefined
            ? false
            : true
        }
      />
    </>
  );
};

const EditVehicleStatus = ({
  open,
  setOpenEditVehicleStatusDialog,
  selectedRow,
}) => {
  const dispatch = useDispatch();
  // Form states
  const [editName, setEditName] = useState("");
  const [editSequence, setEditSequence] = useState("");
  const [editIsEditable, setEditIsEditable] = useState(false);
  const [isEditableError, setIsEditableError] = useState(false);

  // Populate fields when `selectedRow` changes
  useEffect(() => {
    if (selectedRow) {
      setEditName(selectedRow?.name || "");
      setEditSequence(selectedRow?.sequence || "");
      setEditIsEditable(selectedRow?.allData?.is_edit_allowed ?? false); // Ensure it's boolean
    }
  }, [selectedRow]);

  // Handle dialog close
  const handleClose = () => {
    setOpenEditVehicleStatusDialog(false);
  };

  // Handle the edit submission
  const handleClickEdit = async () => {
    // Validation check for `is_edit_allowed`
    if (editIsEditable === "") {
      setIsEditableError(true);
      return;
    }
    setIsEditableError(false);

    // Create the request object
    const reqObj = {
      name: editName,
      sequence: editSequence,
      is_edit_allowed: editIsEditable,
      current_time: new Date().toISOString(),
    };

    dispatch(
      HandleApiActions({
        ...adminSettingsAPI.update_vehicle_status,
        params: reqObj,
        show_toast: true,
      })
    ).then(() => {
      dispatch(HandleApiActions({ ...adminSettingsAPI.get_vehicle_status }));
    });

    handleClose();
  };

  return (
    <CustomUpdateDialog
      open={open}
      onClose={handleClose}
      dialogTitle={"Edit Vehicle Status"}
      dialogContent={
        <Stack spacing={2}>
          {/* Name field - Disabled for editing */}
          <CustomTextField1
            required
            size={"small"}
            label={"Name"}
            placeholder={"Name"}
            value={editName}
            setValue={setEditName}
            txtFldStyle={{
              width: "270px",
            }}
            disabled={true} // Name is not editable
          />

          {/* Sequence field - Disabled for editing */}
          <CustomTextField1
            required
            size={"small"}
            label={"Sequence"}
            placeholder={"Sequence"}
            value={editSequence}
            setValue={setEditSequence}
            txtFldStyle={{
              width: "270px",
            }}
            disabled={true} // Sequence is not editable
          />

          {/* Is Editable Field as Select */}
          <SelectTextField
            size={"small"}
            label={"Is Editable*"}
            optionsList={[
              {
                value: true,
                label: "Yes",
              },
              {
                value: false,
                label: "No",
              },
            ]}
            selectedValue={editIsEditable}
            setSelectedValue={(value) => setEditIsEditable(value)} // Handle selection change
            variant={"standard"}
          />
        </Stack>
      }
      onConfirm={handleClickEdit} // Trigger the edit action
    />
  );
};

const DeleteVehicleStstus = ({
  openDeleteVehicleStstusDialog,
  selectedRow,
  setOpenDeleteVehicleStstusDialog,
}) => {
  const dispatch = useDispatch();

  const handleDelete = () => {
    const reqObj = {
      name: selectedRow?.row?.name,
      current_time: new Date(),
    };

    dispatch(
      HandleApiActions({
        ...adminSettingsAPI.delete_vehicle_status,
        params: reqObj,
        show_toast: true,
      })
    ).then(() => {
      dispatch(HandleApiActions({ ...adminSettingsAPI.get_vehicle_status }));
    });
    setOpenDeleteVehicleStstusDialog(false);
  };
  return (
    <CustomConfirmationDialog
      open={openDeleteVehicleStstusDialog}
      onClose={() => setOpenDeleteVehicleStstusDialog(false)}
      dialogTitle={"Confirm Delete"}
      dialogContent={
        <DialogContentText>Are you sure want to delete?</DialogContentText>
      }
      onConfirm={handleDelete}
    />
  );
};
