/**
 * @author Narender - narender@au79consulting.com
 * @date 12-06-2024
 * @description Component for handling the login functionality.
 */

import {
  Avatar,
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { AuthTextField, AuthButton } from "../";
import appMeta from "../../assets/app-meta/appMeta";
import { Link, useNavigate } from "react-router-dom";

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 12-06-2024
 * @description : Component for handling the login functionality.
 * @param : handleLogin, handleAuthViewChange
 * @return : The rendered login component.
 */
function Login({ throttledHandleLogin, handleAuthViewChange }) {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  // Handles click event for "Forgot password?" link.
  const handleForgotPasswordOnClick = () => {
    handleAuthViewChange(appMeta.authView.FORGOT_PASSWORD);
  };

  // Handles click event for "Don't have an account? Register" link.
  const handleSignUpOnClick = () => {
    handleAuthViewChange(appMeta.authView.SIGNUP);
  };

  // Handles click event for "Login" button.
  // Validates user inputs and triggers login process.
  const handleLoginOnClick = () => {
    // Regular expression for a valid email address
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Validate email in real-time
    if (!emailRegex.test(email)) {
      setEmailError("Invalid email address");
      return;
    } else {
      setEmailError("");
    }

    // Password minmum length
    const passwdMinLength = 8;
    // Validate password in real-time
    if (password.length < passwdMinLength) {
      setPasswordError("Invalid password");
      return;
    } else {
      setPasswordError("");
    }

    // Trigger login function with user data
    throttledHandleLogin({ email, password, rememberMe });
  };

  return (
    <Box sx={styles.mainContainer}>
      <Avatar variant="rounded" sx={styles.avatar}>
        <img height={"100%"} src="deloitteIconBlackLarge.png" alt="Deloitte" />
      </Avatar>

      <Typography style={{ fontWeight: 600 }} component="h1" variant="body1">
        Welcome To Digital Homologation
      </Typography>

      {/* Login text fields */}
      <Box sx={styles.fldsContainer}>
        <AuthTextField
          label={"Email"}
          placeHolder={"Email"}
          type={"email"}
          fldType={appMeta.textFieldTypes.EMAIL}
          variant={"standard"}
          value={email}
          setValue={setEmail}
          valueError={emailError}
          setValueError={setEmailError}
          isAutoFocused={true}
          isRequired={true}
          onKeyDown={(event) => {
            if (event.key === "Enter") handleLoginOnClick();
          }}
          txtFldStyle={{
            mt: 3,
          }}
        />
        <AuthTextField
          label={"Password"}
          placeHolder={"Password"}
          type={"password"}
          fldType={appMeta.textFieldTypes.AUTHPASSWORD}
          variant={"standard"}
          value={password}
          setValue={setPassword}
          valueError={passwordError}
          setValueError={setPasswordError}
          // isAutoFocused={true}
          isRequired={true}
          onKeyDown={(event) => {
            if (event.key === "Enter") handleLoginOnClick();
          }}
          txtFldStyle={{
            mt: 3,
            mb: 2,
          }}
        />
        <Box sx={styles.rmFpContainer}>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  value="remember"
                  color="primary"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(!rememberMe)}
                />
              }
              label="Remember me"
            />
          </Box>
        </Box>
        <AuthButton
          type="submit"
          btnName="Login"
          handleOnClick={handleLoginOnClick}
        />
        {/* <Divider sx={styles.divider} /> */}
        <Box sx={styles.fpLinkContainer}>
          <Link
            style={styles.linkStyle}
            href="#"
            variant="body2"
            onClick={(e) => {
              e.preventDefault();
              handleForgotPasswordOnClick();
            }}
          >
            Forgot password?
          </Link>
        </Box>
      </Box>
    </Box>
  );
}

export default Login;

// Styling for the Login component
const styles = {
  mainContainer: {
    width: "100%",
    paddingX: 5,
    paddingY: 4,
    borderRadius: 1,
    backgroundColor: "white",
    border: { sm: "1px solid lightgrey" },
    boxShadow: { sm: "0px 2px 4px rgba(0, 0, 0, 0.1)" },
    boxSizing: "border-box",
  },
  fldsContainer: {
    width: "100%",
  },
  linkStyle: {
    color: "#1976d2",
  },
  avatar: {
    mb: 3,
    bgcolor: "white",
    width: "100%",
    height: 24,
  },
  rmFpContainer: {
    display: "flex",
    justifyContent: "start",
    marginTop: 0.5,
    marginBottom: -1.5,
  },
  divider: {
    mb: 2,
  },
  fpLinkContainer: {
    mt: 3,
    mb: 1,
  },
};
