import {
  Checkbox,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";

function MultiSelectUsers({
  sltFldStyle,
  optionsList,
  label,
  size,
  selectedValues,
  setSelectedValues,
}) {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedValues(
      typeof value === "string" ? value?.split(",")?.map(Number) : value
    );
  };
  return (
    <div>
      <FormControl variant="standard" size={size} sx={sltFldStyle}>
        <InputLabel>{label}</InputLabel>
        <Select
          sx={{ width: "270px" }}
          multiple
          value={selectedValues}
          onChange={handleChange}
          input={<Input />}
          renderValue={(selected) =>
            selected
              ?.map(
                (value) =>
                  optionsList?.find((option) => option?.value === value)?.label
              )
              ?.join(", ")
          }
          MenuProps={CustomMenuProps}
        >
          {optionsList?.map((data, idx) => (
            <MenuItem
              key={idx}
              value={data?.value}
              sx={{
                whiteSpace: "normal",
                wordBreak: "break-word",
                lineHeight: 1.5,
              }}
            >
              <Checkbox
                checked={(selectedValues || [])?.indexOf(data?.value) > -1}
              />
              <ListItemText primaryTypographyProps primary={`${data?.label}`} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default MultiSelectUsers;

const CustomMenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 220,
      overflowX: "auto", // Allow horizontal scrolling
      // whiteSpace: "nowrap", // Prevent text from wrapping to the next line
      overflowX: "hidden", // Hide horizontal overflow
      scrollbarWidth: "thin", // Firefox
      msOverflowStyle: "none", // IE and Edge
      "&::-webkit-scrollbar": {
        height: "8px", // Adjust scrollbar height
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#888", // Scrollbar thumb color
        borderRadius: "4px", // Scrollbar thumb rounded corners
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#f1f1f1", // Scrollbar track color
      },
    },
  },
};
