import {
  Box,
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { CustomButton } from "../../../components";
import AddIcon from "@mui/icons-material/Add";
import DynamicFormDialog from "./DynamicFormDialog";
import ViewDynammicForm from "./ViewDynammicForm";
import { useDispatch, useSelector } from "react-redux";
import { HandleApiActions } from "../../../redux/actions";

function FormsTemplates({ open, handleClick }) {
  //States
  const [openDynamicFormDialog, setOpenDynamicFormDialog] = useState(false);

  return (
    <div>
      <List
        sx={{
          width: "100%",
          maxWidth: "100%",
          marginTop: "15px",
          backgroundColor: "whitesmoke",
          boxShadow: "10px 10px 30px rgba(0, 0, 0, 0.2)",
          borderRadius: "0px",
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <ListItemButton onClick={() => handleClick("10")}>
          <ListItemText
            sx={{
              textAlign: "start",
              color: "black",
            }}
            primary="Forms/Templates"
            primaryTypographyProps={{
              fontWeight: 600,
              fontSize: "18px",
            }}
          />
          {open === "9" ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={open === "10"} timeout="auto" unmountOnExit>
          <Divider sx={{ margin: "10px" }} />
          <List
            component="div"
            disablePadding
            sx={{
              background: "whitesmoke",
              margin: "10px",
              padding: "10px",
              borderRadius: "10px",

              maxHeight: "500px",
            }}
          >
            <Box>
              <Box
                sx={{
                  mt: 2,
                  mb: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <CustomButton
                  size={"medium"}
                  variant={"contained"}
                  btnName={"Add"}
                  startIcon={<AddIcon />}
                  handleOnClick={() => setOpenDynamicFormDialog(true)}
                  btnStyle={{
                    ml: 2,
                    // borderRadius: "0px",
                  }}
                />
              </Box>
            </Box>
            <ViewDynammicForm />
          </List>
        </Collapse>
      </List>
      {openDynamicFormDialog && (
        <DynamicFormDialog
          open={openDynamicFormDialog}
          onClose={() => setOpenDynamicFormDialog(false)}
        />
      )}
    </div>
  );
}

export default FormsTemplates;
