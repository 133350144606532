/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 20-07-2024
 * @description : Custom dynamic form component.
 */
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import UpdateIcon from "@mui/icons-material/Update";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import appTheme from "../../assets/AppTheme/appTheme";
import CustomTextFieldN from "../text-fields/CustomTextFieldN";
import CustomButton from "../buttons/CustomButton";
import customConsole from "../../config/customConsole";
import DynamicFormTemplate from "./DynamicFormTemplate";
import {
  ALIGN_TYPES,
  EDIT_TYPES,
  FIELD_TYPES,
  FORM_TYPES,
} from "../../config/appConstants";

const DynamicFormDialog = ({
  open,
  onClose,
  dynamicFormData,
  setDynamicFormData,
  dynamicFormFilteredData,
  setDynamicFormFilteredData,
  dialogTitle,
  onConfirm,
  onDisabled,
}) => {
  /* States */
  const [selectedHeaders, setSelectedHeaders] = useState([]);
  const [selectedFooters, setSelectedFooters] = useState([]);
  const [selectedSignatures, setSelectedSignatures] = useState([]);

  // Add/Update states
  const [isShowAddField, setIsShowAddField] = useState(true);
  const [showAddUpdateField, setShowAddUpdateField] = useState(false);
  const [currentField, setCurrentField] = useState({
    field_name: "",
    value: "",
    default_value: "",
    edit_type: "",
    // field_type: "",
    sequence: "",
  });

  const headerOptionsList = [
    { position: ALIGN_TYPES?.LEFT, label: "Left" },
    { position: ALIGN_TYPES?.CENTER, label: "Center" },
    { position: ALIGN_TYPES?.RIGHT, label: "Right" },
  ];

  const footerOptionsList = [
    { position: ALIGN_TYPES?.LEFT, label: "Left" },
    { position: ALIGN_TYPES?.CENTER, label: "Center" },
    { position: ALIGN_TYPES?.RIGHT, label: "Right" },
  ];

  const signatureOptionsList = [
    { position: ALIGN_TYPES?.LEFT, label: "Left" },
    { position: ALIGN_TYPES?.RIGHT, label: "Right" },
  ];

  /* Functions */
  const handleAddUpdateField = () => {
    if (!isShowAddField) {
      // Update existing field
      const updatedFields = dynamicFormData?.fields?.map((field) =>
        field?.sequence === currentField?.sequence
          ? {
              ...field,
              field_name: currentField?.field_name,
              field_type: currentField?.field_type,
              edit_type: currentField?.edit_type,
            }
          : field
      );

      // Update the form data with the updated fields
      setDynamicFormData({
        ...dynamicFormData,
        fields: updatedFields,
      });
    } else {
      // Generate a unique sequence for the new field (using Date.now() or UUID)
      // const newSequence = String(dynamicFormData?.fields?.length + 1); // Using UUID for a globally unique sequence

      // Add new field with unique sequence
      const newField = {
        ...currentField,
        // sequence: newSequence,
      };

      setDynamicFormData({
        ...dynamicFormData,
        fields: [...dynamicFormData?.fields, newField],
      });
    }

    // Reset form and close it after adding or updating the field
    resetFieldInputs();
  };

  const handleFieldChange = (field, value) => {
    setCurrentField((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleEditField = (fieldSequence) => {
    // Find the field to edit by its sequence
    const fieldToEdit = dynamicFormData?.fields?.find(
      (field) => field?.sequence === fieldSequence
    );

    // If the field is found, set it in the `currentField` state
    if (fieldToEdit) {
      setCurrentField(fieldToEdit);
      setShowAddUpdateField(true);
      setIsShowAddField(false);
    }
  };

  const handleDeleteField = (fieldSequence) => {
    // Filter out the field with the given sequence
    const updatedFields = dynamicFormData?.fields?.filter(
      (field) => field?.sequence !== fieldSequence
    );

    // Update the dynamicFormData with the remaining fields
    setDynamicFormData((prevData) => ({
      ...prevData,
      fields: updatedFields,
    }));

    resetFieldInputs();
  };

  const resetFieldInputs = () => {
    // Reset the form fields
    setCurrentField({
      field_name: "",
      value: "",
      default_value: "",
      edit_type: "",
      // field_type: "",
      sequence: "",
    });

    // Close the "Add/Update Field" form
    setShowAddUpdateField(false);
  };

  /* UseEffects */
  useEffect(() => {
    // Define valid positions
    const validPositions = ["left", "center", "right"];
    const validPositions1 = ["left", "right"];

    // Update the selected headers list with valid positions
    let selectedHeadersList =
      dynamicFormData?.headers
        ?.map((el) =>
          validPositions?.includes(el?.position) ? String(el?.position) : null
        )
        ?.filter(Boolean) || [];
    setSelectedHeaders(selectedHeadersList);

    // Update the selected signatures list with valid positions
    let selectedSignaturesList =
      dynamicFormData?.signatures
        ?.map((el) =>
          validPositions1?.includes(el?.position) ? String(el?.position) : null
        )
        ?.filter(Boolean) || [];
    setSelectedSignatures(selectedSignaturesList);

    // Update the selected footers list with valid positions
    let selectedFootersList =
      dynamicFormData?.footers
        ?.map((el) =>
          validPositions?.includes(el?.position) ? String(el?.position) : null
        )
        ?.filter(Boolean) || [];
    setSelectedFooters(selectedFootersList);
  }, [open]);

  useEffect(() => {
    setDynamicFormFilteredData({
      master_template_id: Number(dynamicFormData?.id),
      name: dynamicFormData?.name,
      type: dynamicFormData?.type,
      fields: dynamicFormData?.fields,
      headers: dynamicFormData?.headers?.filter((el) =>
        selectedHeaders?.includes(el?.position)
      ),
      footers: dynamicFormData?.footers?.filter((el) =>
        selectedFooters?.includes(el?.position)
      ),
      signatures: dynamicFormData?.signatures?.filter((el) =>
        selectedSignatures?.includes(el?.position)
      ),
      status: dynamicFormData?.status,
    });
  }, [open, dynamicFormData]);

  useEffect(() => {
    customConsole({ dynamicFormFilteredData });
  }, [dynamicFormFilteredData]);

  return (
    <Dialog
      open={open}
      // onClose={onClose}
      fullWidth
      maxWidth={"lg"}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          textAlign: "center",
          boxShadow: 2,
          py: 1,
        }}
      >
        {/* Title text */}
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          {dialogTitle}
        </Typography>

        {/* Close Icon Button */}
        <IconButton
          edge="end"
          color="inherit"
          onClick={() => {
            onClose();
            resetFieldInputs();
          }}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
        }}
      >
        {/* Enter form name */}
        <Box>
          <Typography
            variant="subtitle1"
            color={"grey"}
            component="label"
            htmlFor="form-type"
          >
            Enter name of the form*
          </Typography>
          <TextField
            fullWidth
            id="form-type"
            variant="standard"
            type={"text"}
            size={"large"}
            value={dynamicFormData?.name}
            onChange={(e) =>
              setDynamicFormData((prev) => ({
                ...prev,
                name: e?.target?.value,
              }))
            }
            sx={{ mt: -1 }}
          />
        </Box>

        {/* Form type */}
        <Box>
          <Typography
            variant="subtitle1"
            color={"grey"}
            component="label"
            htmlFor="form-type"
          >
            Type of the form:{" "}
          </Typography>
          <Typography
            variant="subtitle1"
            color={"grey"}
            component="label"
            htmlFor="form-type"
          >
            {dynamicFormData?.type === FORM_TYPES?.EE
              ? "EE Component"
              : dynamicFormData?.type === FORM_TYPES?.SWM
              ? "Software Module"
              : dynamicFormData?.type === FORM_TYPES?.VEHICLE
              ? "Vehicle Component"
              : ""}
          </Typography>
        </Box>

        {/* Select headers, footers & signature  */}
        <Box sx={{ display: "flex", gap: 4 }}>
          {/* Select headers  */}
          <Box sx={{ width: "100%" }}>
            <Typography
              variant="subtitle1"
              color={"grey"}
              component="label"
              htmlFor="form-type"
            >
              Select header*
            </Typography>
            <FormControl variant="standard" fullWidth>
              {/* <InputLabel>Select header</InputLabel> */}
              <Select
                multiple
                value={selectedHeaders}
                onChange={(e) => setSelectedHeaders(e?.target?.value)}
                input={<Input />}
                renderValue={
                  (selected) =>
                    selected
                      ?.map(
                        (position) =>
                          headerOptionsList?.find(
                            (option) => option?.position === position
                          )?.label
                      )
                      ?.join(", ") // Display labels of selected values
                }
              >
                {headerOptionsList?.map((option) => (
                  <MenuItem key={option?.position} value={option?.position}>
                    <Checkbox
                      checked={selectedHeaders?.indexOf(option?.position) > -1}
                    />
                    <ListItemText primary={option?.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          {/* Select footers  */}
          <Box sx={{ width: "100%" }}>
            <Typography
              variant="subtitle1"
              color={"grey"}
              component="label"
              htmlFor="form-type"
            >
              Select footer*
            </Typography>
            <FormControl variant="standard" fullWidth>
              {/* <InputLabel>Select footer</InputLabel> */}
              <Select
                multiple
                value={selectedFooters}
                onChange={(e) => setSelectedFooters(e?.target?.value)}
                input={<Input />}
                renderValue={
                  (selected) =>
                    selected
                      ?.map(
                        (value) =>
                          footerOptionsList?.find(
                            (option) => option?.position === value
                          )?.label
                      )
                      ?.join(", ") // Display labels of selected values
                }
              >
                {footerOptionsList?.map((option) => (
                  <MenuItem key={option?.position} value={option?.position}>
                    <Checkbox
                      checked={selectedFooters?.indexOf(option?.position) > -1}
                    />
                    <ListItemText primary={option?.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          {/* Select signatures  */}
          <Box sx={{ width: "100%" }}>
            <Typography
              variant="subtitle1"
              color={"grey"}
              component="label"
              htmlFor="form-type"
            >
              Select signature*
            </Typography>
            <FormControl variant="standard" fullWidth>
              {/* <InputLabel>Select signature</InputLabel> */}
              <Select
                multiple
                value={selectedSignatures}
                onChange={(e) => setSelectedSignatures(e?.target?.value)}
                input={<Input />}
                renderValue={
                  (selected) =>
                    selected
                      ?.map(
                        (value) =>
                          signatureOptionsList?.find(
                            (option) => option?.position === value
                          )?.label
                      )
                      ?.join(", ") // Display labels of selected values
                }
              >
                {signatureOptionsList?.map((option) => (
                  <MenuItem key={option?.position} value={option?.position}>
                    <Checkbox
                      checked={
                        selectedSignatures?.indexOf(option?.position) > -1
                      }
                    />
                    <ListItemText primary={option?.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          {/* Add  */}
          <Box
            sx={{
              width: "70%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CustomButton
              size={"small"}
              variant={"contained"}
              btnName={"Add "}
              startIcon={<AddIcon />}
              handleOnClick={() => {
                // Reset the form fields
                setCurrentField({
                  field_name: "",
                  value: "",
                  default_value: "",
                  edit_type: "",
                  // field_type: "",
                  sequence: "",
                });
                setShowAddUpdateField(true);
                setIsShowAddField(true);
              }}
              btnStyle={{
                ml: 2,
              }}
            />
          </Box>
        </Box>

        {/* Template & Add/Update field */}
        <Box sx={{ display: "flex", gap: 2.5 }}>
          {/* Template */}
          <DynamicFormTemplate
            handleEditField={handleEditField}
            handleDeleteField={handleDeleteField}
            headerOptionsList={headerOptionsList}
            signatureOptionsList={signatureOptionsList}
            footerOptionsList={footerOptionsList}
            selectedHeaders={selectedHeaders}
            selectedSignatures={selectedSignatures}
            selectedFooters={selectedFooters}
            dynamicFormData={dynamicFormData}
            setDynamicFormData={setDynamicFormData}
          />

          {/* Add/Update field */}
          {showAddUpdateField ? (
            <Paper
              elevation={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                px: 1,
                py: 1.4,
                border: "1px solid grey",
                borderRadius: 1,
                height: "375px",
                width: "240px",
              }}
            >
              {/* Title text */}
              <Typography
                sx={{ fontSize: "17px", fontWeight: 600, textAlign: "center" }}
              >
                {isShowAddField ? "Add Field" : "Update Field"}
              </Typography>
              <Divider sx={{ m: -1, p: 0, border: "1px solid lightgrey" }} />

              {/* Enter sequence no */}
              <Box>
                <Typography
                  variant="subtitle1"
                  color={"grey"}
                  component="label"
                  htmlFor="sequence-no"
                >
                  {isShowAddField ? "Enter" : ""} sequence no*
                </Typography>
                <TextField
                  fullWidth
                  id="sequence-no"
                  variant="standard"
                  type={"text"}
                  size={"small"}
                  disabled={
                    dynamicFormData?.fields?.some(
                      (otherField) =>
                        otherField?.sequence === currentField?.sequence
                    ) && !isShowAddField
                  }
                  error={
                    dynamicFormData?.fields?.some(
                      (otherField) =>
                        otherField?.sequence === currentField?.sequence
                    ) && isShowAddField
                  }
                  helperText={
                    dynamicFormData?.fields?.some(
                      (otherField) =>
                        otherField?.sequence === currentField?.sequence
                    ) && isShowAddField
                      ? "Sequence must be unique"
                      : ""
                  }
                  value={currentField?.sequence || ""}
                  onChange={(e) =>
                    handleFieldChange("sequence", e.target?.value)
                  }
                  sx={{ width: "220px", mt: -1 }}
                />
              </Box>

              {/* Enter field name */}
              <Box>
                <Typography
                  variant="subtitle1"
                  color={"grey"}
                  component="label"
                  htmlFor="field-name"
                >
                  Enter field name*
                </Typography>
                <TextField
                  fullWidth
                  id="field-name"
                  variant="standard"
                  type={"text"}
                  size={"small"}
                  value={currentField?.field_name || ""}
                  onChange={(e) =>
                    handleFieldChange("field_name", e.target?.value)
                  }
                  sx={{ width: "220px", mt: -1 }}
                />
              </Box>

              {/* Select field type */}
              <Box>
                <FormControl>
                  <FormLabel>Select field type*</FormLabel>
                  <RadioGroup
                    row
                    value={currentField?.field_type || FIELD_TYPES?.TEXT}
                    onChange={(e) =>
                      handleFieldChange("field_type", e.target?.value)
                    }
                    sx={{ mt: -1 }}
                  >
                    {[
                      { value: FIELD_TYPES?.NUMBER, label: "Number" },
                      { value: FIELD_TYPES?.TEXT, label: "Text" },
                    ]?.map((item) => (
                      <FormControlLabel
                        key={item?.value}
                        value={item?.value}
                        control={<Radio />}
                        label={item?.label}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Box>

              {/* Select edit type */}
              <Box>
                <FormControl>
                  <FormLabel>Select edit type*</FormLabel>
                  <RadioGroup
                    row
                    value={currentField?.edit_type || ""}
                    onChange={(e) =>
                      handleFieldChange("edit_type", Number(e.target?.value))
                    }
                    sx={{ mt: -1 }}
                  >
                    {[
                      { value: EDIT_TYPES?.DDH, label: "DDH" },
                      { value: EDIT_TYPES?.SNOW, label: "SNOW" },
                      { value: EDIT_TYPES?.BOTH, label: "BOTH" },
                    ]?.map((item) => (
                      <FormControlLabel
                        key={item?.value}
                        value={item?.value}
                        control={<Radio />}
                        label={item?.label}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Box>

              {/* Close & Add/Update buttons */}
              <Box
                sx={{ display: "flex", justifyContent: "flex-end", gap: 1.4 }}
              >
                <CustomButton
                  size={"small"}
                  variant={"contained"}
                  btnName={"Close"}
                  startIcon={<CloseIcon />}
                  handleOnClick={resetFieldInputs} // Reset the form
                  btnStyle={{}}
                />
                <CustomButton
                  size={"small"}
                  variant={"contained"}
                  btnName={isShowAddField ? "Add" : "Update"}
                  disabled={
                    !(
                      currentField?.field_name?.length &&
                      String(currentField?.edit_type)?.length &&
                      String(currentField?.sequence)?.length
                    ) ||
                    (isShowAddField &&
                      dynamicFormData?.fields?.some(
                        (otherField) =>
                          otherField?.sequence === currentField?.sequence
                      ))
                  }
                  startIcon={isShowAddField ? <CheckIcon /> : <UpdateIcon />}
                  handleOnClick={handleAddUpdateField}
                  btnStyle={{}}
                />
              </Box>
            </Paper>
          ) : null}
        </Box>
      </DialogContent>
      <DialogActions sx={{ boxShadow: 4, gap: 2, px: 2, py: 1 }}>
        <CustomButton
          size={"small"}
          variant={"contained"}
          btnName={"Close"}
          startIcon={<CloseIcon />}
          handleOnClick={() => {
            onClose();
            resetFieldInputs();
          }}
          btnStyle={{}}
        />
        <CustomButton
          size={"small"}
          variant={"contained"}
          btnName={"Add form"}
          startIcon={<CheckIcon />}
          disabled={
            // !(
            //   Array?.isArray(dynamicFormFilteredData?.fields) &&
            //   dynamicFormFilteredData?.fields?.length >= 1 &&
            //   dynamicFormFilteredData?.fields?.every(
            //     (field) => field?.value?.trim()?.length > 0
            //   )
            // ) ||
            !(
              Array?.isArray(dynamicFormFilteredData?.headers) &&
              dynamicFormFilteredData?.headers?.length >= 1 &&
              dynamicFormFilteredData?.headers?.every(
                (header) =>
                  typeof header?.title === "string" &&
                  header?.title?.trim().length > 0 &&
                  typeof header?.sub_title === "string" &&
                  header?.sub_title?.trim().length > 0
              ) &&
              selectedHeaders?.every((position) =>
                dynamicFormFilteredData?.headers?.some(
                  (header) => header?.position === position
                )
              )
            ) ||
            !(
              Array?.isArray(dynamicFormFilteredData?.footers) &&
              dynamicFormFilteredData?.footers?.length >= 1 &&
              dynamicFormFilteredData?.footers?.every(
                (footer) =>
                  typeof footer?.title === "string" &&
                  footer?.title?.trim().length > 0 &&
                  typeof footer?.sub_title === "string" &&
                  footer?.sub_title?.trim().length > 0
              ) &&
              selectedFooters?.every((position) =>
                dynamicFormFilteredData?.footers?.some(
                  (footer) => footer?.position === position
                )
              )
            ) ||
            !(
              Array?.isArray(dynamicFormFilteredData?.signatures) &&
              dynamicFormFilteredData?.signatures?.length >= 1 &&
              dynamicFormFilteredData?.signatures?.every(
                (signature) =>
                  typeof signature?.title === "string" &&
                  signature?.title?.trim().length > 0 &&
                  typeof signature?.sub_title === "string" &&
                  signature?.sub_title?.trim().length > 0
              ) &&
              selectedSignatures?.every((position) =>
                dynamicFormFilteredData?.signatures?.some(
                  (signature) => signature?.position === position
                )
              )
            )
          }
          handleOnClick={() => {
            onClose();
            resetFieldInputs();
            onConfirm();
          }}
          btnStyle={{}}
        />
      </DialogActions>
    </Dialog>
  );
};

export default DynamicFormDialog;
