import { Box, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SimulationFiles from "./SimulationFiles";
import Reports from "./Reports";

import { SearchSelectTextFieldN } from "../../components";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import selectProjectAction from "../../redux/actions/selectProject";
import selectVariantAction from "../../redux/actions/selectVariant";
import { useLocation } from "react-router-dom";
import {
  HandleApiActions,
  labCar,
  projectConfigAPI,
} from "../../redux/actions";

function DocumentLibrary() {
  const dispatch = useDispatch();
  const location = useLocation();
  /* -------------- useSelectors -------------- */
  const { projectDetails, selectedProject, selectedVariant } = useSelector(
    (state) => state.labCarReducer,
    shallowEqual
  );
  const { variantInfo: variantDetails } = useSelector(
    (state) => state.variantReducer,
    shallowEqual
  );

  const [projectOptionsList, setProjectOptionsList] = useState([]);
  const [variantOptionsList, setVariantOptionsList] = useState([]);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState(null);
  const [selectedVariantDetails, setSelectedVariantDetails] = useState(null);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /* -------------- UseEffects -------------- */
  useEffect(() => {
    /* Get project details */
    dispatch(HandleApiActions({ ...labCar.get_project_details }));
  }, []);

  /* Get project options list & select first project by default, if not selected */

  useEffect(() => {
    // Map through the project details to create options for the dropdown
    let filteredProjectOptionsList = projectDetails
      ?.map((el) => {
        return {
          value: el?.project_id,
          label: el?.name,
        };
      })
      ?.sort((a, b) => a.label.localeCompare(b.label));

    setProjectOptionsList(filteredProjectOptionsList);

    // Try to find the project that matches the projectId
    const queryParams = new URLSearchParams(location.search);

    const projectId = queryParams.get("project_id");

    const selectedProjectQuery = filteredProjectOptionsList.find(
      (project) => project.value === projectId
    );

    if (selectedProjectQuery) {
      // If a matching project is found, set it as the selected project
      dispatch(selectProjectAction(selectedProjectQuery));
    }
    /* Store first project in the redux store */
    if (!(selectedProject?.value && selectedProject?.label)) {
      dispatch(selectProjectAction(filteredProjectOptionsList?.[0]));
    }
  }, [projectDetails]);

  useEffect(() => {
    /* Get variant options list */
    let filteredVariantOptionsList = variantDetails
      ?.map((el) => {
        return {
          value: el?.variant_id,
          label: el?.name,
        };
      })
      ?.sort((a, b) => a.label.localeCompare(b.label));

    setVariantOptionsList(filteredVariantOptionsList);

    /* Store first project in the redux store */
    if (!(selectedVariant?.value && selectedVariant?.label)) {
      dispatch(selectVariantAction(filteredVariantOptionsList?.[0]));
    }
  }, [variantDetails]);

  /* Get selected project details */
  useEffect(() => {
    if (selectedProject) {
      let filteredSelectedProjectDetails = projectDetails?.find(
        (el) => el?.project_id === selectedProject?.value
      );
      setSelectedProjectDetails(filteredSelectedProjectDetails);
    }

    /* Selected variant will be null, when the project is changed */
    dispatch(selectVariantAction(null));
  }, [selectedProject]);

  /* Get selected variant details */
  useEffect(() => {
    let filteredSelectedVariantDetails = variantDetails?.find(
      (el) => el?.variant_id === selectedVariant?.value
    );
    setSelectedVariantDetails(filteredSelectedVariantDetails);
  }, [variantDetails, selectedVariant]);

  useEffect(() => {
    if (selectedProject) {
      dispatch(
        HandleApiActions({
          ...projectConfigAPI.get_variant_details,
          params: {
            project_id: selectedProject?.value,
            is_complete_details_required: true,
          },
        })
      );
    }
  }, [selectedProject]);

  return (
    <div>
      <Box sx={styles.topContainer}>
        <Box sx={styles.searchContainer}>
          <SearchSelectTextFieldN
            size={"small"}
            label={"Search Project"}
            placeholder={"Search Project"}
            noOptionsText={"No projects found"}
            optionsList={projectOptionsList}
            selectedValue={selectedProject}
            handleValueChange={(event, value) =>
              // Store the selected project in the redux store
              dispatch(selectProjectAction(value))
            }
            txtFldStyle={styles.searchSelectTextField}
          />
        </Box>
        <Box sx={styles.searchContainer}>
          <SearchSelectTextFieldN
            size={"small"}
            label={"Search Variant"}
            placeholder={"Search Variant"}
            noOptionsText={"No variants found"}
            optionsList={variantOptionsList}
            selectedValue={selectedVariant}
            handleValueChange={(event, value) =>
              // Store the selected variant in the redux store
              dispatch(selectVariantAction(value))
            }
            txtFldStyle={styles.searchSelectTextField}
          />
        </Box>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Reports" {...a11yProps(0)} />
            <Tab label="Simulation Files" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Reports
            selectedProjectDetails={selectedProjectDetails}
            selectedVariantDetails={selectedVariantDetails}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <SimulationFiles
            selectedProjectDetails={selectedProjectDetails}
            selectedVariantDetails={selectedVariantDetails}
          />
        </CustomTabPanel>
      </Box>
    </div>
  );
}

export default DocumentLibrary;

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

//Styles for the Page
const styles = {
  box1: { width: "100%", position: "relative" },
  box2: {
    position: "sticky",
    top: 0,
    borderBottom: 1,
    borderColor: "divider",
    backgroundColor: "white",
    zIndex: 1000,
    borderTop: "1px solid lightgrey",
  },
  searchContainer: {
    textAlign: "start",
    mt: { xs: 2, md: 0 },
  },
  searchSelectTextField: {
    width: "215px",
    backgroundColor: "white",
  },
  topContainer: {
    mt: 1,
    mx: 2,
    flexDirection: { xs: "column", md: "row" },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: { xs: 2, md: 4 },
  },
  prjDetailsContainer: {
    px: 2,
    // py: 1,
    mx: 1,
    height: "100%",
  },
  prjDetailsTxt: {
    textAlign: "start",
    alignContent: "center",
  },
  txtBold: {
    fontWeight: "bold",
  },
};
