import customConsole from "../../config/customConsole";
import actionType from "../actionTypes";

const initialState = {
  error: false,
  msg: "",
  projectConfig: [],
  // Project
  projects: [],
  selectedProjectId: null,
  selectedProjectDetails: null,
  // Variant
  variants: [],
  selectedVariantId: null,
  selectedVariantDetails: null,
};

const projectConfigReducer = (state = initialState, action) => {
  customConsole(action);
  switch (action.type) {
    case actionType.ADD_PROJECT:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.GET_PROJECT:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        projectConfig: action.payload.error ? [] : action.payload.data,
      };

    case actionType.UPDATE_PROJECT:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.UPDATE_PROJECT_STATUS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    // Project
    case actionType.GET_PROJECTS_MAIN: {
      const projects = Array.isArray(action.payload?.data)
        ? action.payload.data
        : [];
      const isValidSelection = projects.some(
        (project) => project.project_id === state.selectedProjectId
      );
      return {
        ...state,
        projects,
        selectedProjectId: isValidSelection
          ? state.selectedProjectId
          : projects.length > 0
          ? projects[0]?.project_id
          : null,
        selectedProjectDetails: isValidSelection
          ? state.selectedProjectDetails
          : projects.length > 0
          ? projects[0]
          : null,
      };
    }
    case actionType.SET_SELECTED_PROJECT_MAIN: {
      const selectedProject =
        Array.isArray(state.projects) && state.projects?.length > 0
          ? state.projects.find(
              (project) =>
                parseInt(project?.project_id, 10) ===
                parseInt(action.payload, 10)
            ) || state.projects[0] // Select the first one if payload isn't found
          : null;

      return {
        ...state,
        selectedProjectId: selectedProject?.project_id ?? null,
        selectedProjectDetails: selectedProject ?? null,
      };
    }
    // Variant
    case actionType.GET_VARIANTS_MAIN: {
      const variants = Array.isArray(action.payload?.data)
        ? action.payload.data
        : [];
      const isValidSelection = variants.some(
        (variant) => variant.variant_id === state.selectedVariantId
      );
      return {
        ...state,
        variants,
        selectedVariantId: isValidSelection
          ? state.selectedVariantId
          : variants.length > 0
          ? variants[0]?.variant_id
          : null,
        selectedVariantDetails: isValidSelection
          ? state.selectedVariantDetails
          : variants.length > 0
          ? variants[0]
          : null,
      };
    }
    case actionType.SET_SELECTED_VARIANT_MAIN: {
      const selectedVariant =
        Array.isArray(state.variants) && state.variants?.length > 0
          ? state.variants.find(
              (variant) =>
                parseInt(variant?.variant_id, 10) ===
                parseInt(action.payload, 10)
            ) || state.variants[0] // Select the first one if payload isn't found
          : null;

      return {
        ...state,
        selectedVariantId: selectedVariant?.variant_id ?? null,
        selectedVariantDetails: selectedVariant ?? null,
      };
    }
    case actionType.RESET_PROJECT_CONFIG:
      return initialState;
    default:
      return state;
  }
};

export default projectConfigReducer;
