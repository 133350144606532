import actionType from "../actionTypes";

const initialState = {
  error: false,
  msg: "",
  reports: [],
};

const documentLibraryReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.GET_DF_REPORTS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        reports: action.payload.error ? [] : action.payload.data,
      };

    default:
      return state;
  }
};

export default documentLibraryReducer;
