/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 23-09-2024
 * @description : To give co-owner/editor/viewer access to the project/zones/ee component/software module
 */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import customConsole from "../../../config/customConsole";
import { HandleApiActions, labCar } from "../../../redux/actions";
import {
  Button,
  ButtonGroup,
  Paper,
  Typography,
  Divider,
  Grid,
  Box,
} from "@mui/material";
import {
  CustomButton,
  EEDetails,
  ModuleAccessTypes,
  ProjectDetails,
  SearchSelectTextField,
  SelectModules,
  SWMDetails,
  VariantDetailsBox,
  VehicleDetailsBox,
} from "../../../components";
import ProjectAccessDrawer from "../ProjectAccessDrawer";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

function AccessControlTab({
  selectedProjectDetails,
  projectStatus,
  moduleTypes,
  accessRequestStatus,
}) {
  const dispatch = useDispatch();

  // New Requests Buttons
  const [selectedPaBtn, setSelectedPaBtn] = useState(moduleTypes?.PROJECT);

  // Co-owners state
  // const [coOwnersOptionsList, setCoOwnersOptionsList] = useState([]);
  // const [selectedCoOwner, setSelectedCoOwner] = useState(null);

  // Access type
  // const [selectedAtBtn, setSelectedAtBtn] = useState(accessTypes?.COOWNER);

  // Variant Components States
  const [variantDetails, setVariantDetails] = useState([]);
  const [variantOptionsList, setVariantOptionsList] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [selectedVariantDetails, setSelectedVariantDetails] = useState(null);

  // Vehicle States
  const [vehicleDetails, setVehicleDetails] = useState([]);
  const [vehicleOptionsList, setVehicleOptionsList] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [selectedVehicleDetails, setSelectedVehicleDetails] = useState(null);

  // Zones States
  const [zoneOptionsList, setZoneOptionsList] = useState([]);
  const [selectedZone, setSelectedZone] = useState(null);
  const [selectedZoneDetails, setSelectedZoneDetails] = useState(null);

  // EE Components States
  const [zoneEEDetails, setZoneEEDetails] = useState([]);
  const [eeOptionsList, setEEOptionsList] = useState([]);
  const [selectedEE, setSelectedEE] = useState(null);
  const [selectedEEDetails, setSelectedEEDetails] = useState(null);

  // Software Modules States
  const [eeSwmDetails, setEESwmDetails] = useState([]);
  const [swmOptionsList, setSWMOptionsList] = useState([]);
  const [selectedSWM, setSelectedSWM] = useState(null);
  const [selectedSWMDetails, setSelectedSWMDetails] = useState(null);

  //
  const [reasonForAccess, setReasonForAccess] = useState("");

  // Access Drawer
  const [isAccessDrawerOpened, setIsAccessDrawerOpened] = useState(false);
  const [accessControlData, setAccessControlData] = useState({});

  const handleCloseDrawer = () => {
    setIsAccessDrawerOpened(false);
    setAccessControlData({});
  };

  const handleAccessBtnClick = (params) => {
    customConsole("handleAccessBtnClick: ");
    customConsole(params);

    setAccessControlData(params);
    setIsAccessDrawerOpened(true);
  };

  // Functions to handle data //
  // Get Variant Details
  const getVariantDetails = async ({ projectId }) => {
    let apiDetails = labCar.get_variant_details;

    customConsole({
      ...apiDetails,
      params: {
        project_id: projectId,
        is_complete_details_required: false,
        // is_for_access: true,
      },
    });

    const resp = await dispatch(
      HandleApiActions({
        ...apiDetails,
        params: {
          project_id: projectId,
          is_complete_details_required: true,
          // is_for_access: true,
        },
      })
    );

    if (resp.error) {
      setVariantDetails([]);
    } else {
      setVariantDetails(resp.data);
    }
  };

  // Get EE Components Details
  const getZoneEEDetails = async ({ projectId, variantId, zonePosition }) => {
    let apiDetails = labCar.get_ee_components_pa;

    customConsole({
      ...apiDetails,
      params: {
        project_id: projectId,
        variant_id: variantId,
        ...(zonePosition && { zone_position: zonePosition }),
        // is_for_req_access: true,
        status: true,
      },
    });

    const resp = await dispatch(
      HandleApiActions({
        ...apiDetails,
        params: {
          project_id: projectId,
          variant_id: variantId,
          ...(zonePosition && { zone_position: zonePosition }),
          // is_for_req_access: true,
          status: true,
        },
      })
    );

    if (resp.error) {
      setZoneEEDetails([]);
    } else {
      setZoneEEDetails(resp.data);
    }
  };

  // Get Software Module Details
  const getEESwmDetails = async ({ projectId, variantId, eeId }) => {
    let apiDetails = labCar.get_sw_modules_pa;

    customConsole({
      ...apiDetails,
      params: {
        project_id: projectId,
        variant_id: variantId,
        ee_id: eeId,
        // is_for_req_access: true,
      },
    });

    const resp = await dispatch(
      HandleApiActions({
        ...apiDetails,
        params: {
          project_id: projectId,
          variant_id: variantId,
          ee_id: eeId,
          // is_for_req_access: true,
        },
      })
    );

    if (resp.error) {
      setEESwmDetails([]);
    } else {
      setEESwmDetails(resp.data);
    }
  };

  /* UseEffects */
  useEffect(() => {
    // Make the states to default when the project is changed
    // access type buttons
    setSelectedPaBtn(moduleTypes?.PROJECT);
    // Variant
    setVariantOptionsList([]);
    setSelectedVariant(null);
    setSelectedVariantDetails(null);
    // Vehicle
    setVehicleOptionsList([]);
    setSelectedVehicle(null);
    setSelectedVehicleDetails(null);
    // Zones
    setZoneOptionsList([]);
    setSelectedZone(null);
    setSelectedZoneDetails(null);
    // EE Components
    setEEOptionsList([]);
    setSelectedEE(null);
    setSelectedEEDetails(null);
    // Software Modules
    setSWMOptionsList([]);
    setSelectedSWM(null);
    setSelectedSWMDetails(null);
  }, [selectedProjectDetails]);

  useEffect(() => {
    // When the selected Variant is changed, make the Vehicle component state to default
    setVehicleOptionsList([]);
    setSelectedVehicle(null);
    setSelectedVehicleDetails(null);
    // When the selected Variant is changed, make the Zone state to default
    setZoneOptionsList([]);
    setSelectedZone(null);
    setSelectedZoneDetails(null);
  }, [selectedVariant]);

  useEffect(() => {
    // When the selected Zone is changed, make the EE Components state to default
    setEEOptionsList([]);
    setSelectedEE(null);
    setSelectedEEDetails(null);
  }, [selectedVariant, selectedZone]);

  useEffect(() => {
    // When the selected Zone/EE Component is changed, make the Software Modules state to default
    setSWMOptionsList([]);
    setSelectedSWM(null);
    setSelectedSWMDetails(null);
  }, [selectedVariant, selectedZone, selectedEE]);

  useEffect(() => {
    if (selectedProjectDetails?.project_id) {
      // Get variant details
      getVariantDetails({
        projectId: selectedProjectDetails?.project_id,
      });
    }
  }, [selectedProjectDetails]);

  useEffect(() => {
    // Get variant options list
    let filteredVariantOptionsList = variantDetails
      ?.map((el) => {
        return {
          value: el?.variant_id,
          label: el?.name,
        };
      })
      ?.sort((a, b) => a.label.localeCompare(b.label));
    setVariantOptionsList(filteredVariantOptionsList);

    // Select the first variant
    if (filteredVariantOptionsList?.length) {
      setSelectedVariant(filteredVariantOptionsList?.[0]);
    }
  }, [variantDetails]);

  useEffect(() => {
    // Get variant details
    let filteredVariantDetails = variantDetails?.find(
      (el) => el?.variant_id == selectedVariant?.value
    );
    setSelectedVariantDetails(filteredVariantDetails);
  }, [selectedVariant]);

  useEffect(() => {
    // let filteredCoOwnersOptionsList = selectedProjectDetails?.co_owner_details
    //   ?.map((el) => {
    //     if (el?.user_id) {
    //       return {
    //         value: el.user_id,
    //         label: `${el.name} - ${el.email}`,
    //       };
    //     }
    //     return null;
    //   })
    //   ?.filter(Boolean);
    // setCoOwnersOptionsList(filteredCoOwnersOptionsList);
    // // Select first co-owner by default
    // setSelectedCoOwner(selectedProjectDetails?.co_owner_details[0]?.user_id);

    // If Centralized project, Get the EE Components Details, When the project is selected
    if (
      selectedProjectDetails?.project_id &&
      !selectedProjectDetails?.is_zonal &&
      selectedVariantDetails?.variant_id
    ) {
      getZoneEEDetails({
        projectId: selectedProjectDetails?.project_id,
        variantId: selectedVariantDetails?.variant_id,
      });
    }

    // Vehicle components options list
    let filteredVehicleOptionsList = selectedVariantDetails?.vehicle_components
      ?.filter((el) => el?.id != null)
      ?.map((el) => {
        return {
          value: el?.id,
          label: el?.component,
        };
      })
      ?.sort((a, b) => a.label.localeCompare(b.label));
    setVehicleOptionsList(filteredVehicleOptionsList);
    // Select the first vehicle component, when the selected variant is changed
    if (filteredVehicleOptionsList?.length) {
      setSelectedVehicle(filteredVehicleOptionsList?.[0]);
    }

    // Zones options list
    let filteredZoneOptionsList = selectedVariantDetails?.zone_details
      ?.map((el) => {
        return {
          // value: el?.sequence,
          value: el?.zone?.position,
          label: el?.zone?.zone_name,
        };
      })
      ?.sort((a, b) => a.label.localeCompare(b.label));
    setZoneOptionsList(filteredZoneOptionsList);
    // Select the first zone, when the selected variant is changed
    if (filteredZoneOptionsList?.length) {
      setSelectedZone(filteredZoneOptionsList?.[0]);
    }
  }, [selectedVariantDetails]);

  useEffect(() => {
    // Get the vehicle details
    let filteredSelectedVehicleDetails =
      selectedVariantDetails?.vehicle_components?.find(
        (el) => el?.id == selectedVehicle?.value
      );
    setSelectedVehicleDetails(filteredSelectedVehicleDetails);
    customConsole({ filteredSelectedVehicleDetails });
  }, [selectedVehicle]);

  useEffect(() => {
    /* Update selected zone details based on the selected zone */
    let filteredSelectedZoneDetails =
      selectedVariantDetails?.zone_details?.find(
        (el) => el?.zone?.position == selectedZone?.value
      );
    setSelectedZoneDetails(filteredSelectedZoneDetails);

    // If Zonal project, Get the EE Components Details, When the zone is selected
    if (
      selectedProjectDetails?.project_id &&
      selectedVariantDetails?.variant_id &&
      selectedZone?.value
    ) {
      getZoneEEDetails({
        projectId: selectedProjectDetails?.project_id,
        variantId: selectedVariantDetails?.variant_id,
        zonePosition: selectedZone?.value,
      });
    }
  }, [selectedZone]);

  useEffect(() => {
    // EE Components Options List
    let filteredEEOptionsList = zoneEEDetails
      ?.map((el) => {
        return {
          value: el?.ee_id,
          label: el?.description,
        };
      })
      ?.sort((a, b) => a.label.localeCompare(b.label));
    setEEOptionsList(filteredEEOptionsList);

    // Select the first ee component, when the selected zone is changed
    if (filteredEEOptionsList?.length) {
      setSelectedEE(filteredEEOptionsList[0]);
    }
  }, [zoneEEDetails]);

  useEffect(() => {
    // When EE Component is selected, Get the Software Module Details
    if (
      selectedProjectDetails?.project_id &&
      selectedVariantDetails?.variant_id &&
      selectedEE?.value
    ) {
      getEESwmDetails({
        projectId: selectedProjectDetails?.project_id,
        variantId: selectedVariantDetails?.variant_id,
        eeId: selectedEE?.value,
      });
    }

    // Get Selected EE Component details
    let filteredSelectedEEDetails = zoneEEDetails?.find(
      (el) => el?.ee_id == selectedEE?.value
    );
    setSelectedEEDetails(filteredSelectedEEDetails);
  }, [selectedEE]);

  useEffect(() => {
    // Software Module Options List
    let filteredSWMOptionsList = eeSwmDetails
      ?.map((el) => {
        return {
          value: el?.swm_id,
          label: el?.name,
        };
      })
      ?.sort((a, b) => a.label.localeCompare(b.label));
    setSWMOptionsList(filteredSWMOptionsList);

    // Select the first software module, when the selected ee component is changed
    if (filteredSWMOptionsList?.length) {
      setSelectedSWM(filteredSWMOptionsList[0]);
    }
  }, [eeSwmDetails]);

  useEffect(() => {
    // Get Selected Software Module Details
    let filteredEESwmDetails = eeSwmDetails?.find(
      (el) => el?.swm_id == selectedSWM?.value
    );
    setSelectedSWMDetails(filteredEESwmDetails);
  }, [selectedSWM]);

  return (
    // New Request
    <Paper elevation={4} sx={styles.nrContainer}>
      {/* Module Access Types */}
      <ModuleAccessTypes
        selectedProjectDetails={selectedProjectDetails}
        selectedPaBtn={selectedPaBtn}
        setSelectedPaBtn={setSelectedPaBtn}
        moduleTypes={moduleTypes}
      />

      {/* Request access */}
      <Paper elevation={4} sx={styles.nrMainContainer}>
        {/* Project Details */}
        <ProjectDetails
          selectedProjectDetails={selectedProjectDetails}
          projectStatus={projectStatus}
        />

        {/* Variant Details */}
        <VariantDetailsBox selectedVariantDetails={selectedVariantDetails} />

        {/* Select Zone/EE Component/Software Module */}
        <SelectModules
          selectedProjectDetails={selectedProjectDetails}
          selectedPaBtn={selectedPaBtn}
          moduleTypes={moduleTypes}
          variantOptionsList={variantOptionsList}
          selectedVariant={selectedVariant}
          setSelectedVariant={setSelectedVariant}
          vehicleOptionsList={vehicleOptionsList}
          selectedVehicle={selectedVehicle}
          setSelectedVehicle={setSelectedVehicle}
          zoneOptionsList={zoneOptionsList}
          selectedZone={selectedZone}
          setSelectedZone={setSelectedZone}
          eeOptionsList={eeOptionsList}
          selectedEE={selectedEE}
          setSelectedEE={setSelectedEE}
          swmOptionsList={swmOptionsList}
          selectedSWM={selectedSWM}
          setSelectedSWM={setSelectedSWM}
        />

        {/* Vehicle Component Details */}
        {selectedPaBtn === moduleTypes?.VEHICLE && selectedVehicle && (
          <VehicleDetailsBox selectedVehicleDetails={selectedVehicleDetails} />
        )}

        {/* EE Component Details */}
        {selectedPaBtn === moduleTypes?.EE && selectedEE && (
          <EEDetails selectedEEDetails={selectedEEDetails} />
        )}

        {/* Software Module Details */}
        {selectedPaBtn === moduleTypes?.SWM && selectedSWM && (
          <SWMDetails selectedSWMDetails={selectedSWMDetails} />
        )}

        {/* Access type */}
        <Box sx={styles.acATContainer}>
          <Box sx={styles.acATbContainer}>
            <CustomButton
              endIcon={<KeyboardDoubleArrowRightIcon />}
              size={"small"}
              disabled={
                // Project Access Validation
                (selectedPaBtn === moduleTypes?.PROJECT &&
                  !selectedProjectDetails?.project_id) ||
                // Variant Validation
                (selectedPaBtn === moduleTypes?.VARIANT &&
                  (!selectedProjectDetails?.project_id ||
                    !selectedVariant?.value)) ||
                // Vehicle Component Validation
                (selectedPaBtn === moduleTypes?.VEHICLE &&
                  (!selectedProjectDetails?.project_id ||
                    !selectedVariant?.value ||
                    !selectedVehicle?.value)) ||
                // Zone Access Validation
                (selectedPaBtn === moduleTypes?.ZONE &&
                  (!selectedProjectDetails?.project_id ||
                    !selectedVariant?.value ||
                    !selectedZone?.value)) ||
                // EE Component Access Validation
                (selectedPaBtn === moduleTypes?.EE &&
                  (!selectedProjectDetails?.project_id ||
                    !selectedVariant?.value ||
                    !selectedEEDetails?.ee_id)) ||
                // Software Module Access Validation
                (selectedPaBtn === moduleTypes?.SWM &&
                  (!selectedProjectDetails?.project_id ||
                    !selectedVariant?.value ||
                    !selectedEEDetails?.ee_id ||
                    !selectedSWMDetails?.swm_id))
              }
              btnName={"Access Details"}
              handleOnClick={() =>
                handleAccessBtnClick({
                  access_type:
                    selectedPaBtn === moduleTypes?.PROJECT
                      ? moduleTypes?.PROJECT
                      : selectedPaBtn === moduleTypes?.VARIANT
                      ? moduleTypes?.VARIANT
                      : selectedPaBtn === moduleTypes?.VEHICLE
                      ? moduleTypes?.VEHICLE
                      : selectedPaBtn === moduleTypes?.ZONE
                      ? moduleTypes?.ZONE
                      : selectedPaBtn === moduleTypes?.EE
                      ? moduleTypes?.EE
                      : moduleTypes?.SWM,
                  project_id: selectedProjectDetails?.project_id,
                  variant_id: selectedVariantDetails?.variant_id,
                  vehicle_id: selectedVehicleDetails?.id,
                  zone_position: selectedZoneDetails?.zone?.position,
                  ee_id: selectedEEDetails?.ee_id,
                  swm_id: selectedSWMDetails?.swm_id,
                })
              }
              // btnStyle={styles.customButton}
            />
          </Box>
        </Box>
      </Paper>

      {isAccessDrawerOpened && (
        <ProjectAccessDrawer
          open={isAccessDrawerOpened}
          handleClose={handleCloseDrawer}
          data={accessControlData}
        />
      )}
    </Paper>
  );
}

export default AccessControlTab;

// Styles
const styles = {
  nrContainer: {
    width: "100%",
    display: "flex",
    p: 2,
    mt: 3,
    border: "1px solid grey",
  },
  nrMainContainer: {
    width: "100%",
    border: "1px solid grey",
    ml: 3,
    px: 2,
    pt: 0.6,
    pb: 2,
  },
  acATContainer: {
    mt: 4,
    mb: 0.4,
  },
  acATbContainer: {},
};
