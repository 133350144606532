/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 20-07-2024
 * @description : Custom add dialog component.
 */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import appTheme from "../../assets/AppTheme/appTheme";
import DraftsIcon from "@mui/icons-material/Drafts";

const CustomAddDialog = ({
  open,
  onClose,
  dialogTitle,
  dialogContent,
  onConfirm,
  onDisabled,
  onDisabledDraft,
  onConfirmDraft,
}) => {
  return (
    <Dialog
      open={open}
      // onClose={onClose}
      // fullWidth
      // maxWidth={"xs"}
    >
      <DialogTitle sx={{ boxShadow: 3 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography fontWeight={600} variant="h6">
            {dialogTitle}
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent
        dividers
        sx={
          {
            //   px: 4,
          }
        }
      >
        {dialogContent}
      </DialogContent>
      <DialogActions sx={{ boxShadow: 3 }}>
        {dialogTitle === "Add EE Component" ||
        dialogTitle === "Add SW Module" ? (
          <Button
            color="error"
            variant="contained"
            disabled={onDisabledDraft}
            onClick={onConfirmDraft}
            startIcon={<DraftsIcon />}
            sx={{ ...appTheme.commonBtnStyle }}
          >
            Save As Draft
          </Button>
        ) : null}

        <Button
          variant="contained"
          onClick={onConfirm}
          startIcon={<AddIcon />}
          disabled={onDisabled}
          sx={{ ...appTheme.commonBtnStyle }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomAddDialog;
