import React, { useEffect } from "react";
import MultiSelectTextFieldNVs from "./MultiSelectTextFieldNVs";
import { Box } from "@mui/material";
import MultiSelectUsers from "./MultiSelectUsers";
import { useDispatch } from "react-redux";
import { HandleApiActions, userManagementApis } from "../../redux/actions";

function CustomUserDetailsMultiSelect({
  selectedVehicleComponentIds,
  UserDetails,
  selectOEMUsers,
  setSelectOEMUsers,
  selectTechUsers,
  setSelectTechUsers,
  selectSupplierUsers,
  setSelectSupplierUsers,
  selectedMainEEDetails,
  legalRequirementsList,
}) {
  console.log("selectedMainEEDetails", selectedMainEEDetails);
  console.log(
    "UserDetailsUserDetailsUserDetails",
    UserDetails?.usersList?.filter((oem) =>
      selectedMainEEDetails?.co_owners
        ?.concat(selectedMainEEDetails?.owner_details?.user_id)
        ?.includes(oem?.user_id)
    )
  );

  return (
    <div>
      {[
        {
          key: "oem_details",
          title: "Select TA requestors",
          selectedValues: selectOEMUsers,
          setSelectedValues: setSelectOEMUsers,
          details:
            UserDetails?.usersList?.length > 0
              ? UserDetails?.usersList
                  ?.filter((oem) => {
                    console.log("OEM USERS", oem);
                    const owners =
                      selectedMainEEDetails?.owner_details?.map(
                        (owner) => owner.user_id
                      ) || [];
                    const coOwners =
                      selectedMainEEDetails?.co_owner_details?.map(
                        (coOwner) => coOwner.user_id
                      ) || [];

                    const allowedUserIds = [...owners, ...coOwners];

                    return (
                      allowedUserIds.includes(oem?.user_id) &&
                      oem?.role_details?.role_type === 0
                    );
                  })
                  ?.map((details) => ({
                    value: details?.user_id,
                    label: details?.fullname,
                  }))
              : [],
        },
        {
          key: "tech_service_users",
          title: "Select TA reviewers",
          selectedValues: selectTechUsers,
          setSelectedValues: setSelectTechUsers,
          details:
            legalRequirementsList?.length > 0
              ? legalRequirementsList[0]?.tech_service_users?.map((user) => ({
                  value: user.user_id,
                  label: user.name,
                }))
              : [],
        },
        {
          key: "supplier_users",
          title: "Select Suppliers Users",
          selectedValues: selectSupplierUsers,
          setSelectedValues: setSelectSupplierUsers,
          details:
            UserDetails?.usersList?.length > 0
              ? UserDetails?.usersList
                  ?.filter((edit) => {
                    const coOwners =
                      selectedMainEEDetails?.co_owner_details?.map(
                        (coOwner) => coOwner.user_id
                      ) || [];
                    const editUsers =
                      selectedMainEEDetails?.edit_user_details?.map(
                        (editUser) => editUser.user_id
                      ) || [];
                    const viewUsers =
                      selectedMainEEDetails?.view_user_details?.map(
                        (viewUser) => viewUser.user_id
                      ) || [];

                    const allowedUserIds = [
                      ...coOwners,
                      ...editUsers,
                      ...viewUsers,
                    ];

                    return (
                      allowedUserIds.includes(edit?.user_id) &&
                      edit?.role_details?.role_type === 2
                    );
                  })
                  ?.map((details) => ({
                    value: details?.user_id,
                    label: details?.fullname,
                  }))
              : [],
        },
      ]?.map((data) => {
        return (
          <Box sx={{ mt: "10px" }}>
            <MultiSelectUsers
              required
              optionsList={data?.details}
              label={data?.title}
              selectedValues={data?.selectedValues}
              setSelectedValues={data?.setSelectedValues}
            />
          </Box>
        );
      })}
    </div>
  );
}

export default CustomUserDetailsMultiSelect;
