import { Box, Chip, Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import {
  adminSettingsAPI,
  HandleApiActions,
  projectConfigAPI,
} from "../../../redux/actions";
import UpdateVehicleStatus from "../UpdateVehicleStatus";
import { ThemeProvider } from "@emotion/react";
import { createTheme, CssBaseline } from "@mui/material";
import { MuiColorInput } from "mui-color-input";
import SaveIcon from "@mui/icons-material/Save";

const theme = createTheme();

function VariantDetails({ selectedVariantData, projectId }) {
  const dispatch = useDispatch();
  //Redux
  const vehicleStatusDetails = useSelector(
    (state) => state.vehicleStatusReducer
  );

  //1.useState
  const [openVehicleStatus, setOpenVehicleStatus] = useState(false);
  const [color, setColor] = useState("#000000");

  useEffect(() => {
    dispatch(
      HandleApiActions({
        ...adminSettingsAPI.get_vehicle_status,
        shallowEqual: false,
      })
    )?.then(
      dispatch(
        HandleApiActions({
          ...projectConfigAPI?.get_variant_details,
          params: {
            project_id: selectedVariantData?.project_id,

            is_complete_details_required: true,
          },
        })
      )
    );
  }, [openVehicleStatus]);

  const findVehicleStatus = useMemo(() => {
    if (vehicleStatusDetails?.vehicleStatusDetails?.data?.length > 0) {
      return vehicleStatusDetails?.vehicleStatusDetails?.data?.find(
        (vs) =>
          vs?.name?.toUpperCase() ===
          selectedVariantData?.vehicle_status?.toUpperCase()
      );
    }
    return null;
  }, [vehicleStatusDetails, selectedVariantData]);

  const rgbToHex = (rgb) => {
    // Extract the numeric values from the rgb string
    const match = rgb.match(/\d+/g);
    if (!match) return "#000000";

    const [r, g, b] = match.map(Number);
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
  };

  const handleChange = (newColor) => {
    if (newColor.startsWith("rgb")) {
      const hexColor = rgbToHex(newColor);
      setColor(hexColor);
    } else {
      setColor(newColor);
    }
  };

  const [isEditingColor, setIsEditingColor] = React.useState(false);

  const handleSaveColor = () => {
    setIsEditingColor(false);
    dispatch(
      HandleApiActions({
        ...projectConfigAPI.update_variant_color,
        params: {
          project_id: projectId,
          variant_id: selectedVariantData?.variant_id,
          color_code: color,
        },
      })
    );
  };

  return (
    <div>
      <Grid container spacing={2} sx={{ mt: "10px" }}>
        {/* First grid with 3 items */}
        <Grid item xs={12} md={4} sm={12}>
          {[
            {
              title: "Name",
              value: selectedVariantData?.name,
            },
            {
              title: "Vehicle Status",
              value: selectedVariantData?.vehicle_status,
            },
          ]?.map((item, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "8px 16px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ fontWeight: "bold", color: "black" }}>
                  {item?.title}
                </Typography>
                <strong>:</strong>

                <Box>
                  {item?.title === "Vehicle Status" ? (
                    <Box sx={styles.dataStatus}>
                      {findVehicleStatus?.is_edit_allowed === true ? (
                        <Chip
                          sx={{
                            boxShadow: "3",
                            fontWeight: "bold",
                            borderRadius: "0px 15px 0px 15px",
                            backgroundColor: "#c8e8fa",
                            color: "black",
                          }}
                          label={selectedVariantData?.vehicle_status}
                          size="medium"
                        />
                      ) : (
                        <Chip
                          sx={{
                            boxShadow: "3",
                            fontWeight: "bold",
                            borderRadius: "0px 15px 0px 15px",
                            backgroundColor: "#ed6653",
                            color: "white",
                          }}
                          label={selectedVariantData?.vehicle_status}
                          size="medium"
                        />
                      )}
                      <IconButton
                        sx={{ color: "blue" }}
                        size="small"
                        onClick={() => setOpenVehicleStatus(true)}
                      >
                        <EditIcon sx={styles.editIcon} />
                      </IconButton>
                    </Box>
                  ) : (
                    <Typography sx={{ color: "black" }}>
                      {item?.value}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          ))}
        </Grid>

        {/* Second grid with 3 items */}
        <Grid item xs={12} md={4} sm={12}>
          {[
            {
              title: "Delivery Market",
              value: selectedVariantData?.delivery_market,
            },
            {
              title: "Power Train Class",
              value: `${selectedVariantData?.powertrain_class_details?.[0]?.name} - ${selectedVariantData?.powertrain_class_details?.[0]?.abbreviation}`,
            },
          ].map((item, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "8px 16px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ fontWeight: "bold", color: "black" }}>
                  {item?.title}
                </Typography>
                <strong>:</strong>
                <Typography sx={{ color: "black" }}>{item?.value}</Typography>
              </Box>
            </Box>
          ))}
        </Grid>

        <Grid item xs={12} md={4} sm={13}>
          {[
            {
              title: "Manufacturer Name",
              value: selectedVariantData?.mft_details?.name,
            },
            {
              title: "Manufacturer Address",
              value: selectedVariantData?.mft_details?.address,
            },
          ].map((item, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "8px 16px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ fontWeight: "bold", color: "black" }}>
                  {item?.title}
                </Typography>
                <strong>:</strong>
                <Typography sx={{ color: "black" }}>{item?.value}</Typography>
              </Box>
            </Box>
          ))}
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          {[
            { title: "Variant Color", value: selectedVariantData?.color_code },
          ].map((item, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "8px 16px",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Typography sx={{ fontWeight: "bold", color: "black" }}>
                  {item.title}
                </Typography>
                <strong>:</strong>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  {isEditingColor ? (
                    <ThemeProvider theme={theme}>
                      <CssBaseline />
                      <MuiColorInput
                        value={item.value}
                        onChange={handleChange}
                        sx={{
                          display: "inline-block",
                          width: "50px",
                          "& .MuiOutlinedInput-root": {
                            border: "none",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        }}
                        disableTextfield
                      />
                      <IconButton
                        sx={{ color: "green" }}
                        size="small"
                        onClick={handleSaveColor}
                      >
                        <SaveIcon />
                      </IconButton>
                    </ThemeProvider>
                  ) : (
                    <>
                      <Chip
                        sx={{
                          boxShadow: "3",
                          fontWeight: "bold",
                          borderRadius: "5px 5px 5px 5px",
                          backgroundColor: item.value || "#ffffff",
                          color: "#000",
                        }}
                        size="large"
                      />
                      <IconButton
                        sx={{ color: "blue" }}
                        size="small"
                        onClick={() => setIsEditingColor(true)}
                      >
                        <EditIcon />
                      </IconButton>
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          ))}
        </Grid>
      </Grid>
      {openVehicleStatus && (
        <UpdateVehicleStatus
          openVehicleStatus={openVehicleStatus}
          setOpenVehicleStatus={setOpenVehicleStatus}
          boxes={selectedVariantData}
        />
      )}
    </div>
  );
}

export default VariantDetails;

const styles = {};
