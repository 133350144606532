/**
 * @author Tahir Shaik
 * @date 23/08/2024
 * @description
 */
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import appTheme from "../../../assets/AppTheme/appTheme";
import moment from "moment";
import React, { useEffect, useState } from "react";
import sampleJson from "./sample.json";
import { DataGrid } from "@mui/x-data-grid";
import DependenciesDialog from "./DependenciesDialog";
import { HandleApiActions } from "../../../redux/actions";
import typeApprovalAPI from "../../../redux/actions/typeApprovalAPI";
import { useDispatch } from "react-redux";
import { CustomButton, ViewTemplateDialog } from "../../../components";
import IntersDelTicket from "./IntersDelTicket";
import EEDependenciesDialog from "./EEDependenciesDialog";
function Intersections({
  intersectionDetailsList,
  setIntersectionDetailsList,
  isSWMToLegalIntersection,
  isVehicleToLegalIntersection,
  handleTabChange,
  tabValue,
  setTabValue,
  handleSaveDraft,
}) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [dependencies, setDependencies] = useState({});
  const [existingPrDetails, setExistingPrDetails] = useState([]);
  const [existingECUs, setExistingECUs] = useState([]);
  const [existingSWMs, setExistingSWMs] = useState([]);
  const [existingVehicle, setExistingVehicle] = useState([]);
  const [existingLS, setExistingLS] = useState([]);
  const [existingLSCh, setExistingLSCh] = useState([]);
  const [existingLR, setExistingLR] = useState([]);
  const [selectedECU, setSelectedECU] = useState(0);
  const [selectedSWM, setSelectedSWM] = useState(0);
  const [selectedVEHICLE, setSelectedVEHICLE] = useState(0);
  const [selectedLS, setSelectedLS] = useState(0);
  const [selectedLSCh, setSelectedLSCh] = useState("");
  const [selectedLR, setSelectedLR] = useState(0);
  const [existingData, setExistingData] = useState([]);
  const [requestObj, setRequestObj] = useState({});
  const [draftRequestObj, setDraftRequestObj] = useState({});
  const [requestDraftObj, setRequestDraftObj] = useState({});

  // Dynamic form data
  const [dynamicFormData, setDynamicFormData] = useState(null);
  // Template dialog states
  const [isViewTemplateDialogOpen, setIsViewTemplateDialogOpen] =
    useState(false);

  /* Handle Template Dialog Function */
  const handleViewTemplateDialog = (confirm) => {
    if (confirm) {
    }

    setIsViewTemplateDialogOpen(false);
  };

  /**
   * @params {selectedItem:1, setSelectedItem(), data:{details:"", id:""}}
   */
  const CustomDropDown = (props) => {
    // console.log("-- CustomDropDown --");
    // console.log(props);
    return (
      <FormControl>
        <InputLabel
          sx={{
            fontSize: "16px",
            fontWeight: "bold",
            marginBottom: "10px",
          }}
        >
          {props.header}
        </InputLabel>
        <Select
          variant="standard"
          value={
            props.selectedItem !== undefined
              ? props.selectedItem
              : props.data[0].id
          }
          onChange={(e) => {
            if (props.setSelectedItem) {
              props.setSelectedItem(e.target.value);
            }
          }}
          sx={{
            minWidth: "200px",
            textAlign: "start",
            ...props.styles,
          }}
        >
          {props.data.map((req, idx) => {
            return (
              <MenuItem
                key={idx}
                value={req.id}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                {props.is_data_object ? (
                  <Typography sx={{ fontSize: "14px" }}>
                    {req.details.fullname}
                    <br /> {req.details.email}
                    <br />
                    {req.details.role_name}
                    <br /> {req.details.department}
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      fontSize: "14px",
                      maxWidth: "200px",
                      overflow: "auto",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {req.details}
                  </Typography>
                )}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  const handleRemoveFilters = () => {
    setSelectedECU(0);
    setSelectedSWM(0);
    setSelectedVEHICLE(0);
    setSelectedLS(0);
    setSelectedLSCh(0);
    setSelectedLR(0);
  };

  useEffect(() => {
    if (tabValue === 0) {
      setExistingData([]);
      setExistingPrDetails([]);
      setExistingECUs([]);
      setExistingSWMs([]);
      setExistingVehicle([]);
      setExistingLS([]);
      setExistingLSCh([]);
      setExistingLR([]);
      setRequestObj({});
      setDraftRequestObj({});
      setRequestDraftObj({});
      dispatch(
        HandleApiActions({
          type: typeApprovalAPI.get_add_intersection.action_type,
          payload: { error: true, msg: "", data: { intersections: [] } },
        })
      );
    }
  }, [handleTabChange]);

  // for (const data of intersectionDetailsList) {

  //   // Push unique project details
  //   if (
  //     prDetails.findIndex(
  //       (e) =>
  //         parseInt(e.project_id) ===
  //         parseInt(data.project_details.project_id)
  //     ) === -1
  //   ) {
  //     prDetails.push(data.project_details);
  //   }

  //   // Push unique EE components
  //   if (
  //     ecus.findIndex(
  //       (e) => parseInt(e.ee_id) === parseInt(data?.ee_details.ee_id)
  //     ) === -1
  //   ) {
  //     ecus.push(data.ee_details);
  //   }

  //   // Push unique SWM details
  //   if (
  //     swms?.findIndex(
  //       (s) => parseInt(s?.swm_id) === parseInt(data?.swm_details?.swm_id)
  //     ) === -1
  //   ) {
  //     swms?.push(data?.swm_details);
  //   }

  //   // Push unique vehicle components
  //   vehicleComponents.forEach((vehicleComponent) => {
  //   if (
  //     vehicle?.findIndex(
  //         (v) => parseInt(v?.id) === parseInt(vehicleComponent?.id)
  //     ) === -1
  //   ) {
  //       vehicle?.push(vehicleComponent);
  //   }
  //   });

  //   // Push unique LS details
  //   if (
  //     lss.findIndex(
  //       (l) => parseInt(l.ls_id) === parseInt(data.ls_details.ls_id)
  //     ) === -1
  //   ) {
  //     lss.push(data.ls_details);
  //   }

  //   // Push unique LR details
  //   if (
  //     lrs.findIndex(
  //       (r) => parseInt(r.lr_id) === parseInt(data.lr_details.lr_id)
  //     ) === -1
  //   ) {
  //     lrs.push(data.lr_details);
  //   }
  // }

  //   Use effect to execute on component mount
  useEffect(() => {
    console.log("intersectionDetailsList", intersectionDetailsList);
    if (
      intersectionDetailsList &&
      // !sampleJson.error &&
      // Object.keys(sampleJson).length &&
      intersectionDetailsList.length
    ) {
      const prDetails = [];
      const ecus = [];
      const swms = [];
      const vehicle = [];
      const lss = [];
      const lrs = [];
      let intrscts = [];

      for (const data of intersectionDetailsList) {
        // Push unique project details
        if (
          prDetails.findIndex(
            (e) =>
              parseInt(e.project_id) ===
              parseInt(data.project_details.project_id)
          ) === -1
        ) {
          prDetails.push(data.project_details);
        }

        // Push unique EE components
        if (
          ecus.findIndex(
            (e) => parseInt(e.ee_id) === parseInt(data.ee_details.ee_id)
          ) === -1
        ) {
          ecus.push(data.ee_details);
        }

        // Push unique SWM details
        if (
          swms.findIndex(
            (s) => parseInt(s.swm_id) === parseInt(data.swm_details.swm_id)
          ) === -1
        ) {
          swms.push(data.swm_details);
        }

        // Push unique LS details
        if (
          lss.findIndex(
            (l) => parseInt(l.ls_id) === parseInt(data.ls_details.ls_id)
          ) === -1
        ) {
          lss.push(data.ls_details);
        }

        // Push unique LR details
        if (
          lrs.findIndex(
            (r) => parseInt(r.lr_id) === parseInt(data.lr_details.lr_id)
          ) === -1
        ) {
          lrs.push(data.lr_details);
        }
      }
      setExistingPrDetails(prDetails);
      setExistingECUs(ecus);
      setExistingSWMs(swms);
      setExistingVehicle(vehicle);
      setExistingLS(lss);
      setExistingLR(lrs);
      setExistingData(intersectionDetailsList);
    }
  }, [intersectionDetailsList]);

  const groupDataAndFormatPayloadRaiseTicket = (detailsList) => {
    const payloads = {
      vehicleComponents: [],
      eeComponents: [],
      softwareModules: [],
    };

    for (const data of detailsList) {
      const vehicleComponents = data?.vehicle_component_details
        ? JSON?.parse(data?.vehicle_component_details)
        : [];

      // Vehicle Components Payload
      if (vehicleComponents?.length) {
        payloads?.vehicleComponents?.push({
          project_id: parseInt(data?.project_details?.project_id),
          variant_id: parseInt(data?.variant_details?.[0]?.variant_id),
          intersections: [
            {
              vc_ids: vehicleComponents?.map((vc) => vc?.id),
              lr_id: parseInt(data?.lr_details?.lr_id),
              description: data?.description,
              ...(data?.dynamic_form &&
                data?.dynamic_form?.headers?.length &&
                data?.dynamic_form?.signatures?.length &&
                data?.dynamic_form?.footers?.length && {
                  dynamic_form: {
                    ...data?.dynamic_form,
                    fields: JSON?.parse(data?.dynamic_form?.fields),
                    headers: JSON?.parse(data?.dynamic_form?.headers),
                    footers: JSON?.parse(data?.dynamic_form?.footers),
                    signatures: JSON?.parse(data?.dynamic_form?.signatures),
                  },
                }),
              oem_users: data?.oem_users?.map((users) => users?.user_id),
              tech_service_users: data?.tech_service_users?.map(
                (users) => users?.user_id
              ),
              suppliers: data?.suppliers?.map((users) => users?.user_id),
            },
          ],
          save_as_draft: false,
          create_intersections: true,
        });
      }

      // EE Components Payload
      if (data?.ee_details?.ee_id && !data?.swm_details?.swm_id) {
        payloads?.eeComponents?.push({
          project_id: parseInt(data?.project_details?.project_id),
          variant_id: parseInt(data?.variant_details?.[0]?.variant_id),
          intersections: [
            {
              ee_id: parseInt(data?.ee_details?.ee_id),
              legal: [
                {
                  lr_id: parseInt(data?.lr_details?.lr_id),
                  description: data?.description,
                  ...(data?.dynamic_form &&
                    data?.dynamic_form?.headers?.length &&
                    data?.dynamic_form?.signatures?.length &&
                    data?.dynamic_form?.footers?.length && {
                      dynamic_form: {
                        ...data?.dynamic_form,
                        fields: JSON?.parse(data?.dynamic_form?.fields),
                        headers: JSON?.parse(data?.dynamic_form?.headers),
                        footers: JSON?.parse(data?.dynamic_form?.footers),
                        signatures: JSON?.parse(data?.dynamic_form?.signatures),
                      },
                    }),
                  oem_users: data?.oem_users?.map((users) => users?.user_id),
                  tech_service_users: data?.tech_service_users?.map(
                    (users) => users?.user_id
                  ),
                  suppliers: data?.suppliers?.map((users) => users?.user_id),
                },
              ],
            },
          ],
          is_save_draft: false,
          current_time: new Date().toISOString(),
        });
      }

      // Software Modules Payload
      if (data?.swm_details?.swm_id) {
        payloads?.softwareModules?.push({
          project_id: parseInt(data?.project_details?.project_id),
          variant_id: parseInt(data?.variant_details?.[0]?.variant_id),
          intersections: [
            {
              ee_id: parseInt(data?.ee_details?.ee_id),
              swm_id: parseInt(data?.swm_details?.swm_id),
              legal: [
                {
                  lr_id: parseInt(data?.lr_details?.lr_id),
                  description: data?.description,
                  ...(data?.dynamic_form &&
                    data?.dynamic_form?.headers?.length &&
                    data?.dynamic_form?.signatures?.length &&
                    data?.dynamic_form?.footers?.length && {
                      dynamic_form: {
                        ...data?.dynamic_form,
                        fields: JSON?.parse(data?.dynamic_form?.fields),
                        headers: JSON?.parse(data?.dynamic_form?.headers),
                        footers: JSON?.parse(data?.dynamic_form?.footers),
                        signatures: JSON?.parse(data?.dynamic_form?.signatures),
                      },
                    }),
                  oem_users: data?.oem_users?.map((users) => users?.user_id),
                  tech_service_users: data?.tech_service_users?.map(
                    (users) => users?.user_id
                  ),
                  suppliers: data?.suppliers?.map((users) => users?.user_id),
                },
              ],
            },
          ],
          is_save_draft: false,
          current_time: new Date().toISOString(),
        });
      }
    }

    return payloads;
  };

  useEffect(() => {
    if (intersectionDetailsList?.length) {
      const formattedPayloads = groupDataAndFormatPayloadRaiseTicket(
        intersectionDetailsList
      );

      setRequestObj((prev) => ({
        ...prev,
        vehicleComponents: formattedPayloads.vehicleComponents,
        eeComponents: formattedPayloads.eeComponents,
        softwareModules: formattedPayloads.softwareModules,
      }));
    }
  }, [intersectionDetailsList]);

  const groupDataAndFormatPayloadDraftTicket = (detailsList) => {
    const payloads = {
      vehicleComponents: [],
      eeComponents: [],
      softwareModules: [],
    };

    for (const data of detailsList) {
      const vehicleComponents = data?.vehicle_component_details
        ? JSON?.parse(data?.vehicle_component_details)
        : [];

      // Vehicle Components Payload
      if (vehicleComponents?.length) {
        payloads?.vehicleComponents?.push({
          project_id: parseInt(data?.project_details?.project_id),
          variant_id: parseInt(data?.variant_details?.[0]?.variant_id),
          intersections: [
            {
              vc_ids: vehicleComponents?.map((vc) => vc?.id),
              lr_id: parseInt(data?.lr_details?.lr_id),
              description: data?.description,
              ...(data?.dynamic_form &&
                data?.dynamic_form?.headers?.length &&
                data?.dynamic_form?.signatures?.length &&
                data?.dynamic_form?.footers?.length && {
                  dynamic_form: {
                    ...data?.dynamic_form,
                    fields: JSON?.parse(data?.dynamic_form?.fields),
                    headers: JSON?.parse(data?.dynamic_form?.headers),
                    footers: JSON?.parse(data?.dynamic_form?.footers),
                    signatures: JSON?.parse(data?.dynamic_form?.signatures),
                  },
                }),
              oem_users: data?.oem_users?.map((users) => users?.user_id),
              tech_service_users: data?.tech_service_users?.map(
                (users) => users?.user_id
              ),
              suppliers: data?.suppliers?.map((users) => users?.user_id),
            },
          ],
          save_as_draft: true,
          create_intersections: true,
        });
      }

      // EE Components Payload
      if (data?.ee_details?.ee_id && !data?.swm_details?.swm_id) {
        payloads?.eeComponents?.push({
          project_id: parseInt(data?.project_details?.project_id),
          variant_id: parseInt(data?.variant_details?.[0]?.variant_id),
          intersections: [
            {
              ee_id: parseInt(data?.ee_details?.ee_id),
              legal: [
                {
                  lr_id: parseInt(data?.lr_details?.lr_id),
                  description: data?.description,
                  ...(data?.dynamic_form &&
                    data?.dynamic_form?.headers?.length &&
                    data?.dynamic_form?.signatures?.length &&
                    data?.dynamic_form?.footers?.length && {
                      dynamic_form: {
                        ...data?.dynamic_form,
                        fields: JSON?.parse(data?.dynamic_form?.fields),
                        headers: JSON?.parse(data?.dynamic_form?.headers),
                        footers: JSON?.parse(data?.dynamic_form?.footers),
                        signatures: JSON?.parse(data?.dynamic_form?.signatures),
                      },
                    }),
                  oem_users: data?.oem_users?.map((users) => users?.user_id),
                  tech_service_users: data?.tech_service_users?.map(
                    (users) => users?.user_id
                  ),
                  suppliers: data?.suppliers?.map((users) => users?.user_id),
                },
              ],
            },
          ],
          is_save_draft: true,
          current_time: new Date().toISOString(),
        });
      }

      // Software Modules Payload
      if (data?.swm_details?.swm_id) {
        payloads?.softwareModules?.push({
          project_id: parseInt(data?.project_details?.project_id),
          variant_id: parseInt(data?.variant_details?.[0]?.variant_id),
          intersections: [
            {
              ee_id: parseInt(data?.ee_details?.ee_id),
              swm_id: parseInt(data?.swm_details?.swm_id),
              legal: [
                {
                  lr_id: parseInt(data?.lr_details?.lr_id),
                  description: data?.description,
                  ...(data?.dynamic_form &&
                    data?.dynamic_form?.headers?.length &&
                    data?.dynamic_form?.signatures?.length &&
                    data?.dynamic_form?.footers?.length && {
                      dynamic_form: {
                        ...data?.dynamic_form,
                        fields: JSON?.parse(data?.dynamic_form?.fields),
                        headers: JSON?.parse(data?.dynamic_form?.headers),
                        footers: JSON?.parse(data?.dynamic_form?.footers),
                        signatures: JSON?.parse(data?.dynamic_form?.signatures),
                      },
                    }),
                  oem_users: data?.oem_users?.map((users) => users?.user_id),
                  tech_service_users: data?.tech_service_users?.map(
                    (users) => users?.user_id
                  ),
                  suppliers: data?.suppliers?.map((users) => users?.user_id),
                },
              ],
            },
          ],
          is_save_draft: true,
          current_time: new Date().toISOString(),
        });
      }
    }

    return payloads;
  };

  useEffect(() => {
    if (intersectionDetailsList?.length) {
      const formattedPayloads = groupDataAndFormatPayloadDraftTicket(
        intersectionDetailsList
      );

      setDraftRequestObj((prev) => ({
        ...prev,
        vehicleComponents: formattedPayloads.vehicleComponents,
        eeComponents: formattedPayloads.eeComponents,
        softwareModules: formattedPayloads.softwareModules,
      }));
    }
  }, [intersectionDetailsList]);

  useEffect(() => {
    setSelectedSWM(0);
    setSelectedLS(0);
    setSelectedLR(0);
  }, [selectedECU]);
  useEffect(() => {
    setSelectedLS(0);
    setSelectedLR(0);
  }, [selectedSWM]);
  useEffect(() => {
    setSelectedLR(0);
  }, [selectedLS]);
  // useEffect(() => {
  //   // When the selected legal standard is changed, change the selected chapters to ""
  //   setSelectedLSCh("");

  //   // When the selected legal standard is changed, change the legal standard chapters list
  //   let filteredLSCh = existingLS?.find((ls) => ls?.ls_id == selectedLS);
  //   setExistingLSCh(filteredLSCh?.chapters || []);
  // }, [selectedLS]);
  useEffect(() => {
    console.log("existingLS", existingLS);
    let filteredLSCh = existingLR?.filter(
      (item) => parseInt(item?.ls_id) === selectedLS
    );

    console.log("filteredLSCh--", filteredLSCh);
    const result = filteredLSCh?.reduce((acc, { chapter, lr_id }) => {
      if (!acc[chapter]) {
        acc[chapter] = [];
      }
      acc[chapter].push(lr_id);
      return acc;
    }, {});
    console.log("result", result);
    setExistingLSCh(filteredLSCh?.chapter || []);
  }, [selectedLS]);

  useEffect(() => {
    let filteredIntersectionDetailsList = intersectionDetailsList?.filter(
      (el) =>
        (!selectedECU || parseInt(el?.ee_details?.ee_id) === selectedECU) &&
        (!selectedSWM || parseInt(el?.swm_details?.swm_id) === selectedSWM) &&
        (!selectedVEHICLE ||
          parseInt(el?.vehicle_component_details?.id) === selectedVEHICLE) &&
        (!selectedLS || parseInt(el?.ls_details?.ls_id) === selectedLS)
      // &&
      // (!selectedLR || parseInt(el?.lr_details?.lr_id) === selectedLR)
    );
    // console.log(
    //   "filteredIntersectionDetailsList: ",
    //   filteredIntersectionDetailsList
    // );

    setExistingData(filteredIntersectionDetailsList);
  }, [
    selectedECU,
    selectedSWM,
    selectedVEHICLE,
    selectedLS,
    selectedLSCh,
    selectedLR,
  ]);
  useEffect(() => {
    // console.log("intersectionDetailsList: ", intersectionDetailsList);
    // console.log("dependencies: ", dependencies);
    // console.log("requestObj: ", requestObj);
    console.log("existingData: ", existingData);
    // console.log("existingPrDetails: ", existingPrDetails);
    // console.log("existingECUs: ", existingECUs);
    // console.log("existingSWMs: ", existingSWMs);
    // console.log("existingLS: ", existingLS);
    // console.log("existingLSCh: ", existingLSCh);
    // console.log("existingLR: ", existingLR);
    // console.log("selectedECU: ", selectedECU);
    // console.log("selectedSWM: ", selectedSWM);
    // console.log("selectedLS: ", selectedLS);
    // console.log("selectedLSCh: ", selectedLSCh);
    // console.log("selectedLR: ", selectedLR);
  }, [
    intersectionDetailsList,
    dependencies,
    requestObj,
    draftRequestObj,
    requestDraftObj,
    existingData,
    existingPrDetails,
    existingECUs,
    existingSWMs,
    existingVehicle,
    existingLS,
    existingLSCh,
    existingLR,
    selectedECU,
    selectedSWM,
    selectedVEHICLE,
    selectedLS,
    selectedLSCh,
    selectedLR,
  ]);

  // const handleRaiseTickets = async () => {
  //   try {
  //     // Handle request for SWM/EE intersections
  //     if (Object.keys(requestObj).length) {
  //       // Log requestObj to verify payload before sending
  //       console.log("Request Payload for SWM/EE:", requestObj);

  //       // Ensure vehicle components are included in vc_ids for SWM/EE intersections
  //       const updatedIntersections = requestObj.intersections.map((intersection) => {
  //         // Ensure vc_ids is populated with vehicle component IDs if it's empty
  //         if (!intersection.vc_ids || intersection.vc_ids.length === 0) {
  //           intersection.vc_ids = vehicleRequestObj?.vehicleComponents?.map((vc) => vc.id) || [];
  //         }
  //         return intersection;
  //       });

  //       const updatedRequestObj = {
  //         ...requestObj,
  //         intersections: updatedIntersections,
  //       };

  //       const resp = await dispatch(
  //         isSWMToLegalIntersection
  //           ? HandleApiActions({
  //               ...typeApprovalAPI.add_intersection,
  //               params: updatedRequestObj,
  //               show_toast: true,
  //             })
  //           : HandleApiActions({
  //               ...typeApprovalAPI.add_ee_intersection,
  //               params: updatedRequestObj,
  //               show_toast: true,
  //             })
  //       );

  //       if (resp?.error === false) {
  //         console.log("API Success for SWM/EE:", resp);
  //         clearIntersectionData();
  //       } else {
  //         console.error("API Error for SWM/EE:", resp);
  //       }
  //     }

  //     // Handle vehicle component intersections
  //     if (Object.keys(vehicleRequestObj).length) {
  //       // Log vehicleRequestObj to verify payload before sending
  //       console.log("Request Payload for Vehicle:", vehicleRequestObj);

  //       const updatedVehicleRequestObj = {
  //         ...vehicleRequestObj,
  //         intersections: vehicleRequestObj.intersections.map((intersection) => {
  //           // Ensure vehicle component IDs are added to vc_ids if it's empty
  //           if (!intersection.vc_ids || intersection.vc_ids.length === 0) {
  //             intersection.vc_ids = vehicleRequestObj?.vehicleComponents?.map((vc) => vc.id) || [];
  //           }
  //           return intersection;
  //         }),
  //       };

  //       const resp = await dispatch(
  //         HandleApiActions({
  //           ...typeApprovalAPI.get_vehicle_add_intersection,
  //           params: updatedVehicleRequestObj,
  //           show_toast: true,
  //         })
  //       );

  //       if (resp?.error === false) {
  //         console.log("API Success for Vehicle Intersection:", resp);
  //         clearIntersectionData();
  //       } else {
  //         console.error("API Error for Vehicle Intersection:", resp);
  //       }
  //     }

  //     // Switch to Tab 2 after raising tickets
  //     console.log("Switching to Tab 2");
  //     setTabValue(2);
  //   } catch (error) {
  //     console.error("Error in handleRaiseTickets:", error);
  //   }
  // };

  const handleRaiseTickets = async () => {
    try {
      // Handle Vehicle Components
      if (requestObj?.vehicleComponents?.length) {
        for (const payload of requestObj?.vehicleComponents) {
          console.log("Request Payload for Vehicle Components:", payload);

          const resp = await dispatch(
            HandleApiActions({
              ...typeApprovalAPI?.get_vehicle_add_intersection,
              params: payload,
              show_toast: true,
            })
          );

          if (resp?.error === false) {
            console.log("Vehicle API success:", resp);
          } else {
            console.error("Vehicle API error:", resp);
          }
        }
      }

      // Handle EE Components
      if (requestObj?.eeComponents?.length) {
        for (const payload of requestObj?.eeComponents) {
          console.log("Request Payload for EE Components:", payload);

          const resp = await dispatch(
            HandleApiActions({
              ...typeApprovalAPI?.add_ee_intersection,
              params: payload,
              show_toast: true,
            })
          );

          if (resp?.error === false) {
            console.log("EE Components API success:", resp);
          } else {
            console.error("EE Components API error:", resp);
          }
        }
      }

      // Handle Software Modules
      if (requestObj?.softwareModules?.length) {
        for (const payload of requestObj?.softwareModules) {
          console.log("Request Payload for Software Modules:", payload);

          const resp = await dispatch(
            HandleApiActions({
              ...typeApprovalAPI?.add_intersection,
              params: payload,
              show_toast: true,
            })
          );

          if (resp?.error === false) {
            console.log("Software Modules API success:", resp);
          } else {
            console.error("Software Modules API error:", resp);
          }
        }
      }

      // Switch to Tab 2
      setTabValue(2);
      clearIntersectionData();
    } catch (error) {
      console.error("Error in handleRaiseTickets:", error);
    }
  };

  const handleDraftTickets = async () => {
    try {
      // Handle Vehicle Components
      if (draftRequestObj?.vehicleComponents?.length) {
        for (const payload of draftRequestObj?.vehicleComponents) {
          console.log("Request Payload for Vehicle Components:", payload);

          const resp = await dispatch(
            HandleApiActions({
              ...typeApprovalAPI?.get_vehicle_add_intersection,
              params: payload,
              show_toast: true,
            })
          );

          if (resp?.error === false) {
            console.log("Vehicle API success:", resp);
          } else {
            console.error("Vehicle API error:", resp);
          }
        }
      }

      // Handle EE Components
      if (draftRequestObj?.eeComponents?.length) {
        for (const payload of draftRequestObj?.eeComponents) {
          console.log("Request Payload for EE Components:", payload);

          const resp = await dispatch(
            HandleApiActions({
              ...typeApprovalAPI?.add_ee_intersection,
              params: payload,
              show_toast: true,
            })
          );

          if (resp?.error === false) {
            console.log("EE Components API success:", resp);
          } else {
            console.error("EE Components API error:", resp);
          }
        }
      }

      // Handle Software Modules
      if (draftRequestObj?.softwareModules?.length) {
        for (const payload of draftRequestObj?.softwareModules) {
          console.log("Request Payload for Software Modules:", payload);

          const resp = await dispatch(
            HandleApiActions({
              ...typeApprovalAPI?.add_intersection,
              params: payload,
              show_toast: true,
            })
          );

          if (resp?.error === false) {
            console.log("Software Modules API success:", resp);
          } else {
            console.error("Software Modules API error:", resp);
          }
        }
      }

      // Switch to Tab 1
      setTabValue(1);
      clearIntersectionData();
      handleSaveDraft();
    } catch (error) {
      console.error("Error in handleDraftTickets:", error);
    }
  };

  // Utility function to clear intersection-related data
  const clearIntersectionData = () => {
    setIntersectionDetailsList([]);
    setExistingData([]);
    setExistingPrDetails([]);
    setExistingECUs([]);
    setExistingSWMs([]);
    setExistingVehicle([]);
    setExistingLS([]);
    setExistingLSCh([]);
    setExistingLR([]);
    setRequestObj({});
    setDraftRequestObj({});
    dispatch({
      type: typeApprovalAPI.get_add_intersection.action_type,
      payload: { error: true, msg: "", data: { intersections: [] } },
    });
  };

  const handleDisplayDependecies = (details) => {
    setDependencies(details);
    setOpen(true);
  };

  console.log("existingVehicle", existingVehicle);
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1, pb: 7 }}>
      {/* <Box sx={styles.rmFlContainer}>
        <CustomButton
          size={"medium"}
          variant={"contained"}
          btnName={"Clear Filters"}
          handleOnClick={handleRemoveFilters}
          btnStyle={styles.customButton}
        />
      </Box> */}
      <Paper
        // elevation={12}
        elevation={0}
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          flexWrap: "wrap",
          // justifyContent: "space-evenly",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
          py: 2,
          mb: 2,
        }}
      >
        {/* Project details */}
        {/* {existingPrDetails.length ? (
          <Paper
            elevation={12}
            sx={{
              gap: 1,
              padding: "10px",
              height: "170px",
              width: "240px",
              overflow: "auto",
              "&::-webkit-scrollbar": {
                width: "4px",
                height: "3px",
              },
            }}
          >
            <Typography sx={{ fontWeight: "bold", marginBottom: "15px" }}>
              {"PROJECT DETAILS"}
            </Typography>
            {existingPrDetails[0] ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  textAlign: "left",
                  marginTop: 2,
                }}
              >
                <Typography variant="body2">
                  <span style={styles.txtBold}>Name: </span>
                  {existingPrDetails[0]?.name}
                </Typography>
                <Typography variant="body2">
                  <span style={styles.txtBold}>Architecture Name: </span>
                  {existingPrDetails[0]?.architecture_name}
                </Typography>
                <Typography variant="body2">
                  <span style={styles.txtBold}>Vehicle Platform: </span>
                  {existingPrDetails[0]?.vehicle_platform}
                </Typography>
                <Typography variant="body2">
                  <span style={styles.txtBold}>Trade Mark: </span>
                  {existingPrDetails[0]?.trade_mark}
                </Typography>
              </Box>
            ) : (
              ""
            )}
          </Paper>
        ) : undefined} */}
        {/* EE Component details */}
        {/* {existingECUs.length ? (
          <Paper
            elevation={12}
            sx={{
              gap: 1,
              padding: "10px",
              height: "170px",
              width: "240px",
              overflow: "auto",
              "&::-webkit-scrollbar": {
                width: "4px",
                height: "3px",
              },
            }}
          >
            <Typography sx={{ fontWeight: "bold", marginBottom: "15px" }}>
              {"EE COMPONENT"}
            </Typography>
            <CustomDropDown
              header={"ECUs"}
              is_data_object={false}
              selectedItem={selectedECU}
              setSelectedItem={setSelectedECU}
              data={existingECUs.map((ecu) => {
                return { id: parseInt(ecu.ee_id), details: ecu.description };
              })}
            />
            {existingECUs.filter((data) => parseInt(data.ee_id) === selectedECU)
              .length ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 0.4,
                  textAlign: "left",
                  marginTop: 2,
                }}
              >
                <Typography variant="body2">
                  <span style={styles.txtBold}>Version: </span>
                  {
                    existingECUs.filter(
                      (data) => parseInt(data.ee_id) === selectedECU
                    )[0].version
                  }
                </Typography>
                <Typography variant="body2">
                  <span style={styles.txtBold}>Supplier Name: </span>
                  {
                    existingECUs.filter(
                      (data) => parseInt(data.ee_id) === selectedECU
                    )[0].supplier_name
                  }
                </Typography>
                <Typography variant="body2">
                  <span style={styles.txtBold}>Assembly Number: </span>
                  {
                    existingECUs.filter(
                      (data) => parseInt(data.ee_id) === selectedECU
                    )[0].assembly_number
                  }
                </Typography>
              </Box>
            ) : (
              ""
            )}
          </Paper>
        ) : undefined} */}
        {/* SWM Details */}
        {/* {isSWMToLegalIntersection && existingSWMs.length ? (
          <Paper
            elevation={12}
            sx={{
              gap: 1,
              padding: "10px",
              height: "170px",
              width: "240px",
              overflow: "auto",
              "&::-webkit-scrollbar": {
                width: "4px",
                height: "3px",
              },
            }}
          >
            <Typography sx={{ fontWeight: "bold", marginBottom: "15px" }}>
              {"SOFTWARE MODULE"}
            </Typography>
            <CustomDropDown
              header={"SWM"}
              is_data_object={false}
              selectedItem={selectedSWM}
              setSelectedItem={setSelectedSWM}
              data={existingSWMs
                ?.filter((swm) => swm?.ee_id == selectedECU)
                ?.map((swm) => {
                  return { id: parseInt(swm?.swm_id), details: swm?.name };
                })}
            /> */}
        {/* {existingSWMs.filter(
              (data) => parseInt(data.swm_id) === selectedSWM
            ).length ? ( */}
        {/* {existingSWMs?.filter(
              (swm) =>
                parseInt(swm.ee_id) === selectedECU &&
                parseInt(swm?.swm_id) === selectedSWM
            ).length ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 0.4,
                  textAlign: "left",
                  marginTop: 2,
                }}
              >
                <Typography variant="body2">
                  <span style={styles.txtBold}>Version: </span>
                  {
                    existingSWMs?.filter(
                      (data) => parseInt(data?.swm_id) === selectedSWM
                    )[0]?.version
                  }
                </Typography>
                <Typography variant="body2">
                  <span style={styles.txtBold}>Full Name: </span>
                  {
                    existingSWMs.filter(
                      (data) => parseInt(data?.swm_id) === selectedSWM
                    )[0]?.owner_details?.fullname
                  }
                </Typography>
                <Typography variant="body2">
                  <span style={styles.txtBold}>Email: </span>
                  {
                    existingSWMs.filter(
                      (data) => parseInt(data?.swm_id) === selectedSWM
                    )[0]?.owner_details?.email
                  }
                </Typography>
              </Box>
            ) : (
              ""
            )} */}
        {/* </Paper>
        ) : undefined}  */}
        {/* Vehicle Details 
        {existingVehicle?.length ? (
    <Paper
      elevation={12}
      sx={{
        gap: 1,
        padding: "10px",
        height: "170px",
        width: "240px",
        overflow: "auto",
        "&::-webkit-scrollbar": {
          width: "4px",
          height: "3px",
        },
      }}
    >
      <Typography sx={{ fontWeight: "bold", marginBottom: "15px" }}>
        {"VEHICLE COMPONENT"}
      </Typography>

      {existingVehicle.map((vehicle) => (
        <Box
          key={vehicle?.id}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 0.4,
            textAlign: "left",
            marginTop: 2,
          }}
        >
          <Typography variant="body2">
            <span style={styles.txtBold}>Component: </span>
            {vehicle?.component || "N/A"}
          </Typography>
          <Typography variant="body2">
            <span style={styles.txtBold}>Suppliers: </span>
            {vehicle?.suppliers?.length
              ? vehicle?.suppliers.join(", ")
              : "N/A"}
          </Typography>
          <Typography variant="body2">
            <span style={styles.txtBold}>Assembly Number: </span>
            {vehicle?.assembly_number || "N/A"}
          </Typography>
        </Box>
      ))}
    </Paper>
  ) : (
    <Typography variant="body2" sx={{ textAlign: "center", marginTop: 2 }}>
      No Vehicle Components Found.
    </Typography>
  )
}*/}
        {/* Legal Details */}
        {/* {existingLS.length ? (
          <Paper
            elevation={12}
            sx={{
              gap: 1,
              padding: "10px",
              height: "170px",
              width: "240px",
              overflow: "auto",
              "&::-webkit-scrollbar": {
                width: "4px",
                height: "3px",
              },
            }}
          >
            <Typography sx={{ fontWeight: "bold", marginBottom: "15px" }}>
              {"LEGAL"}
            </Typography>
            <Box sx={{ mb: 4 }}>
              <CustomDropDown
                header={"STANDARD"}
                is_data_object={false}
                selectedItem={selectedLS}
                setSelectedItem={setSelectedLS}
                data={existingLS?.map((ls) => {
                  return {
                    id: parseInt(ls.ls_id),
                    details: ls.standard_number,
                  };
                })}
              />
            </Box>
            <Box sx={{ mb: 4 }}>
              <CustomDropDown
                header={"CHAPTERS/TAGS"}
                is_data_object={false}
                selectedItem={selectedLSCh}
                setSelectedItem={setSelectedLSCh}
                data={existingLSCh.map((ch, idx) => {
                  return {
                    id: idx,
                    details: ch,
                  };
                })}
              />
            </Box> */}
        {/* <Box sx={{ mb: 4 }}>
              <CustomDropDown
                header={"LEGAL REQUIREMENTS No."}
                is_data_object={false}
                selectedItem={selectedLR}
                setSelectedItem={setSelectedLR}
                data={existingLR.map((ls) => {
                  return {
                    id: parseInt(ls.lr_id),
                    details: ls.req_number,
                  };
                })}
              />
            </Box> */}
        {/* </Paper>
        ) : undefined} */}
      </Paper>

      {/* Intersection details */}
      {existingData?.length ? (
        <Box>
          <TableContainer component={Paper} sx={{ maxHeight: "75vH" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {[
                    "SNO",
                    ...(existingData?.some(
                      (ee_detail) =>
                        ee_detail?.ee_details &&
                        Object.keys(ee_detail?.ee_details).length > 0
                    )
                      ? ["EE Component"]
                      : []),
                    ...(existingData?.some(
                      (swm_detail) =>
                        swm_detail?.swm_details &&
                        Object.keys(swm_detail?.swm_details).length > 0
                    )
                      ? ["Software Module"]
                      : []),
                    ...(existingData?.some((vehicle) => {
                      try {
                        return (
                          vehicle?.vehicle_component_details &&
                          JSON.parse(vehicle?.vehicle_component_details)
                            ?.length > 0
                        );
                      } catch (e) {
                        return false;
                      }
                    })
                      ? ["Vehicle Component"]
                      : []),
                    ...(existingData?.some((dependency) => {
                      return (
                        dependency?.dependencies &&
                        Object.keys(dependency?.dependencies).length > 0
                      );
                    })
                      ? ["Dependencies"]
                      : []),
                    "Legal Standard",
                    "Chapter",
                    "Legal Requirement",
                    "Attachments",
                    "Users",
                    "Description",
                  ].map((data, idx) => (
                    <TableCell
                      key={idx}
                      align="center"
                      sx={{ fontWeight: "bold", border: "1px solid black" }}
                    >
                      {data.split("\n").map((line, i) => (
                        <React.Fragment key={i}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {existingData.map((data, inx) => {
                  const vehicleComponents = data.vehicle_component_details
                    ? JSON?.parse(data?.vehicle_component_details)
                    : [];

                  return (
                    <TableRow key={inx}>
                      <TableCell
                        align="left"
                        sx={{ border: "1px solid black" }}
                      >
                        {inx + 1}
                      </TableCell>
                      {existingData?.some(
                        (ee_detail) =>
                          ee_detail?.ee_details &&
                          Object.keys(ee_detail?.ee_details).length > 0
                      ) ? (
                        <TableCell
                          align="left"
                          sx={{ border: "1px solid black" }}
                        >
                          <strong>Description:</strong>&nbsp;&nbsp;
                          {data.ee_details.description}
                          <br />
                          <br />
                          <strong>Version:</strong>&nbsp;&nbsp;
                          {data.ee_details.version}
                          <br />
                          <br />
                          <strong>Assembly:</strong>&nbsp;&nbsp;
                          {data.ee_details.assembly_number}
                          <br />
                          <br />
                          <strong>Supplier:</strong>&nbsp;&nbsp;
                          {data.ee_details.supplier_name}
                        </TableCell>
                      ) : null}

                      {existingData?.some(
                        (swm_detail) =>
                          swm_detail?.swm_details &&
                          Object.keys(swm_detail?.swm_details).length > 0
                      ) ? (
                        <TableCell
                          align="left"
                          sx={{ border: "1px solid black" }}
                        >
                          <strong>Name:</strong>&nbsp;&nbsp;
                          {data?.swm_details?.name}
                          <br />
                          <br />
                          <strong>Description:</strong>&nbsp;&nbsp;
                          {data?.swm_details?.description}
                          <br />
                          <br />
                          <strong>Version:</strong>&nbsp;&nbsp;
                          {data?.swm_details?.version}
                          <br />
                          <br />
                          <strong>Owner:</strong>&nbsp;&nbsp;
                          <br />
                          {data?.swm_details?.owner_details?.fullname}
                          <br />
                          {data?.swm_details?.owner_details?.email}
                        </TableCell>
                      ) : null}
                      {existingData?.some((vehicle) => {
                        try {
                          return (
                            vehicle?.vehicle_component_details &&
                            JSON.parse(vehicle?.vehicle_component_details)
                              ?.length > 0
                          );
                        } catch (e) {
                          return false;
                        }
                      }) ? (
                        <TableCell
                          align="left"
                          sx={{ border: "1px solid black" }}
                        >
                          <FormControl fullWidth>
                            {vehicleComponents.length > 0 ? (
                              <>
                                {/* <label style={{ fontWeight: "bold", fontSize: "14px" }}>
            VEHICLE COMPONENTS
          </label> */}
                                <Select
                                  value={vehicleComponents}
                                  displayEmpty
                                  sx={{ height: "40px", fontSize: "15px" }}
                                  renderValue={(selected) => {
                                    const selectedComponent =
                                      vehicleComponents.find(
                                        (component) => component.id === selected
                                      );
                                    return selectedComponent
                                      ? `${selectedComponent.component} - ${selectedComponent.assembly_number}`
                                      : "Vehicle Component";
                                  }}
                                >
                                  {vehicleComponents.map((component) => (
                                    <MenuItem
                                      sx={{
                                        fontSize: "15px",
                                        display: "block",
                                        padding: "10px 15px",
                                      }}
                                      key={`vehicle-${component.id}`}
                                      value={component.id}
                                    >
                                      <Box sx={{ marginBottom: "4px" }}>
                                        <strong>Component:</strong>{" "}
                                        {component.component}
                                      </Box>
                                      <Box sx={{ marginBottom: "4px" }}>
                                        <strong>Assembly No:</strong>{" "}
                                        {component.assembly_number}
                                      </Box>
                                      <Box>
                                        <strong>Summary:</strong>{" "}
                                        {component.summary}
                                      </Box>
                                    </MenuItem>
                                  ))}
                                </Select>
                              </>
                            ) : (
                              <Typography>
                                No Vehicle Components Available
                              </Typography>
                            )}
                          </FormControl>
                        </TableCell>
                      ) : null}

                      {existingData?.some((dependency) => {
                        return (
                          dependency?.dependencies &&
                          Object.keys(dependency?.dependencies).length > 0
                        );
                      }) ? (
                        <TableCell
                          align="center"
                          sx={{ border: "1px solid black" }}
                        >
                          <Button
                            sx={{
                              ...appTheme.commonBtnStyle,
                            }}
                            onClick={() => {
                              if (isSWMToLegalIntersection) {
                                handleDisplayDependecies({
                                  swm_name: data?.swm_details?.name,
                                  software_modules:
                                    data?.dependencies?.software_modules || [],
                                  vehicle_functions:
                                    data?.swm_details?.vehicle_functions,
                                });
                              } else {
                                handleDisplayDependecies({
                                  description: data?.ee_details?.description,
                                  ee_components:
                                    data?.dependencies?.ee_components || [],
                                  vehicle_systems:
                                    data?.ee_details?.vehicle_systems,
                                });
                              }
                            }}
                          >
                            View{" >>"}
                          </Button>
                        </TableCell>
                      ) : null}

                      <TableCell
                        align="left"
                        sx={{ border: "1px solid black" }}
                      >
                        <strong>Number:</strong>&nbsp;&nbsp;
                        {data.ls_details.standard_number}
                        <br />
                        <br />
                        <strong>Name:</strong>&nbsp;&nbsp;{data.ls_details.name}
                        <br />
                        <br />
                        <strong>Revision:</strong>&nbsp;&nbsp;
                        {data.ls_details.revision}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ border: "1px solid black" }}
                      >
                        {data.lr_details.chapter}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ border: "1px solid black" }}
                      >
                        <strong>Number:</strong>&nbsp;&nbsp;
                        {data.lr_details.req_number}
                        <br />
                        <br />
                        <strong>Description:</strong>&nbsp;&nbsp;
                        {data.lr_details.description}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ border: "1px solid black" }}
                      >
                        <Button
                          sx={{
                            ...appTheme.commonBtnStyle,
                          }}
                          onClick={() => {
                            setIsViewTemplateDialogOpen(true);
                            setDynamicFormData({
                              ...data?.dynamic_form,
                              fields:
                                typeof data?.dynamic_form?.fields === "string"
                                  ? JSON.parse(data?.dynamic_form?.fields)
                                  : data?.dynamic_form?.fields || [],
                              headers:
                                typeof data?.dynamic_form?.headers === "string"
                                  ? JSON.parse(data?.dynamic_form?.headers)
                                  : data?.dynamic_form?.headers || [],
                              footers:
                                typeof data?.dynamic_form?.footers === "string"
                                  ? JSON.parse(data?.dynamic_form?.footers)
                                  : data?.dynamic_form?.footers || [],
                              signatures:
                                typeof data?.dynamic_form?.signatures ===
                                "string"
                                  ? JSON.parse(data?.dynamic_form?.signatures)
                                  : data?.dynamic_form?.signatures || [],
                            });
                          }}
                        >
                          View{" >>"}
                        </Button>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ border: "1px solid black" }}
                      >
                        <CustomDropDown
                          header={"TA requestors"}
                          is_data_object={true}
                          data={data.oem_users.map((user) => ({
                            id: user.user_id,
                            details: { ...user },
                          }))}
                        />
                        <br />
                        <br />
                        <CustomDropDown
                          header={"TA reviewers"}
                          is_data_object={true}
                          data={data.tech_service_users.map((user) => ({
                            id: user.user_id,
                            details: { ...user },
                          }))}
                        />
                        <br />
                        <br />
                        {data?.suppliers?.length > 0 && (
                          <CustomDropDown
                            header={"Suppliers"}
                            is_data_object={true}
                            data={data.suppliers.map((user) => ({
                              id: user.user_id,
                              details: { ...user },
                            }))}
                          />
                        )}
                      </TableCell>

                      <TableCell
                        align="left"
                        sx={{ border: "1px solid black" }}
                      >
                        <strong>Description:</strong>&nbsp;&nbsp;
                        {data.description}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "right",
              marginTop: "20px",
              textAlign: "right",
            }}
          >
            <Button
              sx={{
                ...appTheme.commonBtnStyle,
                mr: 3,
              }}
              onClick={handleDraftTickets}
            >
              Save as draft
            </Button>
            <Button
              sx={{
                ...appTheme.commonBtnStyle,
              }}
              onClick={handleRaiseTickets}
            >
              Raise Tickets
            </Button>
          </Box>
        </Box>
      ) : undefined}

      {open &&
        (isSWMToLegalIntersection ? (
          <DependenciesDialog
            open={open}
            handleClose={() => setOpen(false)}
            swmName={dependencies?.swm_name}
            vehicleFunctions={dependencies?.vehicle_functions}
            softwareModules={dependencies?.software_modules}
          />
        ) : (
          <EEDependenciesDialog
            open={open}
            handleClose={() => setOpen(false)}
            description={dependencies?.description}
            vehicleSystems={dependencies?.vehicle_systems}
            eeComponents={dependencies?.ee_components}
          />
        ))}

      {/* View Template dialog */}
      <ViewTemplateDialog
        open={isViewTemplateDialogOpen}
        onClose={() => handleViewTemplateDialog(false)}
        dynamicFormData={dynamicFormData}
        setDynamicFormData={setDynamicFormData}
        dialogTitle={"Template"}
        dialogContent={null}
        onConfirm={() => {}}
        onDisabled={() => {}}
      />
    </Box>
  );
}

export default Intersections;

// Styles
const styles = {
  txtBold: {
    fontWeight: "bold",
  },
  rmFlContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
};
