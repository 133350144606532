/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 20-07-2024
 * @description : Custom dynamic form component.
 */
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import UpdateIcon from "@mui/icons-material/Update";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import DraftsIcon from "@mui/icons-material/Drafts";
import DynamicFormTemplate from "./DynamicFormTemplate";
import customConsole from "../../../config/customConsole";
import { CustomButton } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { HandleApiActions, settingsApis } from "../../../redux/actions";
import dynamicTemplateAction from "../../../redux/actions/dynamicTemplateAction";

const DynamicFormDialog = ({
  open,
  onClose,
  dialogTitle,
  dialogContent,
  onConfirm,
  onDisabled,
}) => {
  /*Redux*/
  const dispatch = useDispatch();
  const metaDetails = useSelector((state) => state.settingsReducer.metaData);

  /* useEffect for Dispatches*/
  useEffect(() => {
    dispatch(HandleApiActions({ ...settingsApis.get_app_meta_data }));
  }, []);

  console.log("metaDetails", metaDetails?.templates?.template_types);

  /* Constant States */
  const FORM_TYPES = {
    EE: metaDetails?.templates?.template_types?.find(
      (data) => data?.key === "EE COMPONENT"
    )?.value,
    SWM: metaDetails?.templates?.template_types?.find(
      (data) => data?.key === "SOFTWARE MODULE"
    )?.value,
    VEHICLE: metaDetails?.templates?.template_types?.find(
      (data) => data?.key === "VEHICLE COMPONENT"
    )?.value,
  };
  const ALIGN_TYPES = { LEFT: "left", CENTER: "center", RIGHT: "right" };
  const FIELD_TYPES = { NUMBER: "number", TEXT: "string" };
  const EDIT_TYPES = { DDH: 100, SNOW: 200, BOTH: 300 };

  /* States */
  const [formName, setFormName] = useState("");
  const [formNameError, setFormNameError] = useState(false);
  const [formType, setFormType] = useState(FORM_TYPES?.EE);
  const [selectedHeaders, setSelectedHeaders] = useState([]);
  const [selectedFooters, setSelectedFooters] = useState([]);
  const [selectedSignatures, setSelectedSignatures] = useState([]);
  const [error, setError] = useState(false);
  const [sequenceErrorMessage, setSequenceErrorMesssage] = useState("");
  const [fieldValue, setFieldValue] = useState("");

  //States for Header Title and SubTitle
  const [headerTitle, setHeaderTitle] = useState({
    left: "",
    right: "",
    center: "",
  });
  const [headerSubTitle, setHeaderSubTitle] = useState({
    left: "",
    right: "",
    center: "",
  });

  //States for Footer Title and SubTitle
  const [footerTitle, setFooterTitle] = useState({
    left: "",
    right: "",
    center: "",
  });
  const [footerSubTitle, setFooterSubTitle] = useState({
    left: "",
    right: "",
    center: "",
  });

  //States for Signature Title and SubTitle
  const [signatureTitle, setSignatureTitle] = useState({
    left: "",
    right: "",
    center: "",
  });
  const [signatureSubTitle, setSignatureSubTitle] = useState({
    left: "",
    right: "",
    center: "",
  });

  const [selectHeadersDetails, setSelectHeadersDetails] = useState([]);
  const [selectFootersDetails, setSelectFootersDetails] = useState([]);
  const [selectSignatureDetails, setSelectSignatureDetails] = useState([]);

  // Add/Update states
  // const [fields, setFields] = useState([
  //   {
  //     id: 1,
  //     field_name: "Field Name 1",
  //     fieldValue: "",
  //     fieldType: FIELD_TYPES?.NUMBER,
  //     edit_type: EDIT_TYPES?.DDH,
  //   },
  //   {
  //     id: 2,
  //     field_name: "Field Name 2",
  //     fieldValue: "",
  //     fieldType: FIELD_TYPES?.NUMBER,
  //     edit_type: EDIT_TYPES?.SNOW,
  //   },
  //   {
  //     id: 3,
  //     field_name: "Field Name 3",
  //     fieldValue: "",
  //     fieldType: FIELD_TYPES?.TEXT,
  //     edit_type: EDIT_TYPES?.BOTH,
  //   },
  // ]);

  const [fields, setFields] = useState([]);

  const [showAddUpdateField, setShowAddUpdateField] = useState(false);
  const [showEditField, setShowEditField] = useState(false);
  const [currentField, setCurrentField] = useState({
    field_name: "",
    value: "",
    edit_type: "",
    sequence: "",
    default_value: "",
  });

  const headerOptionsList = [
    {
      value: ALIGN_TYPES.LEFT,
      image: "",
      label: "Left",
      title: headerTitle?.left,
      subTitle: headerSubTitle?.left,
    },
    {
      value: ALIGN_TYPES.CENTER,
      image: "",
      label: "Center",
      title: headerTitle?.center,
      subTitle: headerSubTitle?.center,
    },
    {
      value: ALIGN_TYPES.RIGHT,
      image: "",
      label: "Right",
      title: headerTitle?.right,
      subTitle: headerSubTitle?.right,
    },
  ];

  useEffect(() => {
    if (selectedHeaders?.length > 0) {
      const selectedDetails = selectedHeaders
        ?.map((selectedValue) => {
          const option = headerOptionsList?.find(
            (option) => option?.value === selectedValue
          );

          if (option) {
            return {
              position: option?.value,
              image: option?.image,
              title: option?.title,
              sub_title: option?.subTitle,
            };
          }
          return null;
        })
        ?.filter((item) => item !== null);

      setSelectHeadersDetails(selectedDetails);
    }
  }, [selectedHeaders, headerTitle, headerSubTitle]);

  const handleHeaderTitleChange = (value, newTitle) => {
    const trimmedTitle = newTitle.trimStart();
    const updatedTitle = { ...headerTitle, [value]: trimmedTitle };
    setHeaderTitle(updatedTitle);
  };

  const handleHeaderSubTitleChange = (value, newTitle) => {
    const trimmedTitle = newTitle.trimStart();
    const updatedTitle = { ...headerSubTitle, [value]: trimmedTitle };
    setHeaderSubTitle(updatedTitle);
  };

  const footerOptionsList = [
    {
      value: ALIGN_TYPES.LEFT,
      image: "",
      label: "Left",
      title: footerTitle?.left,
      subTitle: footerSubTitle?.left,
    },
    {
      value: ALIGN_TYPES.CENTER,
      image: "",
      label: "Center",
      title: footerTitle?.center,
      subTitle: footerSubTitle?.center,
    },
    {
      value: ALIGN_TYPES.RIGHT,
      image: "",
      label: "Right",
      title: footerTitle?.right,
      subTitle: footerSubTitle?.right,
    },
  ];

  useEffect(() => {
    if (selectedFooters?.length > 0) {
      const selectedDetails = selectedFooters
        ?.map((selectedValue) => {
          const option = footerOptionsList?.find(
            (option) => option?.value === selectedValue
          );

          if (option) {
            return {
              position: option?.value,
              image: option?.image,
              title: option?.title,
              sub_title: option?.subTitle,
            };
          }
          return null;
        })
        .filter((item) => item !== null);
      setSelectFootersDetails(selectedDetails);
    }
  }, [selectedFooters, footerTitle, footerSubTitle]);

  const handleFooterTitleChange = (value, newTitle) => {
    const trimmedTitle = newTitle.trimStart();
    const updatedTitle = { ...footerTitle, [value]: trimmedTitle };
    setFooterTitle(updatedTitle);
  };

  const handleFooterSubTitleChange = (value, newTitle) => {
    const trimmedTitle = newTitle.trimStart();
    const updatedTitle = { ...footerSubTitle, [value]: trimmedTitle };
    setFooterSubTitle(updatedTitle);
  };

  const signatureOptionsList = [
    {
      value: ALIGN_TYPES?.LEFT,
      label: "Left",
      title: signatureTitle?.left,
      subTitle: signatureSubTitle?.left,
      image: "",
    },
    {
      value: ALIGN_TYPES?.RIGHT,
      label: "Right",
      title: signatureTitle?.right,
      subTitle: signatureSubTitle?.right,
      image: "",
    },
  ];

  useEffect(() => {
    if (selectedSignatures?.length > 0) {
      const selectedDetails = selectedSignatures
        ?.map((selectedValue) => {
          const option = signatureOptionsList?.find(
            (option) => option?.value === selectedValue
          );
          if (option) {
            return {
              position: option?.value,
              image: option?.image,
              title: option?.title,
              sub_title: option?.subTitle,
            };
          }
          return null;
        })
        .filter((item) => item !== null);
      setSelectSignatureDetails(selectedDetails);
    }
  }, [selectedSignatures, signatureTitle, signatureSubTitle]);

  const handleSignatureTitleChange = (value, newTitle) => {
    const trimmedTitle = newTitle.trimStart();
    const updatedTitle = { ...signatureTitle, [value]: trimmedTitle };
    setSignatureTitle(updatedTitle);
  };

  const handleSignatureSubTitleChange = (value, newTitle) => {
    const trimmedTitle = newTitle.trimStart();
    const updatedTitle = { ...signatureSubTitle, [value]: trimmedTitle };
    setSignatureSubTitle(updatedTitle);
  };

  /* Functions */
  const handleEditField = (id) => {
    const field = fields?.find((field) => field?.sequence === id);

    console.log("field", field);
    if (field) {
      setCurrentField(field); // Load the selected field into the form
      setShowEditField(true);
      setShowAddUpdateField(false);
    }
  };

  const handleAddField = () => {
    const newField = {
      field_name: currentField.field_name,
      value: fieldValue,
      edit_type: parseInt(currentField.edit_type),
      default_value: currentField.default_value,
      sequence: currentField.sequence,
    };

    setFields((prevFields) => [...prevFields, newField]);

    // Reset form after adding the new field
    setCurrentField({
      field_name: "",
      value: "",
      edit_type: "",
      sequence: "",
      default_value: "",
    });
    setShowEditField(false);
  };
  const handleUpdateField = (id) => {
    const fieldIndex = fields?.findIndex((field) => field?.sequence === id);
    console.log("Field index found:", fieldIndex);

    if (fieldIndex !== -1) {
      const newField = {
        field_name: currentField.field_name,
        value: fieldValue,
        edit_type: parseInt(currentField.edit_type),
        default_value: currentField.default_value,
        sequence: currentField.sequence, // Ensure this has the intended updated value
      };
      console.log("New field:", newField);

      const updatedFields = [...fields];
      updatedFields[fieldIndex] = newField;

      setFields(updatedFields);
      console.log("Fields after update:", updatedFields);
    } else {
      console.error(`Field with sequence ID ${id} not found.`);
    }

    setShowEditField(false);
    setCurrentField({
      field_name: "",
      value: "",
      edit_type: "",
      sequence: "",
      default_value: "",
    });
  };

  const resetFieldInputs = () => {
    setCurrentField(null);
    setShowAddUpdateField(false);
    setShowEditField(false);
  };

  const handleFieldChange = (field, value) => {
    const trimmedValue = value.trimStart();
    setCurrentField((prev) => ({
      ...prev,
      [field]: trimmedValue,
    }));
    if (trimmedValue.length < 1) {
      setError(true);
    } else {
      setError(false);
    }
    if (field === "sequence") {
      if (fields.some((item) => item.sequence === value)) {
        setSequenceErrorMesssage("Sequence number already exists.");
      } else {
        setSequenceErrorMesssage("");
      }
    }
  };

  const handleCreateDT = (index) => {
    const reqObj = {
      name: formName,
      type: parseInt(formType),
      fields: fields,
      headers: selectHeadersDetails,
      footers: selectFootersDetails,
      signatures: selectSignatureDetails,
      status: index === 1 ? 200 : 100,
    };
    console.log("ReqObj For DT", reqObj);
    dispatch(
      HandleApiActions({
        ...dynamicTemplateAction.add_d_template,
        params: reqObj,
        show_toast: true,
      })
    )?.then((resp) => {
      if (resp?.error === false) {
        dispatch(
          HandleApiActions({
            ...dynamicTemplateAction.get_d_template,
            params: {
              page_size: 25,
              page_number: 1,
            },
          })
        );
        onClose();
      }
    });
  };

  const handleFormNameChange = (e) => {
    const value = e.target.value;

    // If the first character is a space, do not allow it
    if (value.trim().length === 0 && value.startsWith(" ")) {
      setFormNameError(true);
      return;
    }

    // Trim spaces for all other cases (after the first character)
    const trimmedValue = value.trimStart();

    setFormName(trimmedValue);

    // Validate if the form name has a minimum length > 1
    if (trimmedValue.length <= 1) {
      setFormNameError(true);
    } else {
      setFormNameError(false);
    }
  };

  const handleAddClick = () => {
    setShowAddUpdateField(true);
    setShowEditField(false);
    setCurrentField({
      field_name: "",
      value: "",
      edit_type: "",
      sequence: "",
      default_value: "",
    });
  };
  return (
    <Dialog
      open={open}
      // onClose={onClose}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          textAlign: "center",
          boxShadow: 2,
          py: 1,
        }}
      >
        {/* Title text */}
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Add Form
        </Typography>

        {/* Close Icon Button */}
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
        }}
      >
        {/* Enter form name */}
        <Box>
          <Typography
            variant="subtitle1"
            color={"grey"}
            component="label"
            htmlFor="form-type"
          >
            Enter name of the form*
          </Typography>

          <TextField
            fullWidth
            id="form-type"
            variant="standard"
            type="text"
            size="large"
            value={formName}
            onChange={handleFormNameChange}
            error={formNameError} // Show error styling
            helperText={
              formNameError
                ? "Form name must be more than 1 character long."
                : ""
            } // Show error message
            sx={{ mt: -1 }}
            autoComplete="off"
          />
        </Box>
        {/* select form type */}
        <Box>
          <FormControl>
            <FormLabel id="demo-controlled-radio-buttons-group">
              Select type of the form*
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={formType}
              onChange={(e) => setFormType(e.target?.value)}
              sx={{ mt: -1 }}
            >
              {[
                { value: FORM_TYPES?.EE, label: "EE Components" },
                { value: FORM_TYPES?.SWM, label: "Software Modules" },
                {
                  value: FORM_TYPES?.VEHICLE,
                  label: "Vehicle Components",
                },
              ]?.map((item, index) => (
                <FormControlLabel
                  key={index}
                  value={item?.value}
                  control={<Radio />}
                  label={item?.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
        {/* Select headers, footers & signature  */}
        <Box sx={{ display: "flex", gap: 3 }}>
          {/* Select headers  */}
          <Box sx={{ width: "100%" }}>
            <Typography
              variant="subtitle1"
              color={"grey"}
              component="label"
              htmlFor="form-type"
            >
              Select Header*
            </Typography>
            <FormControl variant="standard" fullWidth>
              {/* <InputLabel>Select header</InputLabel> */}
              <Select
                multiple
                value={selectedHeaders}
                onChange={(e) => setSelectedHeaders(e?.target?.value)}
                input={<Input />}
                renderValue={
                  (selected) =>
                    selected
                      ?.map(
                        (value) =>
                          headerOptionsList?.find(
                            (option) => option?.value === value
                          )?.label
                      )
                      ?.join(", ") // Display labels of selected values
                }
              >
                {headerOptionsList?.map((option) => (
                  <MenuItem key={option?.value} value={option?.value}>
                    <Checkbox
                      checked={selectedHeaders?.indexOf(option?.value) > -1}
                    />
                    <ListItemText primary={option?.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          {/* Select footers  */}
          <Box sx={{ width: "100%" }}>
            <Typography
              variant="subtitle1"
              color={"grey"}
              component="label"
              htmlFor="form-type"
            >
              Select Footer*
            </Typography>
            <FormControl variant="standard" fullWidth>
              {/* <InputLabel>Select footer</InputLabel> */}
              <Select
                multiple
                value={selectedFooters}
                onChange={(e) => setSelectedFooters(e?.target?.value)}
                input={<Input />}
                renderValue={
                  (selected) =>
                    selected
                      ?.map(
                        (value) =>
                          headerOptionsList?.find(
                            (option) => option?.value === value
                          )?.label
                      )
                      ?.join(", ") // Display labels of selected values
                }
              >
                {footerOptionsList?.map((option) => (
                  <MenuItem key={option?.value} value={option?.value}>
                    <Checkbox
                      checked={selectedFooters?.indexOf(option?.value) > -1}
                    />
                    <ListItemText primary={option?.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          {/* Select signatures  */}
          <Box sx={{ width: "100%" }}>
            <Typography
              variant="subtitle1"
              color={"grey"}
              component="label"
              htmlFor="form-type"
            >
              Select Signature*
            </Typography>
            <FormControl variant="standard" fullWidth>
              {/* <InputLabel>Select signature</InputLabel> */}
              <Select
                multiple
                value={selectedSignatures}
                onChange={(e) => setSelectedSignatures(e?.target?.value)}
                input={<Input />}
                renderValue={(selected) =>
                  selected
                    ?.map(
                      (value) =>
                        headerOptionsList?.find(
                          (option) => option?.value === value
                        )?.label
                    )
                    ?.join(", ")
                }
              >
                {signatureOptionsList?.map((option) => (
                  <MenuItem key={option?.value} value={option?.value}>
                    <Checkbox
                      checked={selectedSignatures?.indexOf(option?.value) > -1}
                    />
                    <ListItemText primary={option?.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          {/* Add  */}
          <Box
            sx={{
              width: "70%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CustomButton
              size={"small"}
              variant={"contained"}
              btnName={"Add "}
              startIcon={<AddIcon />}
              handleOnClick={handleAddClick}
              btnStyle={{
                ml: 2,
              }}
            />
          </Box>
        </Box>
        {/* Template & Add/Update field */}
        <Box sx={{ display: "flex", gap: 2 }}>
          {/* Template */}
          <DynamicFormTemplate
            setFields={setFields}
            fieldValue={fieldValue}
            setFieldValue={setFieldValue}
            handleFooterTitleChange={handleFooterTitleChange}
            handleSignatureTitleChange={handleSignatureTitleChange}
            handleSignatureSubTitleChange={handleSignatureSubTitleChange}
            handleFooterSubTitleChange={handleFooterSubTitleChange}
            handleHeaderSubTitleChange={handleHeaderSubTitleChange}
            handleHeaderTitleChange={handleHeaderTitleChange}
            fields={fields}
            EDIT_TYPES={EDIT_TYPES}
            handleEditField={handleEditField}
            headerOptionsList={headerOptionsList}
            selectedHeaders={selectedHeaders}
            signatureOptionsList={signatureOptionsList}
            selectedSignatures={selectedSignatures}
            footerOptionsList={footerOptionsList}
            selectedFooters={selectedFooters}
            handleFieldChange={handleFieldChange}
          />
          {/* Add field */}
          {showAddUpdateField ? (
            <Paper
              elevation={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                px: 1,
                py: 1.4,
                border: "1px solid grey",
                borderRadius: 1,
                height: "490px",
                width: "240px",
              }}
            >
              {/* Title text */}
              <Typography
                sx={{
                  fontSize: "17px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Add Field
              </Typography>
              <Divider sx={{ m: -1, p: 0, border: "1px solid lightgrey" }} />

              {/* Enter field name */}
              <Box>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "bold" }}
                  component="label"
                  htmlFor="form-type"
                >
                  Field Name*
                </Typography>
                <TextField
                  fullWidth
                  placeholder="Enter the Field Name"
                  id="form-type"
                  variant="standard"
                  type="text"
                  size="small"
                  value={currentField?.field_name || ""}
                  onChange={(e) =>
                    handleFieldChange("field_name", e.target?.value)
                  }
                  error={error}
                  helperText={
                    error ? "Field name must have at least 1 character." : ""
                  }
                  sx={{ width: "220px" }}
                />
              </Box>

              {/* Select field type */}
              <Box sx={{ mt: 1 }}>
                <FormControl>
                  <FormLabel sx={{ fontWeight: "bold", color: "black" }}>
                    Select Field Type*
                  </FormLabel>
                  <RadioGroup
                    row
                    value={currentField?.default_value || ""}
                    onChange={(e) =>
                      handleFieldChange("default_value", e.target?.value)
                    }
                    sx={{ mt: -1 }}
                  >
                    {[
                      { value: FIELD_TYPES?.NUMBER, label: "Number" },
                      { value: FIELD_TYPES?.TEXT, label: "Text" },
                    ]?.map((item, index) => (
                      <FormControlLabel
                        key={item?.value}
                        value={item?.value}
                        control={<Radio />}
                        label={item?.label}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Box>

              {/* Select sequence */}
              <Box>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "bold" }}
                  component="label"
                  htmlFor="form-type"
                >
                  Sequence No*
                </Typography>
                <TextField
                  fullWidth
                  id="form-type"
                  variant="standard"
                  type="number"
                  size="small"
                  value={currentField?.sequence || ""}
                  onChange={(e) =>
                    handleFieldChange("sequence", e.target?.value)
                  }
                  sx={{ width: "220px" }}
                  error={!!sequenceErrorMessage}
                  helperText={sequenceErrorMessage}
                />
              </Box>

              {/* Select edit type */}
              <Box>
                <FormControl>
                  <FormLabel sx={{ fontWeight: "bold", color: "black" }}>
                    Select Edit Type*
                  </FormLabel>
                  <RadioGroup
                    row
                    value={parseInt(currentField?.edit_type) || ""}
                    onChange={(e) =>
                      handleFieldChange("edit_type", e.target?.value)
                    }
                    sx={{ mt: -1 }}
                  >
                    {[
                      { value: EDIT_TYPES?.DDH, label: "DDH" },
                      { value: EDIT_TYPES?.SNOW, label: "SNOW" },
                      { value: EDIT_TYPES?.BOTH, label: "BOTH" },
                    ]?.map((item, index) => (
                      <FormControlLabel
                        key={item?.value}
                        value={item?.value}
                        control={<Radio />}
                        label={item?.label}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Box>

              {/* Close & Add/Update buttons */}
              <Box
                sx={{ display: "flex", justifyContent: "flex-end", gap: 1.4 }}
              >
                <CustomButton
                  size={"small"}
                  variant={"contained"}
                  btnName={"Close"}
                  startIcon={<CloseIcon />}
                  handleOnClick={resetFieldInputs}
                  btnStyle={{}}
                />
                <CustomButton
                  size={"small"}
                  variant={"contained"}
                  btnName={"Add"}
                  startIcon={<CheckIcon />}
                  handleOnClick={handleAddField}
                  btnStyle={{}}
                  disabled={
                    !(
                      currentField?.field_name?.trim() &&
                      currentField?.default_value &&
                      currentField?.sequence &&
                      !fields.some(
                        (field) => field.sequence === currentField?.sequence
                      )
                    )
                  }
                />
              </Box>
            </Paper>
          ) : null}

          {/* Update Field */}
          {showEditField ? (
            <Paper
              elevation={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                px: 1,
                py: 1.4,
                border: "1px solid grey",
                borderRadius: 1,
                height: "490px",
                width: "240px",
              }}
            >
              {/* Title text */}
              <Typography
                sx={{
                  fontSize: "17px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Update Field
              </Typography>
              <Divider sx={{ m: -1, p: 0, border: "1px solid lightgrey" }} />
              {/* Enter field name */}
              <Box>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "bold" }}
                  component="label"
                  htmlFor="form-type"
                >
                  Field Name*
                </Typography>
                <TextField
                  fullWidth
                  placeholder="Enter the Field Name"
                  id="form-type"
                  variant="standard"
                  type="text"
                  size="small"
                  value={currentField?.field_name || ""}
                  onChange={(e) =>
                    handleFieldChange("field_name", e.target?.value)
                  }
                  error={error}
                  helperText={
                    error ? "Field name must have at least 1 character." : ""
                  }
                  sx={{ width: "220px" }}
                />
              </Box>

              {/* select field type */}
              <Box sx={{ mt: 1 }}>
                <FormControl>
                  <FormLabel sx={{ fontWeight: "bold", color: "black" }}>
                    Select Field Type*
                  </FormLabel>
                  <RadioGroup
                    row
                    value={currentField?.default_value || ""}
                    onChange={(e) =>
                      handleFieldChange("default_value", e.target?.value)
                    }
                    sx={{ mt: -1 }}
                  >
                    {[
                      { value: FIELD_TYPES?.NUMBER, label: "Number" },
                      { value: FIELD_TYPES?.TEXT, label: "Text" },
                    ]?.map((item, index) => (
                      <FormControlLabel
                        key={item?.value}
                        value={item?.value}
                        control={<Radio />}
                        label={item?.label}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Box>
              {/* select sequence */}
              <Box>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "bold" }}
                  component="label"
                  htmlFor="form-type"
                >
                  Sequence No*
                </Typography>
                <TextField
                  placeholder="Enter a Sequence No"
                  fullWidth
                  id="form-type"
                  variant="standard"
                  type="number"
                  size={"small"}
                  value={currentField?.sequence || ""}
                  onChange={(e) =>
                    handleFieldChange("sequence", e.target?.value)
                  }
                  sx={{ width: "220px" }}
                />
              </Box>
              {/* select edit type */}
              <Box>
                <FormControl>
                  <FormLabel sx={{ fontWeight: "bold", color: "black" }}>
                    Select Edit Type*
                  </FormLabel>
                  <RadioGroup
                    row
                    value={parseInt(currentField?.edit_type) || ""}
                    onChange={(e) =>
                      handleFieldChange("edit_type", e.target?.value)
                    }
                    sx={{ mt: -1 }}
                  >
                    {[
                      { value: EDIT_TYPES?.DDH, label: "DDH" },
                      { value: EDIT_TYPES?.SNOW, label: "SNOW" },
                      { value: EDIT_TYPES?.BOTH, label: "BOTH" },
                    ]?.map((item, index) => (
                      <FormControlLabel
                        key={item?.value}
                        value={item?.value}
                        control={<Radio />}
                        label={item?.label}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Box>
              {/* Close & Add/Update buttons */}
              <Box
                sx={{ display: "flex", justifyContent: "flex-end", gap: 1.4 }}
              >
                <CustomButton
                  size={"small"}
                  variant={"contained"}
                  btnName={"Close "}
                  startIcon={<CloseIcon />}
                  handleOnClick={resetFieldInputs}
                  btnStyle={{}}
                />
                <CustomButton
                  size={"small"}
                  variant={"contained"}
                  btnName={"Update"}
                  startIcon={<UpdateIcon />}
                  handleOnClick={() =>
                    handleUpdateField(currentField?.sequence)
                  }
                  btnStyle={{}}
                />
              </Box>
            </Paper>
          ) : null}
        </Box>
      </DialogContent>
      <DialogActions sx={{ boxShadow: 4, gap: 2, px: 2, py: 1 }}>
        <CustomButton
          size={"small"}
          variant={"contained"}
          btnName={"Close"}
          startIcon={<CloseIcon />}
          handleOnClick={onClose}
          btnStyle={{}}
        />
        <CustomButton
          size={"small"}
          variant={"contained"}
          btnName={"Save As Draft"}
          startIcon={<DraftsIcon />}
          handleOnClick={() => handleCreateDT(2)}
          btnStyle={{}}
        />
        <CustomButton
          disabled={formName?.length > 0 && formType ? false : true}
          size={"small"}
          variant={"contained"}
          btnName={"Create"}
          startIcon={<CheckIcon />}
          handleOnClick={() => handleCreateDT(1)}
          btnStyle={{}}
        />
      </DialogActions>
    </Dialog>
  );
};

export default DynamicFormDialog;
