/**
 * @author Bharathan - bharathan@au79consulting.com
 * @date 09-08-2024
 * @description : This is Tickets Page for Type Approval Page
 */

//Importing the required components/functions from the libraries
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import appTheme from "../../../assets/AppTheme/appTheme";
import SwmTickets from "./SwmTickets";
import EeTickets from "./EeTickets";
import VehicleTickets from "./VehicleTickets";
import { LinkModuleType, SearchSelectTextField } from "../../../components";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import TicketsType from "../../../components/labcar/TicketsType";

//Link Component to view the concern S/W Modules and Legal Requirements to be Link
function Tickets({
  selectOEM,
  selectedSWM,
  selectedZones,
  selectTECH,
  selectedVC,
  selectColumn,
  selectedEE,
  selectedChapter,
  selectedStandard,
  selectedLR,
  selectOrder,
  selectTicketStatus,
  searchIntrId,
  searchTicketId,
  fetchTickets,
  ticketDetails,
  setTicketDetails,

  ticketsType,
  selectedTicketsType,
  setSelectedTicketsType,
  setIntersectionDetailsNew,
  setIsSWMToLegalIntersection,
  setIsVehicleToLegalIntersection,
  selectedProjectIsZonal,
  selectedProjectZonesList,
  setTabValue,

  setShowIntersDelTicket,

  projectOptionsList,
  selectedProject,
  setSelectedProject,
  selectedProjectDetails,

  selectedSingleVariantDetails,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  reqObj,
}) {
  const dispatch = useDispatch();

  // const ticketsType = { SWM: 1, EE: 2, VEHICLE: 3 };

  // const [selectedTicketsType, setSelectedTicketsType] = useState(
  //   ticketsType?.EE
  // );

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryLinkModuleType = queryParams.get("linkModuleType");

  useEffect(() => {
    if (queryLinkModuleType) {
      setSelectedTicketsType(Number(queryLinkModuleType)); // Set state based on query parameter
    }
  }, [queryLinkModuleType]);

  const handleProjectChange = (newSelectedProject) => {
    dispatch(setSelectedProject(newSelectedProject));
  };

  console.log("selectedTicketsType", selectedTicketsType);

  return (
    <Box>
      {/* Linking Software module To Legal or EE Component To Legal */}
      <Box sx={{ mt: 1.4, mb: 9 }}>
        {/* Linking Software module To Legal */}
        {parseInt(selectedTicketsType) === ticketsType?.SWM ? (
          <SwmTickets
            searchIntrId={searchIntrId}
            searchTicketId={searchTicketId}
            selectedSWM={selectedSWM}
            selectedChapter={selectedChapter}
            selectedStandard={selectedStandard}
            selectedLR={selectedLR}
            selectOEM={selectOEM}
            selectedZones={selectedZones}
            selectTECH={selectTECH}
            selectedVC={selectedVC}
            selectOrder={selectOrder}
            selectColumn={selectColumn}
            selectedEE={selectedEE}
            selectTicketStatus={selectTicketStatus}
            fetchTickets={fetchTickets}
            ticketDetails={ticketDetails}
            setTicketDetails={setTicketDetails}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            setIntersectionDetailsNew={setIntersectionDetailsNew}
            setIsSWMToLegalIntersection={setIsSWMToLegalIntersection}
            selectedSingleVariantDetails={selectedSingleVariantDetails}
            selectedProjectIsZonal={selectedProjectIsZonal}
            selectedProjectZonesList={selectedProjectZonesList}
            setTabValue={setTabValue}
            setShowIntersDelTicket={setShowIntersDelTicket}
            projectOptionsList={projectOptionsList}
            selectedProject={selectedProject}
            setSelectedProject={() => {}}
            selectedProjectId={selectedProjectDetails?.project_id}
            selectedProjectDetails={selectedProjectDetails}
          />
        ) : parseInt(selectedTicketsType) === ticketsType?.EE ? (
          <EeTickets
            searchIntrId={searchIntrId}
            searchTicketId={searchTicketId}
            selectedSWM={selectedSWM}
            selectedChapter={selectedChapter}
            selectedStandard={selectedStandard}
            selectedLR={selectedLR}
            selectOEM={selectOEM}
            selectedZones={selectedZones}
            selectTECH={selectTECH}
            selectedVC={selectedVC}
            selectColumn={selectColumn}
            selectedEE={selectedEE}
            selectTicketStatus={selectTicketStatus}
            fetchTickets={fetchTickets}
            ticketDetails={ticketDetails}
            setTicketDetails={setTicketDetails}
            reqObj={reqObj}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            selectOrder={selectOrder}
            setIntersectionDetailsNew={setIntersectionDetailsNew}
            setIsSWMToLegalIntersection={setIsSWMToLegalIntersection}
            selectedSingleVariantDetails={selectedSingleVariantDetails}
            selectedProjectIsZonal={selectedProjectIsZonal}
            selectedProjectZonesList={selectedProjectZonesList}
            setTabValue={setTabValue}
            setShowIntersDelTicket={setShowIntersDelTicket}
            projectOptionsList={projectOptionsList}
            selectedProject={selectedProject}
            setSelectedProject={() => {}}
            selectedProjectId={selectedProjectDetails?.project_id}
            selectedProjectDetails={selectedProjectDetails}
          />
        ) : parseInt(selectedTicketsType) === ticketsType?.VEHICLE ? (
          <VehicleTickets
            searchIntrId={searchIntrId}
            searchTicketId={searchTicketId}
            selectedSWM={selectedSWM}
            selectedChapter={selectedChapter}
            selectedStandard={selectedStandard}
            selectedLR={selectedLR}
            selectOEM={selectOEM}
            selectedZones={selectedZones}
            selectTECH={selectTECH}
            selectedVC={selectedVC}
            selectOrder={selectOrder}
            selectColumn={selectColumn}
            selectedEE={selectedEE}
            selectTicketStatus={selectTicketStatus}
            fetchTickets={fetchTickets}
            ticketDetails={ticketDetails}
            setTicketDetails={setTicketDetails}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            setIntersectionDetailsNew={setIntersectionDetailsNew}
            setIsSWMToLegalIntersection={setIsSWMToLegalIntersection}
            selectedSingleVariantDetails={selectedSingleVariantDetails}
            selectedProjectIsZonal={selectedProjectIsZonal}
            selectedProjectZonesList={selectedProjectZonesList}
            setTabValue={setTabValue}
            setShowIntersDelTicket={setShowIntersDelTicket}
            projectOptionsList={projectOptionsList}
            selectedProject={selectedProject}
            setSelectedProject={() => {}}
            selectedProjectId={selectedProjectDetails?.project_id}
            selectedProjectDetails={selectedProjectDetails}
          />
        ) : null}
      </Box>
    </Box>
  );
}

export default Tickets;

//Styles for the page
// const styles = {
//   searchContainer: {
//     display: "flex",
//     justifyContent: "center",
//     textAlign: "start",
//   },
//   searchSelectTextField: {
//     width: "215px",
//     backgroundColor: "white",
//   },
// };
