import {
  Checkbox,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";

function MultiSelectSuppliers({
  size,
  label,
  optionsList,
  selectedValues,
  setSelectedValues,
  sltFldStyle,
}) {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    // Ensures that the selected values are correctly stored as an array of numbers
    setSelectedValues(
      typeof value === "string" ? value.split(",").map(Number) : value
    );
  };
  return (
    <div>
      <FormControl variant="standard" size={size} sx={sltFldStyle}>
        <InputLabel>{label}</InputLabel>
        <Select
          multiple
          value={selectedValues}
          onChange={handleChange}
          input={<Input />}
          renderValue={(selected) =>
            selected
              .map((id) => {
                const selectedOption = optionsList.find(
                  (option) => option?.id === id
                );
                return selectedOption ? selectedOption.name : "";
              })
              .join(", ")
          }
          MenuProps={CustomMenuProps}
        >
          {optionsList?.map((data) => (
            <MenuItem
              key={data?.id}
              value={data?.id} // Use `data.id` directly without parsing
              sx={{
                whiteSpace: "normal",
                wordBreak: "break-word",
                lineHeight: 1.5,
              }}
            >
              <Checkbox
                checked={selectedValues.includes(data?.id)} // Use `includes` for checking selection
              />
              <ListItemText primary={data?.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default MultiSelectSuppliers;

const CustomMenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 220,
      overflowX: "auto", // Allow horizontal scrolling
      overflowX: "hidden", // Hide horizontal overflow
      scrollbarWidth: "thin", // Firefox
      msOverflowStyle: "none", // IE and Edge
      "&::-webkit-scrollbar": {
        height: "8px", // Adjust scrollbar height
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#888", // Scrollbar thumb color
        borderRadius: "4px", // Scrollbar thumb rounded corners
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#f1f1f1", // Scrollbar track color
      },
    },
  },
};
