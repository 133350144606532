/**
 * @author Abdul Rahim M-abdul@au79consulting.com
 * @date 03-12-2024
 * @description  Document Library API
 */
import actionType from "../actionTypes";
const documentLibraryAction = {
  //Document Library API
  get_df_reports: {
    api_name: "/ACM/intersections/user-dynamic-forms",
    https_method: "GET",
    action_type: actionType.GET_DF_REPORTS,
    description: "Get the Dynamic Forms In Reports",
  },
};

export default documentLibraryAction;
