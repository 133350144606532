/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 20-07-2024
 * @description : Custom dynamic form template component.
 */
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import UpdateIcon from "@mui/icons-material/Update";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { toast } from "react-toastify";
import { HandleApiActions, profileActions } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import ImageUploader from "./ImageUploader";
const DynamicFormTemplate = ({
  setFields,
  fieldValue,
  setFieldValue,

  fields,
  EDIT_TYPES,
  handleEditField,
  headerOptionsList,
  selectedHeaders,
  signatureOptionsList,
  selectedSignatures,
  footerOptionsList,
  selectedFooters,
  handleHeaderSubTitleChange,
  handleHeaderTitleChange,
  handleFieldChange,
  handleFooterTitleChange,
  handleFooterSubTitleChange,
  handleSignatureTitleChange,
  handleSignatureSubTitleChange,
}) => {
  const [isTouched1, setIsTouched1] = useState(false);
  const [isTouched2, setIsTouched2] = useState(false);
  const [isTouched3, setIsTouched3] = useState(false);
  const handleDelete = (sequence) => {
    const newData = fields?.filter((item) => item.sequence !== sequence);
    setFields(newData);
  };
  console.log("fields", fields);
  return (
    <Paper
      elevation={2}
      sx={{ border: "1px solid grey", borderRadius: 1, flexGrow: 1 }}
    >
      {/* Template Headers */}
      <Box sx={{ display: "flex", gap: 3 }}>
        {headerOptionsList?.map((item, index) => (
          //  Enter field name
          <Box
            key={index}
            sx={{
              visibility: selectedHeaders?.includes(item?.value)
                ? "visible"
                : "hidden",

              mt: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                paddingRight: "15px",
                flexDirection: "row",
              }}
            >
              <Box sx={{ flexGrow: 1, marginLeft: "15px" }}>
                {" "}
                {/* Make this section grow to fill remaining space */}
                <TextField
                  fullWidth
                  id="form-type"
                  variant="standard"
                  type="text"
                  size="small"
                  value={item?.title || ""}
                  onChange={(e) =>
                    handleHeaderTitleChange(item?.value, e.target.value)
                  }
                  label="Header Title*"
                  placeholder="Enter Header title"
                  helperText={
                    isTouched1 && item?.title?.length < 1
                      ? "Header Title should be at least 1 character"
                      : ""
                  }
                  error={isTouched1 && (!item?.title || item?.title.length < 1)} // Only show error if touched
                  onFocus={() => setIsTouched1(true)} // Mark as touched when the field is focused
                  sx={{
                    mt: -1,
                    mb: 1,
                  }}
                  autoComplete="off"
                />
                <TextField
                  required
                  helperText={
                    isTouched1 && item?.subTitle?.length < 1
                      ? "Header SubTitle should be at least 1 character"
                      : ""
                  }
                  error={
                    isTouched1 && (!item?.subTitle || item?.subTitle.length < 1)
                  } // Only show error if touched
                  onFocus={() => setIsTouched1(true)} // Mark as touched when the field is focused
                  fullWidth
                  id="form-type-subtitle"
                  variant="standard"
                  type="text"
                  size="small"
                  value={item?.subTitle}
                  onChange={(e) =>
                    handleHeaderSubTitleChange(item?.value, e.target.value)
                  }
                  label="Header Subtitle"
                  placeholder="Enter Header Subtitle"
                  // helperText={
                  //   item?.subTitle?.length < 5
                  //     ? "Subtitle should be at least 5 characters"
                  //     : ""
                  // }
                  //error={item?.subTitle?.length < 5}

                  sx={{
                    mb: 1,
                  }}
                  // inputProps={{
                  //   maxLength: 100,
                  // }}
                  autoComplete="off"
                />
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
      <Divider sx={{ border: "1px solid lightGrey" }} />
      {/* Template Fields */}
      <Box
        sx={{
          maxHeight: "200px",
          minHeight: "250px",
          m: 2.5,
          mb: 3.4,
          overflowY: "auto",
        }}
      >
        {fields
          ?.sort((a, b) => a?.sequence - b?.sequence)
          ?.map((field, index) => (
            <Box
              key={field?.sequence}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                mb: 1.4,
              }}
            >
              <Typography
                variant="subtitle1"
                color={
                  field?.edit_type === EDIT_TYPES?.DDH
                    ? "blue"
                    : field?.edit_type === EDIT_TYPES?.SNOW
                    ? "grey"
                    : "black"
                }
              >
                {`${field?.sequence}. ${field.field_name}: `}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  mt: -1,
                }}
              >
                <TextField
                  fullWidth
                  size="large"
                  variant="standard"
                  type={field?.default_value}
                  value={field?.value || fieldValue}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    console.log("Field value changing:", newValue);

                    setFields((prevFields) =>
                      prevFields?.map((item) =>
                        item?.sequence === field?.sequence
                          ? { ...item, value: newValue }
                          : item
                      )
                    );
                  }}
                  sx={{ ml: 1 }}
                  disabled
                  autoComplete="off"
                />
                <IconButton
                  size="small"
                  onClick={() => handleEditField(field?.sequence)}
                >
                  <EditIcon
                    sx={{
                      color:
                        field?.edit_type === EDIT_TYPES?.DDH
                          ? "blue"
                          : field?.edit_type === EDIT_TYPES?.SNOW
                          ? "grey"
                          : "black",
                    }}
                  />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => handleDelete(field?.sequence)}
                >
                  <DeleteIcon sx={{ color: "red" }} />
                </IconButton>
              </Box>
            </Box>
          ))}
      </Box>
      {/* Template Signature */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 2,
          gap: 4,
        }}
      >
        {signatureOptionsList?.map((item, index) => (
          //  Enter field name
          <Box
            key={index}
            sx={{
              visibility: selectedSignatures?.includes(item?.value)
                ? "visible"
                : "hidden",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center", // Vertically aligns items
                paddingRight: "15px",
              }}
            >
              {/* <Box
                sx={{ width: "10%", display: "flex", justifyContent: "center" }}
              >
                <Button
                  component="label"
                  role={undefined}
                  variant="text"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                >
                  <VisuallyHiddenInput
                    type="file"
                    onChange={handelUploadProfile}
                    multiple
                  />
                </Button>
              </Box> */}

              <Box sx={{ flexGrow: 1, marginLeft: "15px" }}>
                {" "}
                {/* Make this section grow to fill remaining space */}
                <TextField
                  label="Signature Title"
                  placeholder="Enter Signature title"
                  fullWidth
                  id="form-type"
                  variant="standard"
                  type={"text"}
                  size={"small"}
                  value={item?.title}
                  onChange={(e) =>
                    handleSignatureTitleChange(item?.value, e.target.value)
                  }
                  required
                  sx={{
                    mt: -1,
                    mb: 1,
                    width: "270px",
                  }}
                  // inputProps={{
                  //   maxLength: 100,
                  // }}
                  autoComplete="off"
                />
                <TextField
                  label="Signature Subtitle"
                  placeholder="Enter Signature subtitle"
                  fullWidth
                  required
                  id="form-type"
                  variant="standard"
                  type={"text"}
                  size={"small"}
                  sx={{
                    mb: 1,
                    width: "270px",
                  }}
                  // inputProps={{
                  //   maxLength: 100,
                  // }}
                  value={item?.subTitle}
                  onChange={(e) =>
                    handleSignatureSubTitleChange(item?.value, e.target.value)
                  }
                  autoComplete="off"
                />
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
      <Divider sx={{ border: "1px solid lightGrey" }} />
      {/* Template Footers */}
      <Box sx={{ display: "flex", gap: 2, mt: "10px" }}>
        {footerOptionsList?.map((item, index) => (
          //  Enter field name
          <Box
            key={index}
            sx={{
              visibility: selectedFooters?.includes(item?.value)
                ? "visible"
                : "hidden",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                paddingRight: "15px",
              }}
            >
              <Box sx={{ flexGrow: 1, marginLeft: "15px" }}>
                {" "}
                {/* Make this section grow to fill remaining space */}
                <TextField
                  fullWidth
                  id="form-type"
                  variant="standard"
                  type={"text"}
                  size={"small"}
                  value={item?.title}
                  label="Footer Title*"
                  placeholder="Enter Footer title"
                  onChange={(e) =>
                    handleFooterTitleChange(item?.value, e.target.value)
                  }
                  helperText={
                    isTouched3 && item?.title.length < 1
                      ? "Footer Title should be at least 1 character"
                      : ""
                  }
                  autoComplete="off"
                  error={isTouched3 && (!item?.title || item?.title.length < 1)} // Only show error if touched
                  onFocus={() => setIsTouched3(true)} // Mark as touched when the field is focused
                  sx={{
                    mt: -1,
                    mb: 1,
                    fontWeight: "bold",
                  }}
                  // inputProps={{
                  //   maxLength: 100,
                  // }}
                />
                <TextField
                  helperText={
                    isTouched3 && item?.subTitle.length < 1
                      ? "Footer SubTitle should be at least 1 character"
                      : ""
                  }
                  error={
                    isTouched3 && (!item?.subTitle || item?.subTitle.length < 1)
                  }
                  onFocus={() => setIsTouched3(true)}
                  fullWidth
                  label="Footer Subtitle"
                  placeholder="Enter Footer Subtitle"
                  id="form-type"
                  variant="standard"
                  type="text"
                  autoComplete="off"
                  size={"small"}
                  value={item?.subTitle}
                  required
                  sx={{
                    mb: 1,
                  }}
                  inputProps={{
                    maxLength: 100,
                  }}
                  onChange={(e) =>
                    handleFooterSubTitleChange(item?.value, e.target.value)
                  }
                />
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Paper>
  );
};

export default DynamicFormTemplate;

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
