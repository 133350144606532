import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";

import Collapse from "@mui/material/Collapse";

import Divider from "@mui/material/Divider";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";

import DeleteIcon from "@mui/icons-material/Delete";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import React, { useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import CustomButton from "../../components/buttons/CustomButton";
import CustomTextField1 from "../../components/text-fields/CustomTextField1";
import customConsole from "../../config/customConsole";

import CustomTextFieldSP from "../../components/text-fields/CustomTextFieldSP";
import { HandleApiActions } from "../../redux/actions/actionHandler";
import profileActions from "../../redux/actions/profile";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import adminSettingsAPI from "../../redux/actions/adminSetttingAPI";

import AddIcon from "@mui/icons-material/Add";
import { convertUtcToTimeZone } from "../../utils/convertUtcToTimeZone";
import CustomAddDialog from "../../components/dialog-boxes/CustomAddDialog";
import { Chip, DialogContentText, IconButton, TextField } from "@mui/material";
import CustomUpdateDialog from "../../components/dialog-boxes/CustomUpdateDialog";
import CustomConfirmationDialog from "../../components/dialog-boxes/CustomConfirmationDialog";
import {
  ChipsTextField,
  MultiSelectTextField2,
  SelectTextField,
} from "../../components";

function VehicleSystems({ open, handleClick }) {
  const dispatch = useDispatch();

  const vehicleSystemsDetails = useSelector(
    (state) => state.vehicleSystemsReducer
  );
  const timeZoneDetails = useSelector((state) => state.profileReducer);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [getVehicleSystems, setGetVehicleSystems] = React.useState([]);
  const [openAddVehicleSystemsDialog, setOpenAddVehicleSystemsDialog] =
    React.useState(false);
  const [openEditVehicleSystemsDialog, setOpenEditVehicleSystemsDialog] =
    React.useState(false);
  const [openDeleteVehicleSystemsDialog, setOpenDeleteVehicleSystemsDialog] =
    React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [timeZone, setTimeZone] = React.useState([]);
  const [tableRowsData, setTableRowsData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    dispatch(
      HandleApiActions({
        ...profileActions.get_coutry_regions,
      })
    );
  }, []);

  useEffect(() => {
    setTimeZone(timeZoneDetails?.profile?.profile?.region);
  }, [timeZoneDetails]);

  useEffect(() => {
    const vehicleSystemsList = getVehicleSystems?.map((item, index) => ({
      sno: index + 1,
      id: item?.id || index,
      name: item?.name,
      components: item?.components,
      created_at: convertUtcToTimeZone(
        item?.created_at,
        timeZoneDetails?.profile?.profile?.region
      ),
      updated_at: convertUtcToTimeZone(
        item?.updated_at,
        timeZoneDetails?.profile?.profile?.region
      ),
      allData: item,
    }));
    setTableRowsData(vehicleSystemsList);
  }, [getVehicleSystems]);

  // const getVehicleSystemsDetails = async () => {
  //   let apiDetails = adminSettingsAPI.get_vehicle_systems;
  //   delete apiDetails.action_type;

  //   const resp = await dispatch(
  //     HandleApiActions({
  //       ...apiDetails,
  //       params: {},
  //       show_toast: false,
  //     })
  //   );
  //   if (resp.error) {
  //     setGetVehicleSystems([]);
  //   } else {
  //     setGetVehicleSystems(resp.data);
  //   }
  // };

  useEffect(() => {
    dispatch(
      HandleApiActions({
        ...adminSettingsAPI.get_vehicle_systems,
      })
    );
  }, []);

  useEffect(() => {
    setGetVehicleSystems(
      vehicleSystemsDetails?.vehicleSystemsDetails?.data?.length
        ? vehicleSystemsDetails?.vehicleSystemsDetails?.data
        : []
    );
  }, [vehicleSystemsDetails]);

  const COLUMNS = [
    {
      field: "sno",
      headerName: "S.No",
      headerAlign: "left",
      width: 80,
    },
    { field: "name", headerName: "Name", headerAlign: "left", width: 220 },
    {
      field: "components",
      headerName: "components",
      headerAlign: "left",
      width: 350,
    },
    {
      field: "created_at",
      headerName: "Created At",
      headerAlign: "left",
      width: 180,
    },
    {
      field: "updated_at",
      headerName: "Updated At",
      headerAlign: "left",
      width: 180,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <IconButton
          size="small"
          color="error"
          onClick={(e) => {
            e.stopPropagation();
            handleTableRowClick(params, true);
          }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  const toggleDialog = (newOpen) => () => {
    setOpenAddVehicleSystemsDialog(newOpen);
  };

  const handleAddVehicleSystemsClick = () => {
    toggleDialog(true)();
  };

  const handleTableRowClick = (params, isButtonClicked) => {
    setSelectedRow(params);
    customConsole(params);
    if (isButtonClicked) {
      setOpenDeleteVehicleSystemsDialog(true);
    } else {
      setOpenEditVehicleSystemsDialog(true); // Open the dialog unconditionally
    }
  };

  const filteredRows = useMemo(() => {
    if (!searchQuery) return tableRowsData;
    return tableRowsData.filter((row) =>
      COLUMNS.some((column) =>
        row[column.field]
          ?.toString()
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, tableRowsData, COLUMNS]);

  return (
    <div>
      <List
        sx={{
          width: "100%",
          maxWidth: "100%",
          marginTop: "15px",
          backgroundColor: "whitesmoke",
          boxShadow: "10px 10px 30px rgba(0, 0, 0, 0.2)",
          borderRadius: "0px",
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <ListItemButton onClick={() => handleClick("9")}>
          <ListItemText
            sx={{
              textAlign: "start",
              color: "black",
            }}
            primary="Vehicle Systems"
            primaryTypographyProps={{
              fontWeight: 600,
              fontSize: "18px",
            }}
          />
          {open === "9" ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={open === "9"} timeout="auto" unmountOnExit>
          <Divider sx={{ margin: "10px" }} />
          <List
            component="div"
            disablePadding
            sx={{
              background: "whitesmoke",
              margin: "10px",
              padding: "10px",
              borderRadius: "10px",

              maxHeight: "500px",
            }}
          >
            <Box>
              <Paper sx={styles.container}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: "60px",
                    mr: "10px",
                    ml: "10px",
                  }}
                >
                  <Box>
                    <TextField
                      variant="standard"
                      label="Search"
                      size="small"
                      margin="normal"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      sx={{ margin: 0 }}
                    />
                  </Box>

                  <Box sx={styles.tblFun}>
                    <CustomButton
                      size={"medium"}
                      variant={"contained"}
                      btnName={"Add"}
                      startIcon={<AddIcon />}
                      handleOnClick={handleAddVehicleSystemsClick}
                      btnStyle={{
                        ml: 2,
                        // borderRadius: "0px",
                      }}
                    />
                  </Box>
                </Box>
                <div
                  style={{
                    height: 400,
                    width: "100%",
                    scrollbarColor: "#888 #f1f1f1",
                  }}
                >
                  <DataGrid
                    initialState={{
                      density: "comfortable",
                    }}
                    sx={{
                      "& .MuiDataGrid-scrollbar.MuiDataGrid-scrollbar--horizontal.css-1rtad1":
                        {
                          display: "block",
                        },
                      "& .MuiDataGrid-columnHeaders": {
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        backgroundColor: "white",
                        "& .MuiDataGrid-columnHeaderTitle": {
                          fontWeight: "bold",
                        },
                      },
                    }}
                    localeText={{ toolbarDensity: "View" }}
                    // autoHeight={true}
                    rows={filteredRows || []}
                    columns={COLUMNS}
                    disableColumnMenu
                    disableColumnSelector
                    disableColumnFilter
                    disableRowSelectionOnClick
                    slots={{ toolbar: GridToolbar }}
                    pageSize={rowsPerPage}
                    slotProps={{
                      toolbar: {
                        printOptions: { disableToolbarButton: true },
                      },
                    }}
                    rowCount={
                      tableRowsData?.length >= rowsPerPage
                        ? -1
                        : page * rowsPerPage + tableRowsData?.length
                    }
                    onPaginationModelChange={(model) => {
                      setPage(model.page);
                      setRowsPerPage(model.pageSize);
                    }}
                    paginationModel={{ page: page, pageSize: rowsPerPage }}
                    pageSizeOptions={[25, 50, 100]}
                    onRowClick={(params) => handleTableRowClick(params.row)}
                  />
                </div>
                <AddVehicleSystems
                  open={openAddVehicleSystemsDialog}
                  toggleDialog={toggleDialog}
                />
                <EditVehicleSystems
                  open={openEditVehicleSystemsDialog}
                  setOpenEditVehicleSystemsDialog={
                    setOpenEditVehicleSystemsDialog
                  }
                  selectedRow={selectedRow}
                />
                <DeleteVehicleSystems
                  openDeleteVehicleSystemsDialog={
                    openDeleteVehicleSystemsDialog
                  }
                  setOpenDeleteVehicleSystemsDialog={
                    setOpenDeleteVehicleSystemsDialog
                  }
                  selectedRow={selectedRow}
                />
              </Paper>
            </Box>
          </List>
        </Collapse>
      </List>
    </div>
  );
}

export default VehicleSystems;

// Styling for the Users component
const styles = {
  container: {
    width: "100%",
    overflow: "auto",
  },
  tblFun: {
    mt: 2,
    mb: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
};

const AddVehicleSystems = ({ open, toggleDialog }) => {
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [components, setComponents] = useState([]);
  const [componentInput, setComponentInput] = useState("");
  const [componentsError, setComponentsError] = useState(false);

  const handleClose = () => {
    setName("");
    setComponents([]);
    setComponentInput("");
    toggleDialog(false)();
  };

  const handleClickAdd = () => {
    const reqObj = {
      current_time: new Date(),
      name: name,
      components: components,
    };

    dispatch(
      HandleApiActions({
        ...adminSettingsAPI.add_vehicle_systems,
        params: reqObj,
        show_toast: true,
      })
    ).then(() => {
      dispatch(
        HandleApiActions({
          ...adminSettingsAPI.get_vehicle_systems,
        })
      );
    });

    // Reset form fields
    setName("");
    setComponents([]);
    setComponentInput("");
    toggleDialog(false)();
  };

  const handleAddComponent = () => {
    if (componentInput && !components.includes(componentInput.trim())) {
      setComponents((prev) => [...prev, componentInput.trim()]);
      setComponentInput(""); // Reset the input after adding
    }
  };

  const handleDeleteComponent = (componentToDelete) => {
    setComponents((prev) =>
      prev.filter((component) => component !== componentToDelete)
    );
  };

  return (
    <>
      <CustomAddDialog
        open={open}
        onClose={handleClose}
        dialogTitle={"Add Vehicle Systems"}
        dialogContent={
          <Stack spacing={2}>
            <CustomTextFieldSP
              required
              size={"small"}
              label={"Name"}
              placeholder={"Name"}
              value={name}
              validationRules={{ minLength: 3 }}
              setValue={setName}
              txtFldStyle={{
                width: "270px",
              }}
              onChange={(e) => {
                setName(e.target.value);
                setNameError(!e.target.value.trim());
              }}
              error={nameError}
              setError={setNameError}
            />
            <ChipsTextField
              size={"small"}
              label={"Components"}
              placeholder={"Components"}
              variant={"standard"}
              chips={components}
              setChips={setComponents}
              txtFldStyle={styles.chipsTextField}
            />
          </Stack>
        }
        onConfirm={handleClickAdd}
        onDisabled={name.length > 2 && components.length > 0 ? false : true}
      />
    </>
  );
};

const EditVehicleSystems = ({
  open,
  setOpenEditVehicleSystemsDialog,
  selectedRow,
}) => {
  const dispatch = useDispatch();

  // Form states

  const [id, setId] = useState();
  const [editName, setEditName] = useState("");
  const [editComponents, setEditComponents] = useState([]);

  // Populate fields when `selectedRow` changes
  useEffect(() => {
    if (selectedRow) {
      setId(selectedRow?.id || "");
      setEditName(selectedRow?.name || "");
      setEditComponents(selectedRow?.components || []);
    }
  }, [selectedRow]);

  // Handle dialog close
  const handleClose = () => {
    setOpenEditVehicleSystemsDialog(false);
  };

  // Handle the edit submission
  const handleClickEdit = async () => {
    // Create the request object
    const reqObj = {
      id: Number(id),
      components: editComponents,
      current_time: new Date().toISOString(),
    };

    // Dispatch the update vehicle systems API action
    dispatch(
      HandleApiActions({
        ...adminSettingsAPI.update_vehicle_systems,
        params: reqObj,
        show_toast: true,
      })
    ).then(() => {
      dispatch(
        HandleApiActions({
          ...adminSettingsAPI.get_vehicle_systems,
        })
      );
    });
    handleClose();
  };
  console.log("selectedRow", selectedRow?.components);
  return (
    <CustomUpdateDialog
      open={open}
      onClose={handleClose}
      dialogTitle={"Edit Vehicle Systems"}
      dialogContent={
        <Stack spacing={2}>
          {/* Name field - Disabled for editing */}
          <CustomTextField1
            size={"small"}
            label={"Name"}
            placeholder={"Name"}
            value={editName}
            setValue={setEditName}
            txtFldStyle={{
              width: "270px",
            }}
            disabled={true} // Name is not editable
          />

          <ChipsTextField
            required
            size={"small"}
            label={"Components"}
            placeholder={"Select components"}
            chips={editComponents}
            setChips={setEditComponents}
            sltFldStyle={{
              textAlign: "start",
              width: "100%",
              ml: "10px",
              mt: "5px",
              backgroundColor: "white",
            }}
          />
        </Stack>
      }
      onConfirm={handleClickEdit} // Trigger the edit action
    />
  );
};

const DeleteVehicleSystems = ({
  openDeleteVehicleSystemsDialog,
  selectedRow,
  setOpenDeleteVehicleSystemsDialog,
}) => {
  const dispatch = useDispatch();
  const handleDelete = () => {
    const reqObj = {
      current_time: new Date(),
      id: parseInt(selectedRow?.id),
    };
    customConsole("reqObj");
    customConsole(reqObj);
    dispatch(
      HandleApiActions({
        ...adminSettingsAPI.delete_vehicle_systems,
        params: reqObj,
        show_toast: true,
      })
    ).then(() => {
      dispatch(
        HandleApiActions({
          ...adminSettingsAPI.get_vehicle_systems,
        })
      );
    });
    setOpenDeleteVehicleSystemsDialog(false);
  };
  return (
    <CustomConfirmationDialog
      open={openDeleteVehicleSystemsDialog}
      onClose={() => setOpenDeleteVehicleSystemsDialog(false)}
      dialogTitle={"Confirm Delete"}
      dialogContent={
        <DialogContentText>Are you sure want to delete?</DialogContentText>
      }
      onConfirm={handleDelete}
    />
  );
};
