import React from "react";
import { Box, Button, Typography } from "@mui/material";

const SortByOptionsTickets = ({
  selectColumn,
  selectOrder,
  setSelectColumn,
  setSelectOrder,
}) => {
  const sortOptions = [
    { key: "CREATED AT", value: "created_at" },
    { key: "UPDATED AT", value: "updated_at" },
    { key: "INTR ID", value: "intr_id" },
    { key: "TICKET ID", value: "ticket_id" },
    { key: "STATUS", value: "status" },
  ];

  const handleSortChange = (value) => {
    setSelectColumn(value);
    setSelectOrder(
      value === selectColumn && selectOrder === "ASC" ? "DESC" : "ASC"
    );
  };

  return (
    <Box
      sx={{
        boxShadow: 3,
        padding: "10px",
        m: "10px",
        display: "flex",
        justifyContent: "flex-start",
        gap: 3,
      }}
    >
      <Box sx={{ marginTop: "6px" }}>
        <Typography sx={{ fontWeight: "bold" }}>Sort By:</Typography>
      </Box>

      {sortOptions.map((item) => {
        const buttonText = item.key;
        const isSelected = selectColumn === item.value;
        const icon = isSelected ? (selectOrder === "ASC" ? " ▲" : " ▼") : "";

        return (
          <Button
            value={item.value}
            sx={{
              color: isSelected
                ? selectOrder === "ASC"
                  ? "green"
                  : "red"
                : "",
            }}
            onClick={() => handleSortChange(item.value)}
            key={item.value}
            variant="text"
          >
            {buttonText}
            {icon}
          </Button>
        );
      })}
    </Box>
  );
};

export default SortByOptionsTickets;
