/**
 * @author : Bharathan - bharathan@au79consulting.com
 * @Date : 05-11-2024
 * @description : Link Vehicle Component to Legal Requirement.
 */
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import customConsole from "../../../config/customConsole";
import {
  HandleApiActions,
  legalRequirements,
  projectConfigAPI,
} from "../../../redux/actions";
import {
  CustomAddDialog,
  CustomButton,
  CustomTextFieldN,
  SelectDynamicForm,
  SelectTextFieldN,
} from "../../../components";
import typeApprovalAPI from "../../../redux/actions/typeApprovalAPI";
import LinkedVehicleToLegalTable from "./LinkedVehicleToLegalTable";
import CustomFiltersN from "../../../components/tables/CustomFiltersN";
import { FORM_TYPES } from "../../../config/appConstants";
import CustomUserDetailsMultiSelect from "../../../components/text-fields/CustomUserDetailsMultiSelect";
import MultiSelectFieldVariants from "../../../components/text-fields/MultiSelectFieldVariants";
import MultiSelectUsers from "../../../components/text-fields/MultiSelectUsers";

function LinkVehicleToLegal({
  setIntersectionDetailsNew,
  setIsVehicleToLegalIntersection,
  selectedProjectId,
  setTabValue,
  setShowIntersDelTicket,
  selectedSingleVariantDetails,
  variantOptionsList,
  selectOEMUsers,
  setSelectOEMUsers,
  selectTechUsers,
  setSelectTechUsers,
  selectSupplierUsers,
  setSelectSupplierUsers,
  selectedVariantsMany,
  setSelectedVariantsMany,
  UserDetails,
}) {
  /* Dispatch */
  const dispatch = useDispatch();

  // -------------Bharathan-----------------

  const variantDetails = useSelector((state) => state.variantReducer);

  // -------------Bharathan-----------------

  const { projectDetails, selectedProject, selectedVariant } = useSelector(
    (state) => state.labCarReducer,
    shallowEqual
  );

  /* Get data from the redux store  */

  const [vehicleCompList, setVehicleCompList] = useState([]);

  const [variantFilteredDetails, setVariantFilteredDetails] = useState([]);
  const [selectedVariantDetails, setSelectedVariantDetails] = useState(null);

  /* Legal Standards */
  const [legalStandardsList, setLegalStandardsList] = useState([]);
  const [legalStandardsOptionsList, setLegalStandardsOptionsList] = useState(
    []
  );
  const [selectedLegalStandardId, setSelectedLegalStandardId] = useState(null);

  /* Legal Standards Chapters */
  const [lsChaptersOptionsList, setLSChaptersOptionsList] = useState([]);
  const [selectedLSChapter, setLSelectedLSChapter] = useState(null);

  /* Legal Requirements */
  const [legalRequirementsList, setLegalRequirementsList] = useState([]);

  /* Selected Legal Requirements States */
  const [selectedLegalRequirementsIds, setSelectedLegalRequirementsIds] =
    useState([]);
  const [isAddDescDialogOpen, setIsAddDescDialogOpen] = useState(false);
  const [currentLegalRequirement, setCurrentLegalRequirement] = useState(null);
  const [currentDescription, setCurrentDescription] = useState("");
  const [vehicleLRIntersectionsDetails, setVehicleLRIntersectionsDetails] =
    useState([]);

  // Filtering
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedSearchField, setSelectedSearchField] = useState("module_type");
  const [searchFieldValue, setSearchFieldValue] = useState(3);

  // DynamicForm
  const [isDynamicFormDialogOpen, setIsDynamicFormDialogOpen] = useState(false);
  const [isFormRequired, setIsFormRequired] = useState(false);
  const [dynamicFormFilteredData, setDynamicFormFilteredData] = useState(null);

  const [selectedVehicleComponentIds, setSelectedVehicleComponentIds] =
    useState([]);

  const vehicleList = vehicleCompList?.[0] || []; // Extract the actual array
  const selectedVehicleComponents = vehicleList?.filter((vehicle) =>
    selectedVehicleComponentIds?.includes(vehicle?.id)
  );

  console.log("vehicleCompList", vehicleCompList);
  console.log("vehicleList", vehicleList); // Check extracted data
  console.log("selectedVehicleComponentIds", selectedVehicleComponentIds);
  console.log("selectedVehicleComponents", selectedVehicleComponents);

  /* Dispatch Functions */

  // -------------Bharathan-----------------

  useEffect(() => {
    if (variantDetails?.variantInfo?.length > 0) {
      const allVariantsDetails = variantDetails?.variantInfo?.filter(
        (data) => data?.project_id === selectedProject?.value
      );
      console.log("allVariantsDetails", allVariantsDetails);

      setSelectedVariantDetails(allVariantsDetails);
    }
  }, [variantDetails, selectedProject]);

  useEffect(() => {
    const fetchVariantDetails = async () => {
      if (selectedProject) {
        try {
          const resp = await dispatch(
            HandleApiActions({
              ...projectConfigAPI.get_variant_details,
              params: {
                project_id: selectedProject?.value,
                variant_id: selectedSingleVariantDetails?.variant_id,
                is_complete_details_required: true,
              },
            })
          );
          if (resp?.data) {
            setVehicleCompList(
              resp?.data?.map((vehicle) => vehicle?.vehicle_components)
            );
          }
        } catch (error) {
          console.error("Error fetching variant details:", error);
        }
      }
    };

    fetchVariantDetails();
  }, [selectedSingleVariantDetails?.variant_id]);

  // -------------Bharathan-----------------

  /* Get Legal Standards List Function */
  const getLegalStandardsList = async () => {
    let apiDetails = legalRequirements.get_legal_standard_details_new;

    const resp = await dispatch(
      HandleApiActions({
        ...apiDetails,
        params: {
          page_number: 1,
          page_size: 250,
          ...(Number(searchFieldValue) && {
            [selectedSearchField]: searchFieldValue,
          }),
        },
        show_toast: false,
      })
    );

    if (resp.error) {
      setLegalStandardsList([]);
    } else {
      setLegalStandardsList(resp.data);
    }
  };

  /* Get Legal Requirements List Function */
  const getLegalRequirementsList = async ({
    legalStandardId,
    legalStandardChapter,
  }) => {
    let apiDetails = legalRequirements.get_legal_requirements_details_new;

    const resp = await dispatch(
      HandleApiActions({
        ...apiDetails,
        params: {
          ls_id: legalStandardId,
          chapter: legalStandardChapter,
          page_number: 1,
          page_size: 250,
        },
        show_toast: false,
      })
    );

    if (resp.error) {
      setLegalRequirementsList([]);
    } else {
      setLegalRequirementsList(resp.data);
    }
  };

  // Handle Add Description Dialog Close Function
  const handleAddDescDialogClose = (confirm) => {
    if (confirm && currentDescription) {
      // Proceed with adding legal requirement
      handleAddLegalRequirement(
        currentLegalRequirement,
        currentDescription,
        dynamicFormFilteredData
      );
    } else {
      // Uncheck the checkbox if no description is provided
      setSelectedLegalRequirementsIds((prevSelectedIds) =>
        prevSelectedIds.filter((id) => id !== currentLegalRequirement.lr_id)
      );
    }
    setCurrentDescription("");
    setCurrentLegalRequirement(null);
    setIsAddDescDialogOpen(false);

    // Dynamic form
    // setIsFormRequired(false);
  };

  /* Handle Add Legal Requirement Function */

  const handleAddLegalRequirement = (
    legalRequirement,
    description,
    dynamicFormFilteredData
  ) => {
    const { lrId, vc_id } = legalRequirement;

    setVehicleLRIntersectionsDetails((prevDetails) => {
      const existingEntry = prevDetails?.find((entry) => entry?.lr_id === lrId);

      if (existingEntry) {
        // Update vc_ids array, ensuring all values are numbers and removing duplicates
        const updatedVcIds = Array.from(
          new Set([
            ...existingEntry.vc_ids,
            ...(Array.isArray(vc_id) ? vc_id.map(Number) : [Number(vc_id)]),
          ])
        );

        return prevDetails?.map((entry) =>
          entry?.lr_id === lrId
            ? {
                ...entry,
                vc_ids: updatedVcIds,
                description: description || entry.description,
              }
            : entry
        );
      } else {
        return [
          ...prevDetails,
          {
            lr_id: lrId,
            vc_ids: Array.isArray(vc_id) ? vc_id.map(Number) : [Number(vc_id)],
            description: description || "",
            oem_users: selectOEMUsers,
            tech_service_users: selectTechUsers,
            suppliers: selectSupplierUsers,
          },
        ];
      }
    });
  };

  // const handleAddLegalRequirement = (legalRequirement, description) => {
  //   const { vc_id, lr_id } = legalRequirement;

  //   setSelectedVehicleComponentIds((prevSelectedIds) => {
  //     // Find an existing entry with the same lr_id
  //     const existingEntry = prevSelectedIds.find(
  //       (entry) => entry.lr_id === Number(lr_id)
  //     );

  //     if (existingEntry) {
  //       // Check if the vc_id(s) already exist in the vc_ids array
  //       const vcIdsToAdd = Array.isArray(vc_id) ? vc_id.map(Number) : [Number(vc_id)];
  //       const uniqueVcIds = [
  //         ...new Set([...existingEntry.vc_id, ...vcIdsToAdd]), // Merge arrays and remove duplicates
  //       ];

  //       return prevSelectedIds.map((entry) =>
  //         entry.lr_id === Number(lr_id)
  //           ? {
  //               ...entry,
  //               vc_id: uniqueVcIds, // Update the vc_ids array
  //             }
  //           : entry
  //       );
  //     }

  //     // If no entry exists for the lr_id, create a new entry
  //     return [
  //       ...prevSelectedIds,
  //       {
  //         vc_id: Array.isArray(vc_id) ? vc_id.map(Number) : [Number(vc_id)], // Initialize as an array
  //         lr_id: Number(lr_id), // Ensure lr_id is a number
  //         description, // Add description
  //       },
  //     ];
  //   });
  // };

  const handleSelectLegalRequirements = (event, vc_id, lr_id) => {
    const lrId = Number(lr_id);

    if (event.target.checked) {
      setCurrentLegalRequirement({
        lrId,
        vc_id: Array.isArray(vc_id) ? vc_id.map(Number) : [Number(vc_id)],
      });
      setIsAddDescDialogOpen(true);

      // Temporarily Add lr_id to the selected ids list
      setSelectedLegalRequirementsIds((prevSelectedIds) => [
        ...prevSelectedIds,
        lrId,
      ]);
    } else {
      setSelectedLegalRequirementsIds((prevSelectedIds) =>
        prevSelectedIds.filter((id) => id !== lrId)
      );

      setVehicleLRIntersectionsDetails((prevDetails) =>
        prevDetails
          ?.map((entry) => {
            if (entry?.lr_id === lrId) {
              const updatedVcIds = entry?.vc_ids?.filter(
                (id) => !vc_id.includes(Number(id))
              );
              return {
                ...entry,
                vc_ids: updatedVcIds,
              };
            }
            return entry;
          })
          ?.filter((entry) => entry?.vc_ids?.length > 0)
      );
    }
  };

  // const handleSelectLegalRequirements = (event, vc_id, lr_id) => {
  //   // Ensure vc_id is treated as a number
  //   const vcId = Number(vc_id);

  //   if (event.target.checked) {
  //     setCurrentLegalRequirement({ vc_id: vcId, lr_id });

  //     // Open the dialog to add a description
  //     setIsAddDescDialogOpen(true);

  //     setSelectedVehicleComponentIds((prevSelectedIds) => {
  //       // Check if lr_id already exists in selectedVehicleComponentIds
  //       const existingEntry = prevSelectedIds?.find((item) => item?.lr_id === lr_id);

  //       if (existingEntry) {
  //         // If lr_id exists, add vc_id to the vc_ids array
  //         return prevSelectedIds?.map((item) =>
  //           item?.lr_id === lr_id
  //             ? { ...item, vc_ids: [...new Set([...item.vc_ids, vcId])]} // Use Set to avoid duplicates
  //             : item
  //         );
  //       } else {
  //         // If lr_id does not exist, create a new entry with vc_id in an array
  //         return [
  //           ...prevSelectedIds,
  //           { lr_id: lr_id, vc_ids: [vcId] },
  //         ];
  //       }
  //     });

  //     setSelectedLegalRequirementsIds((prevSelectedIds) => [
  //       ...prevSelectedIds,
  //       lr_id,
  //     ]);
  //   } else {
  //     // Remove vc_id from the corresponding lr_id entry if unchecked
  //     setSelectedVehicleComponentIds((prevSelectedIds) =>
  //       prevSelectedIds
  //         ?.map((item) =>
  //           item?.lr_id === lr_id
  //             ? { ...item, vc_ids: item?.vc_ids?.filter((id) => id !== vcId) }
  //             : item
  //         )
  //         ?.filter((item) => item?.vc_ids?.length > 0) // Remove entries with empty vc_ids arrays
  //     );

  //     setSelectedLegalRequirementsIds((prevSelectedIds) =>
  //       prevSelectedIds?.filter((id) => id !== lr_id)
  //     );
  //   }
  // };

  // Handle linking vehicle components to legal requirements and dispatching payload
  const handleLinkVehicleComponentsToLegalRequirements = async () => {
    if (selectedProjectId && vehicleLRIntersectionsDetails?.length) {
      const apiDetails = typeApprovalAPI.get_vehicle_add_intersection;

      // Prepare intersections payload ensuring vc_ids is valid
      const intersectionsPayload = vehicleLRIntersectionsDetails
        .filter(({ vc_ids }) => vc_ids?.length > 0) // Ensure vc_ids contains valid values
        .map(({ vc_ids, lr_id, description }) => ({
          vc_ids: vc_ids.filter((id) => id !== null && id !== undefined), // Filter out invalid values
          lr_id,
              oem_users: selectOEMUsers,
              tech_service_users: selectTechUsers,
              suppliers: selectSupplierUsers,
          description: description || "",
          ...(isFormRequired ? { dynamic_form: dynamicFormFilteredData } : {}),
        }));

      const payload = {
        project_id: Number(selectedProjectId),
        variant_id: Number(selectedSingleVariantDetails?.variant_id),
        intersections: intersectionsPayload,
        save_as_draft: false,
        create_intersections: false,
      };

      customConsole({
        ...apiDetails,
        params: payload,
        show_toast: true,
      });

      const resp = await dispatch(
        HandleApiActions({
          ...apiDetails,
          params: payload,
          show_toast: true,
        })
      );

      if (resp.error) {
        setIntersectionDetailsNew([]);
      } else {
        setIntersectionDetailsNew(resp?.data);
        setIsVehicleToLegalIntersection(false);
        setTabValue(1);
        setShowIntersDelTicket(false);
      }
    }
  };

  // useEffect(()=>{
  //   console.log("selectedVehicleComponentIds",selectedVehicleComponentIds)
  //  },[selectedVehicleComponentIds])

  //  useEffect(()=>{
  //   console.log("vehicleLRIntersectionsDetails",vehicleLRIntersectionsDetails)
  //  },[vehicleLRIntersectionsDetails])

  // Open filter menu
  const handleFilterClick = (event) => {
    setAnchorElFilter(event.currentTarget);
    setOpenFilter(true);
  };

  // Handle filter close
  const handleFilterClose = () => {
    setAnchorElFilter(null);
    setOpenFilter(false);
  };

  // Handle filter change
  const handleFilterChange = (searchField, searchValue) => {
    setSelectedSearchField(searchField);
    setSearchFieldValue(searchValue);
    handleFilterClose();
  };

  // Reset the filter values
  const handleFilterResetAll = () => {
    handleFilterChange("module_type", 3);
  };

  // Apply filtering
  const handleFilterApplyNow = (searchField, searchValue) => {
    handleFilterChange(searchField, searchValue);
  };

  /* useEffects */
  useEffect(() => {
    // Get the Legal Standards List
    getLegalStandardsList();
  }, [selectedSearchField, searchFieldValue]);

  useEffect(() => {
    // When the selected project is changed, make the states to default
    setSelectedLegalRequirementsIds([]);
    setCurrentLegalRequirement(null);
    setCurrentDescription("");
  }, [selectedProjectId, selectedSingleVariantDetails?.variant_id]);

  useEffect(() => {
    /* Get Legal Standards Options List */
    let filteredLegalStandardsOptionsList = legalStandardsList
      // ?.filter((ls) => ls?.module_type == 2)
      ?.map((el) => {
        return {
          value: el?.ls_id,
          label: el?.standard_number,
        };
      });
    //   ?.sort((a, b) => a.label?.localeCompare(b.label));
    setLegalStandardsOptionsList(filteredLegalStandardsOptionsList);
    /* Select Legal Standards by default */
    if (filteredLegalStandardsOptionsList?.[0]?.value) {
      setSelectedLegalStandardId(filteredLegalStandardsOptionsList?.[0]?.value);
    }
  }, [legalStandardsList]);

  useEffect(() => {
    if (legalStandardsList) {
      // Set the LS Chapters Options List
      let filteredLSChaptersOptionsList = legalStandardsList
        ?.find((ls) => ls?.ls_id == selectedLegalStandardId)
        ?.chapters?.map((el) => {
          return {
            value: el,
            label: el,
          };
        })
        ?.sort((a, b) => a.label.localeCompare(b.label));
      setLSChaptersOptionsList(filteredLSChaptersOptionsList);

      /* Select first LS Chapter by default */
      if (filteredLSChaptersOptionsList?.[0]?.value) {
        setLSelectedLSChapter(filteredLSChaptersOptionsList?.[0]?.value);
      }
    }
  }, [selectedLegalStandardId]);

  useEffect(() => {
    /* Get Legal Requirements List, when the selected project and LS Chapter is changed  */
    if (selectedProjectId && selectedLegalStandardId && selectedLSChapter) {
      getLegalRequirementsList({
        legalStandardId: selectedLegalStandardId,
        legalStandardChapter: selectedLSChapter,
      });
    }
  }, [selectedProjectId, selectedLSChapter]);

  useEffect(() => {
    const filteredVariant = selectedVariantDetails?.find(
      (data) => data?.variant_id === selectedVariant?.value
    );
    setVariantFilteredDetails(filteredVariant);
  }, [selectedVariantDetails, selectedVariant]);

  // ---------------Bharathan----------------

  // Function to handle checkbox change for vehicle components
  const handleVehicleComponentCheckboxChange = (componentId) => {
    setSelectedVehicleComponentIds((prevSelected) => {
      const updatedSelection = prevSelected?.includes(componentId)
        ? prevSelected?.filter((id) => id !== componentId)
        : [...prevSelected, componentId];
      console.log("updatedSelection", updatedSelection);
      return updatedSelection;
    });
  };
  // ---------------Bharathan----------------

  useEffect(() => {
    console.log("vehicleLRIntersectionsDetails", vehicleLRIntersectionsDetails);
    console.log("selectedLegalRequirementsIds", selectedLegalRequirementsIds);
    console.log("selectedVehicleComponentIds", selectedVehicleComponentIds);
    console.log("legalRequirementsList", legalRequirementsList);
    console.log("variantFilteredDetails", variantFilteredDetails);
  }, [
    vehicleLRIntersectionsDetails,
    selectedLegalRequirementsIds,
    selectedVehicleComponentIds,
    legalRequirementsList,
    variantFilteredDetails,
  ]);

  return (
    <Box>
      {/* Link button */}
      <Box sx={styles.customBtnContainer}>
        <CustomButton
          size={"small"}
          variant={"contained"}
          btnName={"Link"}
          disabled={
            !(selectedProjectId && vehicleLRIntersectionsDetails?.length)
          }
          handleOnClick={handleLinkVehicleComponentsToLegalRequirements}
          btnStyle={styles.customButton}
        />
        <CustomFiltersN
          anchorEl={anchorElFilter}
          open={openFilter}
          handleClose={handleFilterClose}
          mainOptionsList={[
            {
              value: "module_type",
              label: "Module Type",
              type: "select",
              value_options_list: [
                { value: 1, label: "Software Module" },
                { value: 2, label: "EE Component" },
                { value: 3, label: "Vehicle Component" },
              ],
            },
          ]}
          selectedMainOption={selectedSearchField}
          selectedMainOptionValue={searchFieldValue}
          handleFilterClick={handleFilterClick}
          onReset={handleFilterResetAll}
          onApplyNow={handleFilterApplyNow}
        />
      </Box>
      {/* Vehicle Components linking */}
      <Grid container spacing={4}>
        <Grid item xs={12} md={6} sx={styles.grid}>
          <Box sx={{ mb: 9.5 }}></Box>
          {/* Vehicle Components card */}
          <Card sx={styles.card}>
            <CardHeader
              sx={styles.cardHeader}
              title={
                <Typography
                  textAlign={"center"}
                  fontWeight={600}
                  variant="subtitle1"
                >
                  {"VEHICLE COMPONENTS"}
                </Typography>
              }
            />
            <CardContent sx={styles.eeCompCardContent}>
              {variantFilteredDetails?.vehicle_components?.length > 0 ? (
                variantFilteredDetails?.vehicle_components
                  ?.filter((data) => data?.id !== null)
                  ?.map((data) => (
                    <Box
                      key={data?.id}
                      sx={{
                        ...styles.cardItems,
                        "&:hover": {
                          // backgroundColor: "lightgrey",
                        },
                      }}
                    >
                      <Box sx={styles.eeCompsListItemContainer}>
                        <Typography variant="body2">
                          <strong>Component Name:</strong> {data?.component}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Assembly No:</strong> {data?.assembly_number}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Summary:</strong> {data?.summary}
                        </Typography>
                      </Box>
                      <Box>
                        <Checkbox
                          checked={selectedVehicleComponentIds?.includes(
                            data?.id
                          )}
                          onChange={() =>
                            handleVehicleComponentCheckboxChange(data?.id)
                          }
                          // sx={{
                          //   color: "primary.main",
                          //   "&.Mui-disabled": {
                          //     color: "grey.500",
                          //     "& .MuiSvgIcon-root": {
                          //       fill: "grey.500",
                          //     },
                          //   },
                          // }}
                        />
                      </Box>
                    </Box>
                  ))
              ) : (
                <Typography variant="body1" sx={styles.noDataAvailable}>
                  No data available
                </Typography>
              )}
            </CardContent>
            <CardActions sx={styles.cardActions}></CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} sx={styles.grid}>
          {/* Select fields */}
          <Box sx={{ mb: 3 }}>
            <SelectTextFieldN
              size={"small"}
              label={"Select Legal Standards"}
              variant={"standard"}
              optionsList={legalStandardsOptionsList}
              selectedValue={selectedLegalStandardId}
              handleOnChange={(e) => setSelectedLegalStandardId(e.target.value)}
              sltFldStyle={styles.selectTxtStyle}
            />
            <SelectTextFieldN
              size={"small"}
              label={"Select Tag/Chapter"}
              variant={"standard"}
              optionsList={lsChaptersOptionsList}
              selectedValue={selectedLSChapter}
              disabled={!legalStandardsList}
              handleOnChange={(e) => setLSelectedLSChapter(e.target.value)}
              sltFldStyle={{
                ...styles.selectTxtStyle,
                ml: 2,
              }}
            />
          </Box>
          {/* EE Components card */}
          <Card sx={styles.card}>
            <CardHeader
              sx={styles.cardHeader}
              title={
                <Typography
                  textAlign={"center"}
                  fontWeight={600}
                  variant="subtitle1"
                >
                  {"LEGAL REQUIREMENTS"}
                </Typography>
              }
            />
            <CardContent sx={styles.cardContent}>
              {legalRequirementsList && legalRequirementsList.length > 0 ? (
                legalRequirementsList
                  .filter((el) => el.chapter === selectedLSChapter)
                  .map((item, index) => (
                    <Box key={index} sx={styles.cardItems}>
                      <Box sx={styles.cardItemsTxtContainer}>
                        <Typography variant="body2">
                          <strong>Req_No:</strong> {item.req_number}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Description:</strong> {item.description}
                        </Typography>
                      </Box>
                      <Box>
                        <Checkbox
                          checked={
                            vehicleLRIntersectionsDetails?.some(
                              (detail) =>
                                Number(detail?.lr_id) === Number(item?.lr_id)
                            ) ||
                            selectedLegalRequirementsIds?.includes(
                              Number(item?.lr_id)
                            ) ||
                            selectedVehicleComponentIds?.some((selectedId) => {
                              const flatVehicleCompList =
                                vehicleCompList.flat();
                              const matchingComponent =
                                flatVehicleCompList?.find(
                                  (comp) => comp.id === selectedId
                                );
                              const isLinked =
                                Array.isArray(
                                  matchingComponent?.linked_lr_ids
                                ) &&
                                matchingComponent.linked_lr_ids.includes(
                                  Number(item?.lr_id)
                                );
                              return isLinked;
                            })
                          }
                          disabled={
                            Array.isArray(selectedVehicleComponentIds) &&
                            Array.isArray(vehicleCompList) &&
                            selectedVehicleComponentIds?.some((selectedId) => {
                              const flatVehicleCompList =
                                vehicleCompList.flat();
                              const matchingComponent =
                                flatVehicleCompList?.find(
                                  (comp) => comp.id === selectedId
                                );
                              const isLinked =
                                Array.isArray(
                                  matchingComponent?.linked_lr_ids
                                ) &&
                                matchingComponent.linked_lr_ids.includes(
                                  Number(item?.lr_id)
                                );
                              return isLinked;
                            })
                          }
                          onChange={(event) =>
                            handleSelectLegalRequirements(
                              event,
                              selectedVehicleComponentIds,
                              item?.lr_id
                            )
                          }
                        />
                      </Box>
                    </Box>
                  ))
              ) : (
                <Typography variant="body1" sx={styles.noDataAvailableTxt}>
                  No data available
                </Typography>
              )}
            </CardContent>

            <CardActions sx={styles.cardActions}></CardActions>
          </Card>
        </Grid>
      </Grid>
      {/* {selectedMainEEDetails && selectedEELinkedLRList?.length > 0 && (
        <Box sx={{ mt: "10px" }}>
          <LinkedVehicleToLegalTable
            selectedEELinkedLRList={selectedEELinkedLRList}
          />
        </Box>
      )} */}
      <CustomAddDialog
        open={isAddDescDialogOpen}
        onClose={() => handleAddDescDialogClose(false)}
        dialogTitle={" Reason For Linking"}
        onDisabled={!(currentDescription?.length > 3)}
        dialogContent={
          <Stack spacing={1.2}>
            {/* <DialogContentText>
              Reason For Linking
              </DialogContentText> */}
            <Box>
              <CustomTextFieldN
                size={"small"}
                // label={"Description*"}
                placeholder={"Description"}
                type={"text"}
                // fldType={"text"}
                value={currentDescription}
                setValue={setCurrentDescription}
                // valueError={assemblyNoError}
                setValueError={() => null}
                variant={"standard"}
                isMultiline={true}
                txtFldStyle={styles.txtStyle}
              />
              <SelectDynamicForm
                isDynamicFormDialogOpen={isDynamicFormDialogOpen}
                setIsDynamicFormDialogOpen={setIsDynamicFormDialogOpen}
                handleAddDescDialogClose={handleAddDescDialogClose}
                projectId={selectedProjectId}
                variantId={selectedSingleVariantDetails?.variant_id}
                isFormRequired={isFormRequired}
                setIsFormRequired={setIsFormRequired}
                formType={FORM_TYPES?.VEHICLE}
                dynamicFormFilteredData={dynamicFormFilteredData}
                setDynamicFormFilteredData={setDynamicFormFilteredData}
              />

              {/* <CustomUserDetailsMultiSelect
                selectedMainEEDetails={selectedSingleVariantDetails?.vehicle_components?.filter(
                  (vehicle) =>
                    selectedVehicleComponentIds?.includes(
                      parseInt(vehicle?.id?.toString())
                    )
                )}
                UserDetails={UserDetails}
                selectOEMUsers={selectOEMUsers}
                setSelectOEMUsers={setSelectOEMUsers}
                selectTechUsers={selectTechUsers}
                setSelectTechUsers={setSelectTechUsers}
                selectSupplierUsers={selectSupplierUsers}
                setSelectSupplierUsers={setSelectSupplierUsers}
              /> */}

              <MultiSelectUsers
                required
                sltFldStyle={{ mt: "10px" }}
                optionsList={
                  UserDetails?.usersList?.length > 0
                    ? UserDetails?.usersList
                        ?.filter((oem) => {
                          console.log("OEM USERS", oem);

                          // Extract user IDs from selected vehicle components
                          const owners =
                            selectedVehicleComponents?.map(
                              (component) => component.owner_details?.user_id
                            ) || [];

                          const coOwners =
                            selectedVehicleComponents?.flatMap(
                              (component) =>
                                component.co_owner_details?.map(
                                  (coOwner) => coOwner.user_id
                                ) || []
                            ) || [];

                          const allowedUserIds = [
                            ...owners,
                            ...coOwners,
                          ].filter(Boolean);

                          return (
                            allowedUserIds.includes(oem?.user_id) &&
                            oem?.role_details?.role_type === 0
                          );
                        })
                        ?.map((details) => ({
                          value: details?.user_id,
                          label: details?.fullname,
                        }))
                    : []
                }
                label={"Select TA requestors"}
                selectedValues={selectOEMUsers}
                setSelectedValues={setSelectOEMUsers}
              />

              <MultiSelectUsers
                sltFldStyle={{ mt: "10px" }}
                required
                optionsList={
                  legalRequirementsList?.length > 0
                    ? legalRequirementsList[0]?.tech_service_users?.map(
                        (user) => ({
                          value: user.user_id,
                          label: user.name,
                        })
                      )
                    : []
                }
                label={"Select TA reviewers"}
                selectedValues={selectTechUsers}
                setSelectedValues={setSelectTechUsers}
              />

              <MultiSelectUsers
                sltFldStyle={{ mt: "10px" }}
                required
                optionsList={
                  UserDetails?.usersList?.length > 0
                    ? UserDetails?.usersList
                        ?.filter((oem) => {
                          console.log("OEM USERS", oem);

                          const coOwners =
                            selectedVehicleComponents?.flatMap(
                              (component) =>
                                component.co_owner_details?.map(
                                  (coOwner) => coOwner.user_id
                                ) || []
                            ) || [];

                          const editUsers =
                            selectedVehicleComponents?.flatMap(
                              (component) =>
                                component.edit_user_details?.map(
                                  (eu) => eu.user_id
                                ) || []
                            ) || [];

                          const viewUsers =
                            selectedVehicleComponents?.flatMap(
                              (component) =>
                                component.view_user_details?.map(
                                  (vu) => vu.user_id
                                ) || []
                            ) || [];

                          const allowedUserIds = [
                            ...coOwners,
                            ...editUsers,
                            ...viewUsers,
                          ].filter(Boolean);

                          return (
                            allowedUserIds.includes(oem?.user_id) &&
                            oem?.role_details?.role_type === 2
                          );
                        })
                        ?.map((details) => ({
                          value: details?.user_id,
                          label: details?.fullname,
                        }))
                    : []
                }
                label={"Select Suppliers Users"}
                selectedValues={selectSupplierUsers}
                setSelectedValues={setSelectSupplierUsers}
              />
              <Box sx={{ padding: "2px", mt: "10px" }}>
                <MultiSelectFieldVariants
                  sltFldStyle={{ width: "270px" }}
                  mutiple
                  selectedValues={selectedVariantsMany}
                  setSelectedValues={setSelectedVariantsMany}
                  label={"Select Variants*"}
                  optionsList={variantOptionsList?.filter(
                    (variants) =>
                      variants?.value !==
                      selectedSingleVariantDetails?.variant_id
                  )}
                />
              </Box>
            </Box>
          </Stack>
        }
        onConfirm={() =>
          isFormRequired
            ? setIsDynamicFormDialogOpen(true)
            : handleAddDescDialogClose(true)
        }
      />
    </Box>
  );
}

export default LinkVehicleToLegal;

// Styles
const styles = {
  grid: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  selectTxtStyle: {
    width: "190px",
    mt: 1,
  },
  card: {
    mx: 2,
    boxShadow: 12,
    // width: "420px",
    width: "100%",
    maxWidth: "420px",
  },
  cardHeader: {
    pb: 1,
    backgroundColor: "lightgrey",
    borderBottom: "1px solid lightgrey",
  },
  cardContent: {
    height: "340px",
    overflowY: "auto",
  },
  cardItems: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    mb: 1.5,
    pl: 1.4,
    pr: 1,
    backgroundColor: "background.paper",
    borderRadius: 1,
    boxShadow: 4,
  },
  cardItemsTxtContainer: {
    flexGrow: 1,
    textAlign: "start",
    my: 1,
  },
  cardActions: {
    display: "flex",
    justifyContent: "flex-end",
    borderTop: "1px solid lightgrey",
  },
  noDataAvailableTxt: {
    textAlign: "center",
    mt: 2,
  },
  linkOffIcon: {
    color: "#FF5757",
  },
  linkIcon: {
    color: "green",
  },
  customBtnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    mb: 1,
    gap: 1.4,
    my: 1,
  },
  customButton: {},
  txtStyle: {
    width: "270px",
  },
  eeCompMainContainer: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    justifyContent: "space-evenly",
  },
  eeCompCard: {
    mt: 1.3,
    mx: 2,
    width: "370px",
    boxShadow: 12,
  },
  eeCompCardHeader: {
    py: 1,
    backgroundColor: "lightgrey",
    borderBottom: "1px solid lightgrey",
  },
  eeCompCardContent: {
    height: "340px",
    overflowY: "auto",
  },
  eeCompCardActions: {
    display: "flex",
    justifyContent: "flex-end",
    borderTop: "1px solid lightgrey",
  },
  eeCompsList: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    mb: 1.5,
    pl: 1.4,
    pr: 1,
    backgroundColor: "background.paper",
    borderRadius: 1,
    boxShadow: 4,
    cursor: "pointer",
    "&:hover": {
      // backgroundColor: "lightgrey",
    },
  },
  eeCompsListItemContainer: {
    flexGrow: 1,
    textAlign: "start",
    my: 1,
  },
  noDataAvailable: {
    textAlign: "center",
    mt: 2,
  },
  txtStyleR: {
    width: "380px",
  },
  edRpIconsContainer: {
    display: "flex",
    flexDirection: "column",
  },
  customTextFieldN: {
    width: "270px",
  },
};
