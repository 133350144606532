import actionType from "../actionTypes";

const initialState = {
  error: false,
  msg: "",
  dynamicTemplate: [],
};

const dynamicTemplateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.GET_DYNAMIC_TEMPLATE:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        dynamicTemplate: action.payload.error ? [] : action.payload.data,
      };

    case actionType.ADD_DYNAMIC_TEMPLATE:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };

    case actionType.UPDATE_DYNAMIC_TEMPLATE:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.UPDATE_STATUS_DYNAMIC_TEMPLATE:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };

    default:
      return state;
  }
};

export default dynamicTemplateReducer;
