/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 07-02-2025
 * @description : Custom import button for importing from JSON or Excel.
 */

import { Box, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import CustomButton from "./CustomButton";
import * as XLSX from "xlsx";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { showToastMessage } from "../../redux/actions/actionHandler";
import customConsole from "../../config/customConsole";

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 07-02-2025
 * @description : Custom import button for importing from JSON or Excel.
 * @param {}
 * @return : The rendered import button
 */

function CustomImportButton({ handleImportedJsonExcelFile }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFileRead = (file, type) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const content = event.target.result;
      if (type === "json") {
        try {
          const jsonData = JSON.parse(content);
          customConsole("Imported JSON Data: ");
          customConsole(jsonData);
          handleImportedJsonExcelFile({
            fileType: "json",
            importedFileData: jsonData,
          });
        } catch (error) {
          console.error("Invalid JSON file format.", error);
          showToastMessage({
            type: "error",
            message: "Invalid JSON file format.",
          });
        }
      } else if (type === "excel") {
        try {
          const workbook = XLSX.read(content, { type: "binary" });
          let allSheetsData = {};
          workbook.SheetNames.forEach((sheetName) => {
            allSheetsData[sheetName] = XLSX.utils.sheet_to_json(
              workbook.Sheets[sheetName]
            );
          });
          customConsole("Imported Excel Data: ");
          customConsole(allSheetsData);
          handleImportedJsonExcelFile({
            fileType: "excel",
            importedFileData: allSheetsData,
          });
        } catch (error) {
          console.error("Unable to process Excel file.", error);
          showToastMessage({
            type: "error",
            message: "Unable to process Excel file.",
          });
        }
      } else {
        showToastMessage({
          type: "error",
          message: "Unsupported file type.",
        });
      }
    };

    if (type === "json") {
      reader.readAsText(file);
    } else if (type === "excel") {
      reader.readAsBinaryString(file);
    }
  };

  const handleFileSelect = (type) => {
    handleClose();
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = type === "json" ? ".json" : ".xls,.xlsx";

    fileInput.onchange = (event) => {
      const file = event.target.files[0];
      if (file) {
        const fileType = file.name.split(".").pop().toLowerCase();

        if (type === "json" && fileType !== "json") {
          showToastMessage({
            type: "error",
            message: "Please select a valid JSON file.",
          });
        } else if (type === "excel" && !["xls", "xlsx"].includes(fileType)) {
          showToastMessage({
            type: "error",
            message: "Please select a valid Excel file.",
          });
        } else {
          handleFileRead(file, type);
        }
      }
    };

    fileInput.click();
  };

  return (
    <Box>
      <CustomButton
        size={"small"}
        variant={"contained"}
        btnName={"Import "}
        startIcon={<FileUploadIcon />}
        handleOnClick={(e) => setAnchorEl(e.currentTarget)}
        btnStyle={{
          ml: 2,
        }}
      />
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => handleFileSelect("json")}>
          Import JSON
        </MenuItem>
        <MenuItem onClick={() => handleFileSelect("excel")}>
          Import Excel
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default CustomImportButton;
